// Libraries
import { Droppable } from "react-beautiful-dnd";

// Components
import Header from "components/planning/utils/header";
import Card from "components/planning/card/dashboard";
import Menu from "components/menu/dashboard-planning";

const Column = ({ list, title, id, openForm }) => {
    return (
        <Droppable droppableId={id}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={`col__12 col_m__3
                ${snapshot.isDraggingOver && ""}`}
                >
                    <Header title={title} id={id} />
                    {!!list &&
                        list.map((item, index) => (
                            <Card
                                key={item.id}
                                item={item}
                                index={index}
                                openForm={openForm}
                            />
                        ))}
                    {provided.placeholder}
                    <Menu id={id} />
                </div>
            )}
        </Droppable>
    );
};

export default Column;

// export default ({
//     list,
//     title,
//     empty,
//     listId,
//     deadline,
//     toggleFormEdit,
//     updatePlanningDeadline,
// }) => {
//     const [open, toggle] = useState(true);

//     return (
//         <Droppable droppableId={listId}>
//             {(provided, snapshot) => (
//                 <div
//                     ref={provided.innerRef}
//                     {...provided.droppableProps}
//                     className={`col__12 col_m__3
//                 ${snapshot.isDraggingOver && "bg-edit"}`}
//                 >
//                     <Header
//                         title={title}
//                         open={open}
//                         toggle={toggle}
//                         listId={listId}
//                         deadline={deadline}
//                         updatePlanningDeadline={updatePlanningDeadline}
//                         length={list.size || "0"}
//                     />
//                     {!!(open && list) &&
//                         list.map((item, index) => (
//                             <CardPlanning
//                                 key={item.id}
//                                 item={item}
//                                 index={index}
//                                 toggleFormEdit={toggleFormEdit}
//                             />
//                         ))}
//                     {provided.placeholder}
//                     {empty && <CardEmpty />}
//                     <Menu listId={listId} />
//                 </div>
//             )}
//         </Droppable>
//     );
// };
