const Input = ({
    name,
    type = "text",
    autocomplete = "off",
    autoFocus = false,
    placeholder,
    error = false,
    className = "",
    onChange = () => {},
    value = "",
}) => {
    return (
        <input
            className={`input mb-s ${className} ${error && "input--error"}`}
            type={type}
            name={name}
            placeholder={placeholder}
            autoComplete={autocomplete}
            onChange={onChange}
            value={value}
            autoFocus={autoFocus}
        />
    );
};

export default Input;
