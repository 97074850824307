import { editApplicationStore } from "../../../../core/platform/form-edit-application/store";

// Components
import Modal from "../../modal";
import Container from "./container";

const UpdateApplication = () => {
    const { active, closeForm } = editApplicationStore();

    return (
        <Modal active={active} toggle={closeForm}>
            <Container />
        </Modal>
    );
};

export default UpdateApplication;
