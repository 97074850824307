import { useRef } from "react";

// Variables
import { types } from "../../../variables/types.library";

// Components
import TextExpanded from "../../utils/text-expanded";
import Button from "../../button";
import { IconLink, IconEdit } from "../../icons";

const Container = ({
    children,
    item,
    openForm,
    canEdit = true,
    archive = false,
}) => {
    const refLink = useRef();
    const refDocumentLink = useRef();

    const openLink = () => {
        if (item.type === types.DOCUMENT) {
            refDocumentLink.current.click();
        } else {
            refLink.current.click();
        }
    };

    return (
        <div className={`card col__12 ${!archive && "col_m__4 col_l__3"} mb-m`}>
            <div className="bg-gray h-56 mb radius"></div>
            {children}

            <div className="flex flex-align flex-space pt pb-s">
                <div className="flex flex-align">
                    <Button text=" Open Link" onClick={() => openLink()}>
                        <IconLink className="icon icon-xs ml-s" />
                    </Button>
                </div>

                {canEdit && (
                    <Button onClick={() => openForm(item.id)} className="">
                        <IconEdit className="icon icon-xs ml-s mr-s" />
                    </Button>
                )}
            </div>

            {item.type === types.DOCUMENT && (
                <a
                    hidden
                    ref={refDocumentLink}
                    href={item.file.downloadURL || item.file.downloadUrl}
                    target="_black"
                    rel="noreferrer noopener"
                >
                    link download
                </a>
            )}

            {(item.type === types.LINK || item.type === types.VIDEO) && (
                <a
                    hidden
                    ref={refLink}
                    href={item.url}
                    target="_black"
                    rel="noreferrer noopener"
                >
                    link video
                </a>
            )}
        </div>
    );
};

const Title = ({ name }) => (
    <div className="full text-wrap a-l pb font-bold">{name}</div>
);

const CardDocument = ({ item, canEdit, openForm, archive }) => (
    <Container
        item={item}
        canEdit={canEdit}
        openForm={openForm}
        archive={archive}
    >
        <Title name={item.name} openLink={() => null} />
        <TextExpanded text={item.description} className="fs-s" />
    </Container>
);

const CardLink = ({ item, canEdit, openForm, archive }) => (
    <Container
        item={item}
        canEdit={canEdit}
        openForm={openForm}
        archive={archive}
    >
        <Title name={item.name} openLink={() => null} />
        <TextExpanded text={item.description} className="fs-s" />
    </Container>
);

const CardVideo = ({ item, canEdit, openForm, archive }) => (
    <Container
        item={item}
        canEdit={canEdit}
        openForm={openForm}
        archive={archive}
    >
        <Title name={item.name} openLink={() => null} />
        <TextExpanded text={item.description} className="fs-s" />
    </Container>
);

const Card = (props) => {
    if (props.item.type === types.LINK) {
        return <CardLink {...props} />;
    }

    if (props.item.type === types.DOCUMENT) {
        return <CardDocument {...props} />;
    }

    if (props.item.type === types.VIDEO) {
        return <CardVideo {...props} />;
    }

    return null;
};

export default Card;
