import { IconHelp } from "components/icons";

const InfoMessage = ({ children = null }) => {
	return (
		<div className="bgc-main pl pr pt pb flex flex-align b-r">
			<IconHelp className="icon icon-s mr color-main" />
			{children}
		</div>
	);
};

export default InfoMessage;
