import { useState } from "react";

// - Components
import Menu from "components/menu/utils/menu";
import Team from "./team";
import User from "components/utils/user";
import { IconHelp, IconOpen } from "components/icons";

const Owner = ({ owner, team, canEdit, updatePlanning }) => {
    const [open, toggle] = useState(false);

    const updateTeam = (payload) => {
        updatePlanning({
            owner: !!(owner && owner.uid === payload.uid) ? null : payload,
        });

        toggle(false);
    };

    if (!canEdit) {
        return (
            <div className="flex flex-align full">
                <div>
                    <div className="flex flex-center img img--s b-r bgc-g">
                        <IconHelp className="icon icon-s" />
                    </div>
                </div>
                <div className="fs-s fw-b ml-s c-sec full text-wrap">
                    Nog geen verantwoordelijke
                </div>
            </div>
        );
    }

    return (
        <div className="relative">
            <button
                disabled={!canEdit}
                className="flex flex-space flex-align full a-l edit b-r"
                onClick={() => toggle(!open)}
            >
                {owner ? (
                    <User user={owner} text="Verantwoordelijke" />
                ) : (
                    <div className="flex flex-align full">
                        <div>
                            <div className="flex flex-center img img--s b-r bgc-g">
                                <IconHelp className="icon icon-s" />
                            </div>
                        </div>
                        <div className="fs-s fw-b ml-s c-sec full text-wrap">
                            Kies een teamlid
                        </div>
                    </div>
                )}

                <IconOpen className="icon icon-s color-main" />
            </button>
            <Menu
                closeMenu={() => toggle(!open)}
                active={open}
                className="p-b-l"
                wrap
            >
                <Team team={team} owner={owner} updateTeam={updateTeam} />
            </Menu>
        </div>
    );
};

export default Owner;
