import { doc, query, collection, where, writeBatch } from "firebase/firestore";

import { db } from "core/firebase";
import { PlanningListener } from "./utils";
import { applicationStore } from "core/application/application/store";

const planningListener = new PlanningListener();

export const setPlanningListener = () => {
    const { id } = applicationStore.getState().application;

    const q = query(
        collection(db, "applications", id, "planning"),
        where("visibility", "==", true)
    );

    planningListener.on(q);
};

export const removePlanningListener = () => {
    planningListener.off();
};

export const updateBoard = (sourceList = null, destinationList = null) => {
    const { id } = applicationStore.getState().application;
    const batch = writeBatch(db);

    sourceList.list.forEach((item, index) => {
        const cardRef = doc(db, "applications", id, "planning", item.id);

        batch.update(cardRef, { position: index });
    });

    if (destinationList) {
        destinationList.list.forEach((item, index) => {
            const cardRef = doc(db, "applications", id, "planning", item.id);
            batch.update(cardRef, {
                position: index,
                listId: destinationList.id,
            });
        });
    }

    batch.commit();
};
