import { Link } from "react-router-dom";

// Components
import Button from "components/button";
import { IconError, IconLink } from "components/icons";

const Navigation = ({ projectId = "", applicationId = "", closeForm }) => {
    return (
        <div className="pb-m flex flex-align flex-space">
            <Link
                className="button button-border"
                to={`/app/${applicationId}/project/${projectId}`}
            >
                Bekijk Project
                <IconLink className="icon icon-s ml" />
            </Link>

            <Button onClick={closeForm}>
                <IconError className="icon icon-s" />
            </Button>
        </div>
    );
};

export default Navigation;
