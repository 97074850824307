import { useState, useEffect, useRef } from "react";

import { db } from "../../../../core/firebase";
import { onSnapshot, doc, updateDoc } from "firebase/firestore";

// Variables
import { list } from "../../../../variables/types.library";

// Core
import { editLibraryStore } from "../../../../core/application/library/form-edit-library";
import { applicationStore } from "../../../../core/application/application/store";
import { unwrapSnapshot } from "../../../../core/utils";

// Component
import Loader from "../../../utils/loader";
import InputEdit from "../../../inputs/input-edit";
import Switch from "../../../inputs/switch";
import Select from "../../../inputs/select";

const Container = () => {
    const { id } = editLibraryStore();
    const { application } = applicationStore();

    const ref = useRef(doc(db, "applications", application.id, "library", id));

    const [item, setItem] = useState(null);

    useEffect(() => {
        const unsubscribe = onSnapshot(ref.current, (payload) =>
            setItem(unwrapSnapshot(payload))
        );

        return () => unsubscribe();
    }, []);

    const setVisibility = (active) => {
        updateDoc(ref.current, {
            visibility: active,
        });
    };

    const setName = (name) => {
        updateDoc(ref.current, {
            name: name,
        });
    };

    const setDescription = (description) => {
        updateDoc(ref.current, {
            description: description,
        });
    };

    const setStage = (stage) => {
        updateDoc(ref.current, {
            stage: stage,
        });
    };

    if (!item) {
        return <Loader modal />;
    }

    return (
        <div className="modal-container">
            <InputEdit
                placeholder="Deze taak heeft nog geen naam."
                text={item.name}
                setText={setName}
                className="fw-b fs-l"
            />
            <InputEdit
                placeholder="Deze taak heeft nog geen omschrijving."
                text={item.description}
                setText={setDescription}
                className="p"
                textarea
            />
            <div className="mt" />
            <Select list={list} onChange={setStage} selected={item.stage} />
            <div className="mt" />

            <div className="flex flex-align mt">
                <Switch active={item.visibility} setActive={setVisibility} />
                <span className="fs-s ml fw-b">
                    {item.visibility ? "Zichtbaar" : "Verbergen"}
                </span>
            </div>
        </div>
    );
};

export default Container;
