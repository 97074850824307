import { useState, useEffect, useRef, Fragment } from "react";

// Library
import { useSearchParams } from "react-router-dom";

// Core
import { db } from "../../../core/firebase";

import {
    collection,
    query,
    onSnapshot,
    where,
    orderBy,
} from "firebase/firestore";
import { unwrapList } from "../../../core/utils";

// Store
import { applicationStore } from "../../../core/application/application/store";
import { rolesStore } from "../../../core/roles/store";
import { editLibraryStore } from "../../../core/application/library/form-edit-library";

// Variables
import { stages } from "../../../variables/types.library";

// Components
import Loader from "../../../components/utils/loader";
import PageEmpty from "../../loading";
import Navigation from "../../../components/navigations/application.library";
import Grid from "../../../components/utils/grid";
import Card from "../../../components/cards/library";
import CreateLibrary from "../../../components/forms/application/library-create";
import EditLibrary from "../../../components/forms/application/library-edit";
import ArchiveLibrary from "../../../components/forms/application/library-archive";

const Library = () => {
    let [searchParams] = useSearchParams();
    let type = searchParams.get("type") || "";

    const { id } = applicationStore().application;
    const { applicationRoles } = rolesStore();
    const { openForm } = editLibraryStore();
    const [list, setList] = useState(null);
    const [filteredList, setFilteredList] = useState(null);
    const [activeArchive, setActiveArchive] = useState(false);
    const canEdit = useRef({});

    useEffect(() => {
        const q = query(
            collection(db, "applications", id, "library"),
            where("visibility", "==", true),
            orderBy("created_at", "desc")
        );

        const unsubscribe = onSnapshot(q, (payload) =>
            setList(unwrapList(payload))
        );

        return () => unsubscribe();
    }, [id]);

    useEffect(() => {
        if (list) {
            setFilteredList(filterList());
        }
        // eslint-disable-next-line
    }, [type, list]);

    useEffect(() => {
        canEdit.current = !!(
            applicationRoles?.ADMIN ||
            applicationRoles?.APPLICATION_ADMIN ||
            applicationRoles?.SUPERVISOR
        );
    }, [applicationRoles]);

    const filterList = () => {
        if (!type.length) {
            return list.filter((item) => item.stage === stages.ontmoetingsfase);
        }

        return list.filter((item) => item.stage === type);
    };

    if (!filteredList) {
        return <Loader pageWithNav />;
    }

    return (
        <Fragment>
            <Navigation
                setActiveArchive={setActiveArchive}
                canEdit={canEdit.current}
            />
            {filteredList.length ? (
                <Grid>
                    {filteredList.map((item) => (
                        <Card
                            key={item.id}
                            item={item}
                            openForm={openForm}
                            canEdit={canEdit.current}
                        />
                    ))}
                </Grid>
            ) : (
                <PageEmpty
                    pageWithSubNav
                    imageSlug="empty_library"
                    text="Er staan helaas nog geen items in de bibliotheek."
                />
            )}

            <ArchiveLibrary
                active={activeArchive}
                closeForm={() => setActiveArchive(false)}
            />
            <CreateLibrary />
            <EditLibrary />
        </Fragment>
    );
};

export default Library;
