const months = [
    "januari",
    "februari",
    "maart",
    "april",
    "mei",
    "juni",
    "juli",
    "augustus",
    "september",
    "oktober",
    "november",
    "december",
];

const monthShort = [
    "jan",
    "feb",
    "mrt",
    "apr",
    "mei",
    "jun",
    "jul",
    "aug",
    "sep",
    "okt",
    "nov",
    "dec",
];
export const dateShort = (payload) => {
    if (!payload || !payload?.seconds) {
        return "geen datum";
    }

    const date = payload.toDate();

    if (date.getTime()) {
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        return `${day} ${monthShort[month]} ${year}`;
    }

    return "geen datum";
};

export const dateFull = (payload) => {
    if (!payload || !payload?.seconds) {
        return "geen datum";
    }

    const date = payload.toDate();

    if (date.getTime()) {
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        return `${day} ${months[month]} ${year}`;
    }

    return "geen datum";
};
