const Label = ({ index, position }) => {
    return (
        <div
            className={`light light-slide mr ${
                index === position && "light-slide-on"
            }`}
        />
    );
};

export default Label;
