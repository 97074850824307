import { useState, useEffect } from "react";

// Core
import { db } from "../../../core/firebase";
import { collection, query, onSnapshot, where } from "firebase/firestore";
import { unwrapList } from "../../../core/utils";

// Core
import { authStore } from "../../../core/authentication/store";

// Components
import Loader from "../../../components/utils/loader";
import PageEmpty from "../../loading";
import Grid from "../../../components/utils/grid";
import Card from "../../../components/cards/application";

const Applications = () => {
    const [list, setList] = useState(null);
    const { uid } = authStore().user;

    useEffect(() => {
        const q = query(
            collection(db, "applications"),
            where(`users.${uid}`, "==", true),
            where("visibility", "==", true)
        );

        const unsubscribe = onSnapshot(q, (payload) => {
            setList(unwrapList(payload));
        });

        return () => unsubscribe();
    }, [uid]);

    if (!list) {
        return <Loader page />;
    }

    if (!list.length) {
        return (
            <PageEmpty
                pageWithNav
                text="Er zijn helaas nog geen applicaties zichtbaar."
            />
        );
    }

    return (
        <Grid>
            {list.map((item) => (
                <Card key={item.id} item={item} />
            ))}
        </Grid>
    );
};

export default Applications;
