// Firebase
import {
    signInWithGoogle,
    signInWithFacebook,
} from "../../../core/authentication/actions";

// Components
import { IconGoogle, IconFacebook } from "../../../components/icons";

const Socials = () => (
    <div>
        <div className="pt-m bb relative">
            <span className="auth_social_label fs-xs c-sec">
                Of login in met
            </span>
        </div>
        <div className="row pt-m flex-space">
            <button
                className="btn btn--border col__6"
                onClick={signInWithFacebook}
                type="button"
            >
                <span className="fs-s mr">Facebook</span>
                <IconFacebook className="icon i-f" />
            </button>
            <button
                className="btn btn--border col__6"
                onClick={signInWithGoogle}
                type="button"
            >
                <span className="fs-s mr">Google</span>
                <IconGoogle className="icon icon-sm" />
            </button>
        </div>
    </div>
);

export default Socials;
