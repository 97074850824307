import { useEffect } from "react";

// Core
import {
    setTaskListener,
    removeTaskListener,
} from "core/application/project/form-edit-planning/actions";
import { editPlanningStore } from "core/application/project/form-edit-planning/store";

// Components
import Loader from "components/utils/loader";
import TaskNotFound from "./utils/not-found";
import Container from "./container";

const TaskLoader = () => {
    const { item, itemNotFound } = editPlanningStore();

    useEffect(() => {
        setTaskListener();

        return () => removeTaskListener();
    }, []);

    if (!item && !itemNotFound) {
        return <Loader modal />;
    }

    if (itemNotFound) {
        return <TaskNotFound />;
    }

    return <Container />;
};

export default TaskLoader;
