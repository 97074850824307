import { useState } from "react";

//  Components

import Input from "./input";
import TextArea from "./textarea";
import Button from "components/button";
import { IconEdit } from "components/icons";

const InputEdit = ({
    text = "",
    setText = () => {},
    className = "",
    textarea = false,
}) => {
    const [active, setActive] = useState(false);
    const [input, setInput] = useState(text);

    const submitText = (e) => {
        e.preventDefault();
        setText(input);
        setActive(false);
    };

    if (active) {
        return (
            <form
                className="p-box mt-s mb-s b-r bg-edit radius"
                onSubmit={submitText}
            >
                {textarea ? (
                    <TextArea
                        value={input}
                        onChange={({ target }) => setInput(target.value)}
                    />
                ) : (
                    <Input
                        className="fw-b "
                        value={input}
                        onChange={({ target }) => setInput(target.value)}
                    />
                )}
                <div className="mt-s">
                    <Button
                        className="button-border mr-s"
                        onClick={() => setActive(false)}
                        text="Annuleren"
                    />

                    <Button
                        className="mr-s"
                        onClick={submitText}
                        text="Aanpassen"
                    />
                </div>
            </form>
        );
    }
    return (
        <p
            className={`pt-s pb-s b-r full a-l flex flex-space ${className}`}
            onClick={() => setActive(true)}
        >
            {text}
            <span className="button">
                <IconEdit className="icon icon-s " />
            </span>
        </p>
    );
};

export default InputEdit;
