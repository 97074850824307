// Core
import { createLibraryStore } from "../../../../core/application/library/form-create-library";

// Components
import Modal from "../../modal";
import Container from "./container";

const FormCreateLibrary = () => {
    const { closeForm, active } = createLibraryStore((state) => ({
        ...state,
    }));

    return (
        <Modal active={active} toggle={closeForm}>
            <Container />
        </Modal>
    );
};

export default FormCreateLibrary;
