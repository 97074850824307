import { useEffect, useState } from "react";

// Core
import { projectStore } from "core/application/project/project/store";
import { applicationStore } from "core/application/application/store";
import { createTeamMembers } from "core/application/project/form-create-team/actions";
import { notificationStore } from "core/notifications/store";

// Firebase
import { db } from "core/firebase";
import { getDocs, collection, query, where } from "firebase/firestore";

// Variables
import { roles } from "variables/roles";
import { unwrapList } from "core/utils";
import { messages } from "variables/messages";

// Components
import Loader from "components/utils/loader";
import User from "./user";
import InfoMessage from "./info";
import Button from "components/button";

const List = ({ closeForm, setOpenList }) => {
	const { application } = applicationStore();
	const { project } = projectStore();
	const { addNotification } = notificationStore();

	const [users, setUsers] = useState(null);

	const [selected, setSelected] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const getUsers = () => {
			const q = query(
				collection(db, "applications", application.id, "users"),
				where(`roles.${roles.STUDENT}`, "==", true)
			);

			getDocs(q).then((payload) => setUsers(unwrapList(payload)));
		};

		getUsers();
		// eslint-disable-next-line
	}, []);

	const toggleTeamMember = (payload) => {
		const isInList = selected.some((item) => item.id === payload.id);

		if (isInList) {
			setSelected(selected.filter((item) => item.id !== payload.id));
		} else {
			setSelected([payload, ...selected]);
		}
	};

	const submitTeamMembers = async () => {
		setLoading(true);

		const error = await createTeamMembers([...selected]);

		setLoading(false);

		if (error) {
			addNotification({ message: messages.CREATE.ERROR, error });
			return;
		}

		addNotification({ message: messages.CREATE.SUCCESS });
		closeForm();
	};

	if (!users) {
		return (
			<div className="flex flex-center pt-m pb-m relative">
				<Loader />
			</div>
		);
	}

	const filteredList = users.filter((item) => !project.users[item.id]);

	return (
		<>
			<InfoMessage className="mb">
				<p className="fs-xs color-main">
					Is de persoon die je wilt toevoegen actief op dit platform?
					Zo ja, dan is deze persoon zichtbaar in de lijst hieronder.
					Anders nodig je de persoon uit via{" "}
					<button
						type="button"
						className="underline"
						onClick={() => setOpenList(false)}
					>
						Toevoegen via uitnodiging
					</button>
					.
				</p>
			</InfoMessage>
			<div className="fs-s fw-b mb mt">
				De lijst met personen die je kunt toevoegen
			</div>
			{!!(filteredList && filteredList.length) ? (
				filteredList.map((item) => (
					<User
						key={item.id}
						item={item}
						selected={selected}
						toggleTeamMember={toggleTeamMember}
					/>
				))
			) : (
				<InfoMessage className="mb">
					<p className="fs-xs color-main">
						Nog geen personen gevonden? Nodig de persoon uit via{" "}
						<button
							type="button"
							className="underline"
							onClick={() => setOpenList(false)}
						>
							Toevoegen via uitnodiging
						</button>
						.
					</p>
				</InfoMessage>
			)}
			<div className="flex mt-m">
				<Button
					className="button-border flex-1"
					text="annuleren"
					onClick={closeForm}
				/>
				<Button
					className={`flex-1 ml ${
						!selected.length && "button-disabled"
					}`}
					text="Personen toevoegen"
					loading={loading}
					disabled={!selected.length}
					onClick={submitTeamMembers}
				/>
			</div>
		</>
	);
};

export default List;
