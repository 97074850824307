import { planningArchiveStore } from "core/application/project/form-archive-planning";

// Components
import Modal from "components/forms/modal";
import Container from "./container";

const ArchivePlanning = () => {
    const { closeForm, active } = planningArchiveStore();

    return (
        <Modal active={active} toggle={closeForm}>
            <Container />
        </Modal>
    );
};

export default ArchivePlanning;
