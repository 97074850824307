import { stageInfoStore } from "core/application/project/form-stage-info/store";

// Components
import Modal from "components/forms/modal";
import Container from "./container";

const InfoStage = () => {
    const { active, closeForm, id } = stageInfoStore();

    return (
        <Modal active={active} toggle={closeForm}>
            <Container id={id} closeForm={closeForm} />
        </Modal>
    );
};

export default InfoStage;
