const TextArea = ({
    placeholder,
    className = "",
    onChange = () => {},
    value = "",
    rows = 5,
}) => {
    return (
        <textarea
            rows={rows}
            className={`input ${className}`}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
        />
    );
};

export default TextArea;
