// Components
import Modal from "../../modal";
import Container from "./container";

const InvitationCreate = ({ active, closeForm }) => {
    return (
        <Modal active={active} toggle={closeForm}>
            <Container closeForm={closeForm} />
        </Modal>
    );
};

export default InvitationCreate;
