const Label = ({ text = "", className = "", normal = false }) => {
    return (
        <div
            className={`flex flex-space fs-s fw-b ${
                normal ? "" : "c-sec"
            } ${className}`}
        >
            {text}
        </div>
    );
};

export default Label;
