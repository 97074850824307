import { onSnapshot } from "firebase/firestore";
import { applicationStore, initialState } from "./store";
import { unwrapSnapshot } from "../../utils";

export class ApplicationListener {
    on(query) {
        const unsubscribe = onSnapshot(query, (doc) => {
            if (doc.exists()) {
                applicationStore.setState({
                    application: unwrapSnapshot(doc),
                });
            } else {
                applicationStore.setState({
                    applicationNotFound: true,
                });
            }
        });

        this._unsubscribe = () => unsubscribe();
    }
    off() {
        applicationStore.setState({ ...initialState });
        this._unsubscribe();
    }
}
