import { useState, useEffect, Fragment } from "react";

// Core
import { db } from "../../../core/firebase";

import {
    collection,
    query,
    onSnapshot,
    orderBy,
    where,
} from "firebase/firestore";
import { unwrapList } from "../../../core/utils";

// Stores
import { applicationStore } from "../../../core/application/application/store";
import { rolesStore } from "../../../core/roles/store";
import { authStore } from "../../../core/authentication/store";

// Components
import Loader from "../../../components/utils/loader";
import PageEmpty from "../../loading";
import Grid from "../../../components/utils/grid";
import Card from "../../../components/cards/project";
import ProjectCard from "../../../components/cards/project/current-project";
import CreateProject from "../../../components/forms/application/project-create";
import Navigation from "components/navigations/application.projects";

const Projects = () => {
    const { id } = applicationStore().application;
    const { uid } = authStore().user;
    const { applicationRoles } = rolesStore();

    const [list, setList] = useState(null);

    useEffect(() => {
        const q = query(
            collection(db, "applications", id, "projects"),
            where("visibility", "==", true),
            orderBy("created_at", "desc")
        );

        const unsubscribe = onSnapshot(q, (payload) =>
            setList(unwrapList(payload))
        );

        return () => unsubscribe();
    }, [id]);

    if (!list) {
        return <Loader pageWithNav />;
    }

    return (
        <Fragment>
            <Navigation />
            {applicationRoles?.STUDENT && <ProjectCard list={list} uid={uid} />}
            {list.length ? (
                <Fragment>
                    <div className="container">
                        <h1 className="mt-m fw-n">Alle opgaves</h1>
                    </div>
                    <Grid>
                        {list.map((item) => (
                            <Card key={item.id} item={item} uid={uid} />
                        ))}
                    </Grid>
                </Fragment>
            ) : (
                <PageEmpty
                    pageWithSubNav
                    text="Er zijn helass nog geen opdrachten zichtbaar."
                />
            )}
            <CreateProject />
        </Fragment>
    );
};

export default Projects;
