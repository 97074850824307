import { useState, useEffect } from "react";

// Libraries
import { collection, query, onSnapshot, where } from "firebase/firestore";

// Core
import { db } from "core/firebase";
import { unwrapList } from "core/utils";

// Store
import { applicationStore } from "core/application/application/store";
import { projectStore } from "core/application/project/project/store";
import { editPlanningStore } from "core/application/project/form-edit-planning/store";

// Components
import Loader from "components/utils/loader";
import Card from "components/cards/archive/planning";

const Container = () => {
    const { id } = applicationStore().application;
    const { project } = projectStore();
    const { openForm } = editPlanningStore();

    const [list, setList] = useState(null);

    useEffect(() => {
        const q = query(
            collection(
                db,
                "applications",
                id,
                "projects",
                project.id,
                "planning"
            ),
            where("archived", "==", true)
        );

        const unsubscribe = onSnapshot(q, (payload) =>
            setList(unwrapList(payload))
        );

        return () => unsubscribe();
        // eslint-disable-next-line
    }, [id]);

    if (!list) {
        return <Loader modal />;
    }

    return (
        <div className="modal-container">
            <h3 className="mb-s">Bewaarde taken</h3>
            {list.map((item) => (
                <Card key={item.id} item={item} openForm={openForm} />
            ))}
        </div>
    );
};

export default Container;
