//  Component
import Switch from "../../../inputs/switch";

const PaymentRequired = ({ paymentRequired, setPaymentRequired }) => {
    return (
        <div className="flex flex-align flex-space">
            <div className="fw-b">
                {paymentRequired
                    ? "Betaling is nodig"
                    : "Betaling is niet nodig"}
            </div>
            <Switch active={paymentRequired} setActive={setPaymentRequired} />
        </div>
    );
};

export default PaymentRequired;
