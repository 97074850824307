export const list = [
	{
		name: "5W+H",
		tool: "5W+H-methode-tool-youlab.pptx",
		thumbnail: "5w.jpeg",
	},
	{
		name: "Actieplan",
		tool: "actieplan-tool-youlab.pptx",
		thumbnail: "Actieplan.jpeg",
	},
	{
		name: "Concept canvas",
		tool: "concept-canvas-tool-youlab.pptx",
		thumbnail: "Concept canvas.jpeg",
	},
	{
		name: "Disney strategie",
		tool: "disney-strategie-tool-youlab.pptx",
		thumbnail: "Disney strategie.jpeg",
	},
	{
		name: "Eindgebruiker profiel",
		tool: "eindgebruiker-klantprofiel-tool-youlab.pptx",
		thumbnail: "Eindgebruiker klantprofiel.jpeg",
	},
	{
		name: "Plan op 1-A4",
		tool: "implementatieplan-op-1-a4-tool-youlab.pptx",
		thumbnail: "implementatieplan.jpeg",
	},
	{
		name: "Opgave voorstel",
		tool: "opgave-voorstel-tool-youlab.pptx",
		thumbnail: "Opgave voorstel.jpeg",
	},
	{
		name: "Reflectie ontdekkingsfase",
		tool: "reflectie-ontdekkingsfase-tool-youlab.pptx",
		thumbnail: "Business model canvas.jpeg",
	},
	{
		name: "Reflectie ontmoetingsfase",
		tool: "reflectie-ontmoetingsfase-tool-youlab.pptx",
		thumbnail: "Persona's.jpeg",
	},
	{
		name: "Reflectie ontwikkelfase",
		tool: "reflectie-ontwikkelfase-tool-youlab.pptx",
		thumbnail: "Prototype canvas.jpeg",
	},
	{
		name: "Reflectie organisatiefase",
		tool: "reflectie-organisatiefase-tool-youlab.pptx",
		thumbnail: "Reflectie.jpeg",
	},
	{
		name: "Service Blueprint",
		tool: "service-blueprint-tool-youlab.pptx",
		thumbnail: "Service blueprint.jpeg",
	},
	{
		name: "SIMO-analyse",
		tool: "simo-analyse-tool-youlab.pptx",
		thumbnail: "SIMO Analyse.jpeg",
	},
	{
		name: "Stakeholdermap",
		tool: "stakeholder-map-tool-youlab.pptx",
		thumbnail: "Stakeholder map.jpeg",
	},
	{
		name: "Storyboard",
		tool: "storyboard-tool-youlab.pptx",
		thumbnail: "Storyboard.jpeg",
	},
	{
		name: "Systeem map",
		tool: "systeemmap-tool-youlab.pptx",
		thumbnail: "Systeemmap.jpeg",
	},
	{
		name: "Systeem vragen",
		tool: "systeemvragen-tool-youlab.pptx",
		thumbnail: "Systeemvragen.jpeg",
	},
	{
		name: "De five why’s",
		tool: "the-five-whys-tool-youlab.pptx",
		thumbnail: "The Five why's.jpeg",
	},
	{
		name: "Trendanalyse",
		tool: "trend-analyse-tool-youlab.pptx",
		thumbnail: "Trend analyse.jpeg",
	},
	{
		name: "Waardepropositie canvas",
		tool: "waardepropositie-canvas-tool-youlab.pptx",
		thumbnail: "Waardepropositie.jpeg",
	},
];
