import { useState, useRef } from "react";

// Core
import { db } from "core/firebase";
import { addDoc, collection } from "firebase/firestore";

// Components
import { IconLoading, IconFile } from "../icons";

const Upload = ({
	accept = "image/*",
	className = "",
	button = true,
	folderName = "",
}) => {
	const [loading, setLoading] = useState(false);
	// const [file, setFile] = useState(false);

	const input = useRef(null);

	const onUploadFile = async (e) => {
		const file = e.target.files[0];
		e.target.value = null;

		if (!file) {
			setLoading(false);
			return;
		}

		setLoading(true);

		await addDoc(collection(db, folderName));

		// const ref = firebaseDb.collection(this.props.databaseRef).doc();
	};

	const onClickInput = () => {
		input.current.click();
	};

	return (
		<div>
			<input
				className="hidden"
				type="file"
				style={{ display: "none" }}
				onChange={onUploadFile}
				ref={input}
				multiple={false}
				accept={accept}
			/>
			<button
				className={className}
				disabled={loading}
				onClick={onClickInput}
			>
				{button ? (
					loading && <IconLoading className="icon icon-s spin mr-s" />
				) : loading ? (
					<IconLoading className="icon icon-m spin mb" />
				) : (
					<IconFile className="icon icon-m mb" />
				)}
				<div className="fs-s">
					{loading
						? "Uploading"
						: "Klik om een bestand te selecteren"}
				</div>
			</button>
		</div>
	);
};

export default Upload;
