//  Components
import Button from "../../../button";
import User from "../../../utils/user";
import { Container } from "../../rows";
import { dateFull } from "../../../utils/dates";
import { IconSettings } from "../../../icons";

const Row = ({ item }) => {
    return (
        <Container>
            <div className="flex-1">
                <span className={`light ${item.user && "light-on"}`} />
            </div>

            <div className="flex-2">
                {item.user ? <User user={item.user} /> : item.email}
            </div>
            <div className="flex-1">
                <span className={`light ${item.roles.STUDENT && "light-on"}`} />
            </div>
            <div className="flex-1">
                <span className={`light ${item.roles.EXPERT && "light-on"}`} />
            </div>
            <div className="flex-1">
                <span
                    className={`light ${item.roles.SUPERVISOR && "light-on"}`}
                />
            </div>
            <div className="flex-2">
                <span className="mr-s fw-n">Op:</span>
                {dateFull(item.created_at)}
            </div>
            <div className="flex-1">
                <Button disabled className="full" text="Aanpassen">
                    <IconSettings className="icon icon-s ml-s" />
                </Button>
            </div>
        </Container>
    );
};

export default Row;
