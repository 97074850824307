import { Container, Label } from "../../columns";

const Columns = () => {
    return (
        <Container>
            <Label text="Logo" className="flex-1" />
            <Label text="Naam" className="flex-2" />
            <Label text="Datum" className="flex-2" />
            <Label text="Zichtbaarheid" className="flex-1" />
            <Label text="Aanpassen" className="flex-1" />
        </Container>
    );
};

export default Columns;
