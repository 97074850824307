// Components
import { IconTrash } from "components/icons";

const Invitation = ({ removeInvitation, item }) => {
	return (
		<div
			className={`flex flex-align flex-space full a-l mb border b-r p-box-s`}
		>
			<div className="flex flex-align">
				<div className="img img--s b-r flex-center bgc-g" />
				<div className="ml">
					<div className="fs-s fw-b">{item.email}</div>
					<div className="fs-xs fw-b color-main">
						Uitnodiging versturen
					</div>
				</div>
			</div>
			<button
				type="button"
				className="flex flex-align color-main"
				onClick={() => removeInvitation(item)}
			>
				<IconTrash className="icon icon-xs mr-s" />
				<span className="fs-xs">Uit de lijst halen</span>
			</button>
		</div>
	);
};

export default Invitation;
