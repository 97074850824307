import { useState } from "react";

// Core
import { createPlanning } from "core/application/dashboard/form-create-planning";
import { notificationStore } from "core/notifications/store";

// Variables
import { messages } from "variables/messages";
import { text } from "components/forms/application/project-planning-create/config";

// Components
import Slides from "components/forms/slides";
import Slide from "components/forms/slide";
import Header from "components/forms/header";
import Input from "components/inputs/input";
import TextArea from "components/inputs/textarea";
import Files from "components/files";
import DatePicker from "react-datepicker";

const Container = ({ closeForm, type }) => {
	const { addNotification } = notificationStore();

	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [files, setFiles] = useState([]);
	const [deadline, setDeadline] = useState(null);
	const [loading, setLoading] = useState(null);

	const onChangeDate = (payload) => {
		if (payload) {
			setDeadline(new Date(payload.setHours(23, 59, 59)));
		}
	};

	const onSubmit = async () => {
		setLoading(true);

		const error = await createPlanning({
			name,
			description,
			files,
			deadline,
		});

		setLoading(false);

		if (error) {
			addNotification({ message: messages.CREATE.ERROR });
			return;
		}

		addNotification({ message: messages.CREATE.SUCCESS });
		closeForm();
	};

	return (
		<Slides>
			<Slide required value={name}>
				<Header text={text[type].name} />
				<Input
					placeholder="Type de naam..."
					value={name}
					onChange={({ target }) => setName(target.value)}
				/>
			</Slide>
			<Slide required value={description}>
				<Header text={text[type].description} />
				<TextArea
					placeholder="Typ hier de omschrijving..."
					value={description}
					onChange={({ target }) => setDescription(target.value)}
				/>
			</Slide>

			<Slide>
				<Header text={text[type].files} />
				<Files files={files} setFiles={setFiles} isSingle={false} />
			</Slide>
			<Slide
				value={deadline}
				loading={loading}
				onSubmit={onSubmit}
				submit
			>
				<Header text={text[type].deadline} />
				<DatePicker
					name="deadline"
					selected={deadline}
					minDate={new Date()}
					placeholderText="Selecteer datum..."
					className="input"
					onChange={onChangeDate}
				/>
			</Slide>
		</Slides>
	);
};

export default Container;
