export const Label = ({ text = "", className = "" }) => {
    return <div className={`fs-s fw-b  ${className}`}>{text}</div>;
};

export const Container = ({ children }) => {
    return (
        <div className="list-labels flex flex-align flex-space c-sec">
            {children}
        </div>
    );
};
