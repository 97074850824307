import { useState } from "react";
import { NavLink } from "react-router-dom";

// Core
import { signInWithEmail } from "../../../core/authentication/actions";

// Config
import { errorCodes, routes } from "../config";

// Components
import Button from "components/button";
import Navigation from "../components/navigation";
import Socials from "../components/social";
import Label from "../../../components/inputs/label";
import Input from "../../../components/inputs/input-login";
import InputError from "../components/error";
import { IconLogin } from "../../../components/icons";

const Login = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const onSubmitSignIn = async (e) => {
        e.preventDefault();

        setLoading(true);

        const email = e.target["email"].value.toLowerCase().trim();
        const password = e.target["password"].value.trim();

        const error = await signInWithEmail({
            email,
            password,
        });

        if (error) {
            setLoading(false);
            setError(error);
        }
    };

    const errorEmail = !!(
        error &&
        (error === errorCodes.EMAIL ||
            error === errorCodes.EMAIL_IN_USE ||
            error === errorCodes.USER_NOT_FOUND)
    );

    const errorPassword = !!(
        error &&
        (error === errorCodes.PWD_WRONG || error === errorCodes.PWD_WEAK)
    );

    return (
        <section className="auth_layout pb-xl">
            <Navigation />
            <form onSubmit={onSubmitSignIn} noValidate className="container">
                <h1 className="center">Inloggen</h1>
                <p className="center pb fs-s c-sec">Login om verder te gaan.</p>
                <Label text="Email" className="pb-s" />
                <Input
                    name="email"
                    type="email"
                    autocomplete="on"
                    placeholder="Emailadres"
                    error={errorEmail}
                />
                <InputError error={error} active={errorEmail} />
                <Label text="Wachtwoord" className="pb-s" />
                <Input
                    name="password"
                    type="password"
                    placeholder="Wachtwoord"
                    autocomplete="current-password"
                    error={errorPassword}
                />
                <InputError error={error} active={errorPassword} />
                <div className="flex flex-align mt">
                    <Button
                        className="mr"
                        type="submit"
                        disabled={loading}
                        loading={loading}
                        text="Inloggen"
                    >
                        {!loading && <IconLogin className="icon icon-s ml-s" />}
                    </Button>
                    <p className="fs-s">
                        Wachtwoord vergeten?
                        <NavLink to={routes.RESET} className="color-main ml-s">
                            Klik hier
                        </NavLink>
                    </p>
                </div>
                <Socials />
            </form>
        </section>
    );
};

export default Login;
