const LoaderAnimation = ({ className }) => {
    return (
        <div className={`sk-circle ${className}`}>
            <div className="sk-circle1 sk-child" />
            <div className="sk-circle2 sk-child" />
            <div className="sk-circle3 sk-child" />
            <div className="sk-circle4 sk-child" />
            <div className="sk-circle5 sk-child" />
            <div className="sk-circle6 sk-child" />
            <div className="sk-circle7 sk-child" />
            <div className="sk-circle8 sk-child" />
            <div className="sk-circle9 sk-child" />
            <div className="sk-circle10 sk-child" />
            <div className="sk-circle11 sk-child" />
            <div className="sk-circle12 sk-child" />
        </div>
    );
};

const Loader = ({
    page = false,
    pageWithNav = false,
    pageWithSubNav = false,
    modal = false,
    className = "",
}) => {
    if (modal) {
        return (
            <div className="absolute w-screen h-screen flex items-center justify-center top">
                <LoaderAnimation className={className} />
            </div>
        );
    }

    return (
        <div
            className={`
                w-screen p-wrap flex items-center justify-center
                ${page && "h-screen"}
                ${pageWithNav && "h-screen-navigation"}
                ${pageWithSubNav && "h-screen-subNavigation"}
                `}
        >
            <LoaderAnimation className={className} />
        </div>
    );
};

export default Loader;
