import create from "zustand";

export const initialState = {
    application: null,
    applicationNotFound: null,
};

export const applicationStore = create((/*set*/) => ({
    ...initialState,
}));
