import { onSnapshot } from "firebase/firestore";
import { projectStore, initialState } from "./store";
import { unwrapSnapshot } from "core/utils";

export class ProjectListener {
    on(query) {
        const unsubscribe = onSnapshot(query, (doc) => {
            if (doc.exists()) {
                projectStore.setState({
                    project: unwrapSnapshot(doc),
                });
            } else {
                projectStore.setState({
                    projectNotFound: true,
                });
            }
        });

        this._unsubscribe = () => unsubscribe();
    }
    off() {
        projectStore.setState({ ...initialState });
        this._unsubscribe();
    }
}
