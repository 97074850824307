import { useState } from "react";

// Core
import { updatePlanning } from "core/application/project/form-edit-planning/actions";

//  Components
import Button from "components/button";
import Menu from "components/menu/utils/menu";
import Item from "components/menu/utils/item";
import { IconSettings, IconCheck, IconArchive } from "components/icons";

const Navigation = ({ item, canEdit }) => {
    const [active, setActive] = useState(false);

    const toggleCompleted = () => {
        updatePlanning({
            completed: !item.completed,
            completed_at: new Date(),
        });
    };

    const toggleArchived = () => {
        updatePlanning({
            archived: !item.archived,
            archived_at: new Date(),
        });
    };

    return (
        <div className="row flex-space mb-m">
            <div>
                {!item.completed && (
                    <Button text="Voltooien" onClick={toggleCompleted}>
                        <IconCheck className="icon icon-s ml-s" />
                    </Button>
                )}
            </div>
            <div className="relative">
                <Button onClick={() => setActive(true)}>
                    <IconSettings className="icon icon-s" />
                </Button>
                <Menu
                    className="p-t-r b"
                    active={active}
                    closeMenu={() => setActive(false)}
                >
                    <Item
                        text={item.completed ? "Terugzetten" : "Voltooien"}
                        onClick={toggleCompleted}
                    >
                        <IconCheck className="icon icon-xs mr-s" />
                    </Item>

                    <Item
                        text={item.archived ? "Terugzetten" : "Archiveren"}
                        onClick={toggleArchived}
                    >
                        <IconArchive className="icon icon-xs mr-s" />
                    </Item>
                </Menu>
            </div>
        </div>
    );
};

export default Navigation;
