// Components
import { IconPlay, IconImage, IconFile } from "../icons";

const types = {
    IMAGE: "image/*",
    VIDEO: "video/*",
    DOCUMENT: "application/*",
};

const FileType = ({ type }) => {
    if (!type) {
        return null;
    }

    if (type.match(types.IMAGE)) {
        return <IconImage className="icon icon-xs mr-s" />;
    }

    if (type.match(types.VIDEO)) {
        return <IconPlay className="icon icon-xs mr-s" />;
    }

    return <IconFile className="icon icon-xs mr-s" />;
};

export default FileType;
