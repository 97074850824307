import { useEffect, useState } from "react";

// Libraries
import { NavLink } from "react-router-dom";

// Config
import { errorCodes, routes, titles } from "../config";

// Firebase
import { resetWithEmail } from "../../../core/authentication/actions";

// Components
import Button from "components/button";
import { setTitle } from "../../../components/utils/title";
import Label from "../../../components/inputs/label";
import Input from "../../../components/inputs/input-login";
import InputError from "../components/error";
import { IconRight } from "../../../components/icons";

const Reset = () => {
	useEffect(() => {
		setTitle(titles.AUTH_RESET);
	}, []);

	const [isResetSuccessful, setReset] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const onSubmitReset = async (e) => {
		e.preventDefault();

		setLoading(true);

		const email = e.target["email"].value.toLowerCase().trim();

		const error = await resetWithEmail(email);

		setLoading(false);

		if (error) {
			setError(error);
		} else {
			setReset(true);
		}
	};

	if (isResetSuccessful) {
		return (
			<section className="auth_layout flex flex-center pb-xl">
				<div className="container center">
					<h1>Gelukt, je kunt nu een nieuw wachtwoord aanmaken!</h1>
					<p className="fs-s c-sec pt pb">
						Je hebt een mail ontvangen met daarin een link. Klik op
						de link en volg de instructies om je wachtwoord te
						veranderen. Daarna kun je inloggen met het nieuwe
						wachtwoord.
					</p>
					<NavLink to={routes.SIGN_IN} className="button full mt">
						Terug naar inloggen
					</NavLink>
				</div>
			</section>
		);
	}

	const errorEmail = !!(
		error &&
		(error === errorCodes.EMAIL || error === errorCodes.USER_NOT_FOUND)
	);

	return (
		<section className="auth_layout flex flex-center">
			<form
				onSubmit={onSubmitReset}
				noValidate
				className="container pb-xl"
			>
				<h1 className="center">Wachtwoord resetten</h1>
				<p className="center fs-s c-sec pb">
					Er wordt een email gestuurd met daarin een link om je
					wachtwoord te herstellen.
				</p>
				<Label text="Email" className="pb-s" />
				<Input
					name="email"
					type="email"
					autocomplete="on"
					placeholder="Emailadres"
					error={errorEmail}
				/>
				<InputError error={error} active={errorEmail} />
				<Button
					className="mt"
					text="Stuur een email"
					type="submit"
					loading={loading}
					disabled={loading}
				>
					<IconRight className="icon icon-s ml-s" />
				</Button>
			</form>
		</section>
	);
};

export default Reset;
