//  Components
import Button from "../../../button";
import User from "../../../utils/user";
import { Container } from "../../rows";
import { IconLink } from "../../../icons";

const Row = ({ item, openForm }) => {
    return (
        <Container>
            <div className="flex-2 fs-xs fw-b text-wrap pr">{item.name}</div>
            <div className="flex-1">
                <span className={`light ${item.visibility && "light-on"}`} />
            </div>
            <div className="flex-2">
                <User user={item.created_by} />
            </div>
            <div className="flex-2">
                <User user={item.supervised_by} />
            </div>
            <div className="flex-1">
                <Button
                    className="full"
                    text="Aanpassen"
                    onClick={() => openForm(item)}
                >
                    <IconLink className="icon icon-xs ml-s" />
                </Button>
            </div>
        </Container>
    );
};

export default Row;
