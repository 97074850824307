import { createProjectStore } from "../../../core/application/project/form-create-project";

// Components
import Button from "../../button";
import { IconPlus } from "../../icons";

const CardEmpty = () => {
    const { openForm } = createProjectStore();

    return (
        <div className="flex card col__12 col_m__4 col_l__3 mb-m">
            <div className="card-padding flex flex-col">
                <h2 className="mb-m pb">Mijn opgave starten</h2>
                <div>
                    <Button text="Opgave starten" onClick={openForm}>
                        <IconPlus className="icon icon-xs ml-s" />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CardEmpty;
