// Libraries
import { useTransition, animated } from "react-spring";

// - Components
import MenuEventHandler from "./event-handler";
import { animationMenu } from "../../../variables/animations";

const Menu = ({ active, children = "", closeMenu, className, wrap }) => {
    const transitions = useTransition(active, animationMenu);

    return transitions(
        (styles, item) =>
            item && (
                <animated.div className={`menu ${className}`} style={styles}>
                    <MenuEventHandler closeMenu={closeMenu}>
                        {wrap ? (
                            <div className="menu-wrap">{children}</div>
                        ) : (
                            children
                        )}
                    </MenuEventHandler>
                </animated.div>
            )
    );
};

export default Menu;
