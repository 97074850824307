// Libraries
import { NavLink } from "react-router-dom";

// Config
import { routes } from "../config";

const Navigation = () => {
    return (
        <div className="container">
            <nav className="auth_nav row flex-align flex-space center">
                <NavLink
                    to={routes.SIGN_IN}
                    className={({ isActive }) =>
                        isActive ? "color-main" : undefined
                    }
                >
                    Inloggen
                </NavLink>
                <NavLink
                    to={routes.REGISTER}
                    className={({ isActive }) =>
                        isActive ? "color-main" : undefined
                    }
                >
                    Registreren
                </NavLink>
            </nav>
        </div>
    );
};

export default Navigation;
