// Types
import { types } from "variables/types.planning";

// Core
import { updatePlanning } from "core/application/project/form-edit-planning/actions";

//  Components
import Button from "components/button";
import { IconArchive } from "components/icons";

const Completed = ({ item, canEdit }) => {
    const oneDay = 24 * 60 * 60 * 1000;

    const timestampToDate = (date) => {
        return new Date(date.seconds * 1000);
    };

    const datesToDaysDifference = (start, end) =>
        Math.round(Math.abs((start.getTime() - end.getTime()) / oneDay));

    const createMessage = (days) => {
        if (days === 0) {
            return "In minder dan 1 dag voltooid";
        }

        if (days < 7) {
            return "In minder dan 1 week voltooid";
        }

        if (days < 31) {
            return "In minder dan 1 maand voltooid";
        }

        return "In minder dan een jaar voltooid";
    };

    const toggleArchived = () => {
        updatePlanning({
            archived: !item.archived,
            archived_at: new Date(),
        });
    };

    const dateStarted = timestampToDate(item.created_at);
    const dateCompleted =
        item.type === types.ASSIGNMENT
            ? timestampToDate(item.reviewed_at)
            : timestampToDate(item.completed_at);

    const days = datesToDaysDifference(dateStarted, dateCompleted);
    const message = createMessage(days);

    return (
        <div className="row flex-nav bb pb-m mb-m">
            <div className="col__12 col_m__6 flex flex-center mb-mobile mt-mobile">
                <img
                    width="100%"
                    style={{ height: "12rem" }}
                    alt="Welkom bij YouLab - aanmelden"
                    src={`${process.env.PUBLIC_URL}/illustrations/first_task.svg`}
                />
            </div>
            <div className="col_m__6">
                <div className="c-sec fs-s fw-b">Tijd om te voltooien</div>
                <h1>{message}</h1>
                {canEdit && (
                    <Button
                        className="mt-s"
                        text="Archiveren"
                        onClick={toggleArchived}
                    >
                        <IconArchive className="icon icon-xs ml-s" />
                    </Button>
                )}
            </div>
        </div>
    );
};

export default Completed;
