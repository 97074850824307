import { useEffect, useState, Fragment } from "react";

// Core
import { db } from "../../../../core/firebase";
import { onSnapshot, where, collection, query } from "firebase/firestore";
import { unwrapList } from "../../../../core/utils";

// Components
import Loader from "components/utils/loader";
import PageEmpty from "../../../loading";
import Columns from "../../../../components/columns/columns/platform/admins";
import Row from "../../../../components/columns/rows/platform/admin";

const Admins = () => {
    const [list, setList] = useState(null);

    useEffect(() => {
        const q = query(
            collection(db, "admins"),
            where("roles.APPLICATION_ADMIN", "==", true)
        );

        const unsubscribe = onSnapshot(q, (payload) =>
            setList(unwrapList(payload))
        );

        return () => unsubscribe();
    }, []);

    if (!list) {
        return <Loader pageWithSubNav />;
    }

    if (!list.length) {
        return (
            <PageEmpty
                pageWithSubNav
                imageSlug="empty_library"
                text="Er zijn helaas nog geen gebruikers zichtbaar"
            />
        );
    }

    return (
        <Fragment>
            <div className="p-wrap">
                <Columns />
                {list.map((item) => (
                    <Row key={item.id} item={item} />
                ))}
            </div>
        </Fragment>
    );
};

export default Admins;
