import { useState } from "react";

// Store
import { notificationStore } from "../../../../core/notifications/store";
import { createProject } from "../../../../core/application/project/form-create-project";

// Variables
import { messages } from "../../../../variables/messages";

// Components
import Slides from "../../slides";
import Slide from "../../slide";
import Header from "../../header";
import Input from "../../../inputs/input";
import TextArea from "../../../inputs/textarea";

const Container = ({ closeForm }) => {
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [loading, setLoading] = useState(false);

    const { addNotification } = notificationStore();

    const onSubmit = async () => {
        setLoading(true);

        const error = await createProject({
            name,
            description,
        });

        setLoading(false);

        if (error) {
            addNotification({ message: messages.CREATE.ERROR, error });
            return;
        }

        closeForm();
    };

    return (
        <Slides>
            <Slide required value={name}>
                <Header text="Geef je opgave een naam." />
                <Input
                    placeholder="Type een naam..."
                    onChange={({ target }) => setName(target.value)}
                    value={name}
                />
            </Slide>
            <Slide required value={description}>
                <Header text="Geef je opgave een beschrijving." />
                <TextArea
                    placeholder="Type een beschrijving..."
                    onChange={({ target }) => setDescription(target.value)}
                    value={description}
                />
            </Slide>
            <Slide submit loading={loading} onSubmit={onSubmit}>
                <Header text="Weet je zeker dat alle informatie correct is?" />
                <h3 className="mt mb">{name}</h3>
                <p className="p">{description}</p>
            </Slide>
        </Slides>
    );
};

export default Container;
