import { useEffect, useState, Fragment } from "react";
import { collection, query, where } from "firebase/firestore";
import { db } from "core/firebase";
import { unwrapQuery } from "core/utils";
import { authStore } from "core/authentication/store";

// Core
import { editPlanningStore } from "core/application/project/form-edit-planning/store";
import { projectStore } from "core/application/project/project/store";
import { applicationStore } from "core/application/application/store";
import { updatePlanning } from "core/application/project/form-edit-planning/actions";

// Components
import Navigation from "./navigation";
import Completed from "./utils/completed";
import Tag from "components/planning/utils/tag";
import Content from "./task-content";
import Meta from "./meta";
import Messages from "./messages";
import Files from "./files";

const Container = () => {
	const { application } = applicationStore();
	const { project } = projectStore();
	const { item } = editPlanningStore();
	const { uid } = authStore().user;

	const [active, setFilesActive] = useState(false);

	const [
		{
			isInTeam,
			isCreatedBySupervisor,
			teamWithCreator,
			teamWithSupervisor,
		},
		setState,
	] = useState({
		isInTeam: false,
		isCreatedBySupervisor: false,
		teamWithCreator: [],
		teamWithSupervisor: [],
	});

	useEffect(() => {
		const initiateRoles = async () => {
			const qSupervisors = query(
				collection(db, "applications", application.id, "users"),
				where("roles.SUPERVISOR", "==", true)
			);

			const qAdmins = query(
				collection(db, "applications", application.id, "users"),
				where("roles.APPLICATION_ADMIN", "==", true)
			);

			const qTeam = collection(
				db,
				"applications",
				application.id,
				"projects",
				project.id,
				"team"
			);

			const [supervisors, admins, team] = await Promise.all([
				unwrapQuery(qSupervisors),
				unwrapQuery(qAdmins),
				unwrapQuery(qTeam),
			]);

			const list = [...admins, ...supervisors];

			const isCreatedBySupervisor = list.some(
				({ id }) => id === item.created_by.uid
			);

			const teamList = team.map((item) => item.user);

			// create a list of team members & the author
			const teamWithCreator = [
				...teamList.filter(
					(user) => user.uid !== project.created_by.uid
				),
				project.created_by,
			];

			// create a list of the team and the supervisor
			const teamWithSupervisor = [
				...teamWithCreator.filter(
					(user) => user.uid !== project.supervised_by.uid
				),
				project.supervised_by,
			];

			setState({
				isInTeam: !!project.users[uid],
				teamWithCreator,
				teamWithSupervisor,
				isCreatedBySupervisor,
			});
		};

		initiateRoles();
		// eslint-disable-next-line
	}, []);

	const checkContentEditPermissions = () => {
		if (isCreatedBySupervisor) {
			if (!!(item?.created_by?.uid === uid)) {
				return true;
			} else {
				return false;
			}
		}

		if (isInTeam) {
			return true;
		}

		return false;
	};

	const checkMetaEditPermissions = () => {
		if (isInTeam) {
			return true;
		}

		return false;
	};

	const setFiles = (payload) => {
		updatePlanning({ files: payload });
	};

	const canEditContent = checkContentEditPermissions();
	const canEditMeta = checkMetaEditPermissions();

	return (
		<Fragment>
			<div className="modal-planning-container">
				{canEditMeta && <Navigation item={item} />}
				{item.completed && (
					<Completed item={item} canEdit={!!canEditMeta} />
				)}
				<Tag type={item.type} />
				<Content
					item={item}
					canEdit={canEditContent}
					setFilesActive={setFilesActive}
				/>
				<Meta
					item={item}
					canEditContent={canEditContent}
					canEdit={canEditMeta}
					team={teamWithCreator}
				/>
				<Messages canEdit={canEditMeta} team={teamWithSupervisor} />
			</div>
			<Files
				active={active}
				closeForm={() => setFilesActive(false)}
				files={item?.files}
				setFiles={setFiles}
			/>
		</Fragment>
	);
};

export default Container;
