import { Fragment } from "react";

// Libraries
import { Outlet } from "react-router-dom";

// Components
import Navigation from "../../../components/navigations/application.dashboard";

const Dashboard = () => {
    return (
        <Fragment>
            <Navigation />
            <Outlet />
        </Fragment>
    );
};

export default Dashboard;
