const Navigation = ({ openUpload, setOpenUpload }) => {
	return (
		<nav className="flex pb">
			<button
				type="button"
				onClick={() => setOpenUpload(true)}
				className={`pb-m flex-1 flex flex-center fs-s ${
					openUpload ? "bb-main color-main" : "bb"
				}`}
			>
				Nieuw bestand uploaden
			</button>

			<button
				type="button"
				onClick={() => setOpenUpload(false)}
				className={`pb-m flex-1 flex flex-center  fs-s ${
					openUpload ? "bb" : "bb-main color-main"
				}`}
			>
				Bestand kiezen uit lijst
			</button>
		</nav>
	);
};

export default Navigation;
