//  Components
import Button from "../../../button";
import User from "../../../utils/user";
import { dateFull } from "../../../utils/dates";
import { IconSettings } from "../../../icons";
import { Container } from "../../rows";

const Row = ({ item }) => {
    return (
        <Container>
            <div className="flex-2">
                <User user={item} />
            </div>
            <div className="flex-2">{item.email}</div>
            <div className="flex-2">
                <span className="mr-s fw-n">Op:</span>
                {dateFull(item.created_at)}
            </div>
            <div className="flex-1">
                <Button disabled className="full" text="Aanpassen">
                    <IconSettings className="icon icon-s ml-s" />
                </Button>
            </div>
        </Container>
    );
};

export default Row;
