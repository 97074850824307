import { useRef, useState, useEffect } from "react";

// Core
import {
    collection,
    query,
    orderBy,
    where,
    startAfter,
    limit,
    getDocs,
} from "firebase/firestore";

import { db } from "core/firebase";
import { authStore } from "core/authentication/store";
import { unwrapList } from "core/utils";
import { editPlanningStore } from "core/application/project/form-message/store";

// Components
import Loader from "components/utils/loader";
import PageEmpty from "../../../loading";
import Button from "components/button";
import Message from "components/inbox";
import EditPlanning from "components/forms/application/message-planning-edit";

const Account = () => {
    const { uid } = authStore().user;
    const last = useRef(null);
    const [list, setList] = useState(null);
    const [loading, setLoading] = useState(false);
    const { openForm } = editPlanningStore();

    useEffect(() => {
        const q = query(
            collection(db, "messages"),
            where("userId", "==", uid),
            orderBy("created_at", "desc"),
            limit(5)
        );

        getDocs(q).then((payload) => {
            last.current = payload.docs[payload.docs.length - 1] || null;
            setList(unwrapList(payload));
        });
    }, [uid]);

    const getNextMessages = async () => {
        setLoading(true);

        const q = query(
            collection(db, "messages"),
            where("userId", "==", uid),
            orderBy("created_at", "desc"),
            startAfter(last.current),
            limit(5)
        );

        await getDocs(q).then((payload) => {
            last.current = payload.docs[payload.docs.length - 1] || null;
            setList([...list, ...unwrapList(payload)]);
        });

        setLoading(false);
    };

    if (!list) {
        return <Loader subPage />;
    }

    if (!list.length) {
        return (
            <PageEmpty
                subPage
                imageSlug="empty_library"
                text="Er zijn helaas nog geen berichten zichtbaar."
            />
        );
    }

    return (
        <div className="container flex flex-middle">
            <div className="container-m full">
                {list.map((item) => (
                    <Message key={item.id} item={item} openForm={openForm} />
                ))}
                {last.current && (
                    <Button
                        className="full mt mb-m"
                        text={loading ? "Laaden..." : "Meer berichten"}
                        disabled={loading}
                        onClick={getNextMessages}
                    ></Button>
                )}
            </div>
            <EditPlanning />
        </div>
    );
};

export default Account;
