import { Outlet } from "react-router-dom";

// Components
import Image from "components/images/illustration";
import Header from "./header";

const Root = () => {
    return (
        <main className="flex">
            <div className="auth_login_wrap">
                <Header />
                <Outlet />
            </div>
            <div className="auth_img_wrap">
                <div className="center">
                    <Image slug="login" className="auth__img" />
                    <div className="container auth__img">
                        <h1 className="mt-m color-main">
                            Aan de slag met Design Thinking!
                        </h1>
                        <p className="p mt">
                            Met YouLab leer je Design Thinking te gebruiken voor
                            het oplossen van complexe opgaven binnen je eigen
                            omgeving.
                        </p>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Root;
