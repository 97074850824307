import { useState } from "react";

// Components
import Navigation from "./navigation";
import List from "./list";
import Invitations from "./invitations";

const Container = ({ closeForm }) => {
	const [openList, setOpenList] = useState(true);

	return (
		<div className="modal-container">
			<h3 className="center mb">Teamlid toevoegen</h3>
			<Navigation openList={openList} setOpenList={setOpenList} />
			{openList ? (
				<List closeForm={closeForm} setOpenList={setOpenList} />
			) : (
				<Invitations closeForm={closeForm} />
			)}
		</div>
	);
};

export default Container;
