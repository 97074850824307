//  Components
import User from "components/utils/user";
import { IconCheck } from "components/icons";

const Selected = ({ team, selected = [], addUser = () => {} }) => {
    return team.map((item) => (
        <button
            key={item.id}
            className="flex flex-align flex-space p-box bb full"
            onClick={() => addUser(item)}
        >
            <User user={item} />
            {!!selected.some(({ uid }) => uid === item.uid) && (
                <IconCheck className="icon icon-s ml color-main" />
            )}
        </button>
    ));
};

export default Selected;
