import { useState } from "react";

// Core
import { createApplication } from "../../../../core/platform/form-create-application/actions";
import { createApplicationStore } from "../../../../core/platform/form-create-application/store";
import { notificationStore } from "../../../../core/notifications/store";

// Variables
import { messages } from "../../../../variables/messages";

// Components
import Slides from "../../slides";
import Slide from "../../slide";
import Header from "../../header";
import Input from "../../../inputs/input";

import Organizations from "./organizations";
import Admins from "./admins";
import Confirm from "./confirm.js";
import PaymentRequired from "./payment-required";

const Container = () => {
    const { addNotification } = notificationStore();
    const { toggleForm } = createApplicationStore();

    const [name, setName] = useState("");
    const [organization, setOrganization] = useState(null);
    const [admin, setAdmin] = useState(null);
    const [paymentRequired, setPaymentRequired] = useState(false);
    const [loading, setLoading] = useState(false);

    const onSubmitApplication = () => {
        setLoading(true);

        const { error = false } = createApplication({
            name,
            organization,
            payment_required: paymentRequired,
            supervised_by: admin,
        });

        setLoading(false);

        if (error) {
            addNotification({ message: messages.CREATE.ERROR, error });
            return;
        }

        toggleForm();
    };

    return (
        <Slides>
            <Slide required value={name}>
                <Header text="Geef de applicatie een naam." />
                <Input
                    placeholder="Naam van de applicatie..."
                    value={name}
                    onChange={({ target }) => setName(target.value)}
                />
            </Slide>
            <Slide required value={organization}>
                <Header text="Selecteer een organisatie" />
                <Organizations
                    organization={organization}
                    setOrganization={setOrganization}
                />
            </Slide>
            <Slide required value={admin}>
                <Header text="Selecteer een applicatie begeleider" />
                <Admins admin={admin} setAdmin={setAdmin} />
            </Slide>
            <Slide value={paymentRequired}>
                <Header text="Is er een betaling nodig door de gebruikers van de applicatie?" />
                <PaymentRequired
                    setPaymentRequired={setPaymentRequired}
                    paymentRequired={paymentRequired}
                />
            </Slide>
            <Slide submit loading={loading} onSubmit={onSubmitApplication}>
                <Header text="Weet je zeker dat alle informatie correct is?" />
                <Confirm
                    name={name}
                    organization={organization}
                    admin={admin}
                    paymentRequired={paymentRequired}
                />
            </Slide>
        </Slides>
    );
};

export default Container;
