// Core
import { editSupervisorStore } from "../../../../core/application/dashboard/form-edit-supervisor";

// Components
import Modal from "../../modal";
import Container from "./container";

const FormEditLibrary = () => {
    const { closeForm, active, project } = editSupervisorStore();

    return (
        <Modal active={active} toggle={closeForm}>
            <Container closeForm={closeForm} project={project} />
        </Modal>
    );
};

export default FormEditLibrary;
