import { IconEdit } from "../icons";

const ButtonRound = ({ onClick }) => {
    return (
        <div className="fixed bottom right mb-m p-wrap">
            <button className="button-round" onClick={onClick}>
                <span>
                    <IconEdit className="icon icon-s" />
                </span>
            </button>
        </div>
    );
};

export default ButtonRound;
