// Components
import Thumbnail from "components/images/thumbnail";
import { IconRestore, IconPlus } from "components/icons";

const User = ({ item, selected = [], toggleTeamMember = () => {} }) => {
	const isInTeam = selected.some((user) => user.id === item.id);

	return (
		<button
			onClick={() => toggleTeamMember(item)}
			className={`flex flex-align flex-space full a-l mb border b-r p-box-s
			${isInTeam ? "border-main " : ""}`}
		>
			<div className="flex flex-align">
				<Thumbnail user={item.user} />
				<div className="ml">
					<div className="fs-s fw-b">{item.user.displayName}</div>
					{isInTeam && (
						<div className="fs-xs fw-b color-main">
							Toevoegen aan het team
						</div>
					)}
				</div>
			</div>
			{isInTeam ? (
				<span className="flex flex-align color-main">
					<IconRestore className="icon icon-xs mr-s" />
					<span className="fs-xs">Uit de lijst halen</span>
				</span>
			) : (
				<span className="flex flex-align">
					<IconPlus className="icon icon-xs mr-s" />
					<span className="fs-xs">Toevoegen</span>
				</span>
			)}
		</button>
	);
};

export default User;
