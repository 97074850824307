import { useState, useEffect } from "react";

// Core
import { db } from "../../../../core/firebase";
import {
    collection,
    query,
    onSnapshot,
    orderBy,
    where,
} from "firebase/firestore";

import { unwrapList } from "../../../../core/utils";
// Config
import { roles } from "../../../../variables/roles";

// - Components
import Loader from "../../../utils/loader";
import Admin from "./admin";

const Admins = ({ admin, setAdmin }) => {
    const [list, setList] = useState(null);

    useEffect(() => {
        const q = query(
            collection(db, "admins"),
            where(`roles.${roles.APPLICATION_ADMIN}`, "==", true),
            orderBy("created_at", "desc")
        );

        const unsubscribe = onSnapshot(q, (payload) => {
            setList(unwrapList(payload));
        });

        return () => unsubscribe();
    }, []);

    if (!list) {
        return <Loader />;
    }

    return (
        <div className={`modal-select ${!list.length && "flex flex-center"}`}>
            {list.length ? (
                list.map((item) => (
                    <Admin
                        key={item.id}
                        user={item}
                        admin={admin}
                        setAdmin={setAdmin}
                    />
                ))
            ) : (
                <div className="fs-s fw-b c-sec">
                    Nog geen applicatie beheerders
                </div>
            )}
        </div>
    );
};

export default Admins;
