import create from "zustand";

import { authStore } from "../../../authentication/store";
import { db } from "../../../firebase";
import { addDoc, collection } from "firebase/firestore";
import { applicationStore } from "../../../application/application/store";

export const createLibraryStore = create((set) => ({
    active: false,
    type: null,
    openForm: (type = null) => set(() => ({ active: true, type })),
    closeForm: () => set(() => ({ active: false })),
}));

export const createLibrary = async (payload) => {
    const { user } = authStore.getState();
    const { type } = createLibraryStore.getState();
    const { application } = applicationStore.getState();

    const { error = null } = await addDoc(
        collection(db, "applications", application.id, "library"),
        {
            ...payload,
            applicationId: application.id,
            type,
            visibility: true,
            created_by: user,
            created_at: new Date(),
        }
    ).catch((error) => ({ error }));

    return error;
};
