import { useState } from "react";

// Core
import { createPlanningStore } from "core/application/dashboard/form-create-planning";
// Variables
import { types } from "variables/types.planning";

// Components
import { IconPlus } from "../../icons";
import Menu from "../utils/menu";
import Item from "../utils/item";

const MenuPlanning = ({ id, disabled, listId }) => {
    const { openForm } = createPlanningStore();
    const [active, setActive] = useState();

    const closeMenu = () => {
        setActive(false);
    };

    const openMenu = () => {
        setActive(true);
    };

    return (
        <div className="center relative">
            {!active && (
                <button
                    className="btn-planning flex-center mb"
                    onClick={openMenu}
                    disabled={disabled}
                >
                    <IconPlus className="icon icon-sm" />
                </button>
            )}
            <Menu
                closeMenu={closeMenu}
                active={active}
                className="menu-top-right"
            >
                <Item
                    text="Instructie"
                    onClick={() =>
                        openForm({ type: types.INSTRUCTION, listId: id })
                    }
                >
                    <IconPlus className="icon icon-s mr-s" />
                </Item>
                <Item
                    text="Actie"
                    onClick={() => openForm({ type: types.ACTION, listId: id })}
                >
                    <IconPlus className="icon icon-s mr-s" />
                </Item>
                <Item
                    text="Tool"
                    onClick={() => openForm({ type: types.TOOL, listId: id })}
                >
                    <IconPlus className="icon icon-s mr-s" />
                </Item>
                <Item
                    text="Reflectie"
                    onClick={() =>
                        openForm({ type: types.REFLECTION, listId: id })
                    }
                >
                    <IconPlus className="icon icon-s mr-s" />
                </Item>
            </Menu>
        </div>
    );
};

export default MenuPlanning;
