import { useState, useEffect } from "react";

// Libraries
import {
    collection,
    query,
    onSnapshot,
    where,
    orderBy,
} from "firebase/firestore";

// Core
import { db } from "../../../../core/firebase";
import { unwrapList } from "../../../../core/utils";

// Store
import { applicationStore } from "../../../../core/application/application/store";
import { editLibraryStore } from "../../../../core/application/library/form-edit-library";

// Components
import Loader from "../../../utils/loader";
import Card from "../../../cards/library";

const Container = () => {
    const { id } = applicationStore().application;
    const { openForm } = editLibraryStore();

    const [list, setList] = useState(null);

    useEffect(() => {
        const q = query(
            collection(db, "applications", id, "library"),
            where("visibility", "==", false),
            orderBy("created_at", "desc")
        );

        const unsubscribe = onSnapshot(q, (payload) =>
            setList(unwrapList(payload))
        );

        return () => unsubscribe();
    }, [id]);

    if (!list) {
        return <Loader modal />;
    }

    return (
        <div className="modal-container">
            <h3 className="mb-s">Bewaarde taken</h3>
            {list.map((item) => (
                <Card
                    key={item.id}
                    item={item}
                    canEdit
                    openForm={openForm}
                    archive
                />
            ))}
        </div>
    );
};

export default Container;
