import Button from "components/button";
import Menu from "components/menu/utils/menu";
import User from "./user";
import { IconPlus } from "components/icons";

const Navigation = ({ active, setActive, team, addUser, selected }) => {
    return (
        <div className="pb flex flex-align flex-space">
            <h3>Berichten</h3>
            <div className="relative">
                {!active && (
                    <Button
                        text="Teamlid taggen"
                        onClick={() => setActive(true)}
                    >
                        <IconPlus className="icon icon-s ml-s" />
                    </Button>
                )}
                <Menu
                    closeMenu={() => setActive(false)}
                    active={active}
                    className="p-t-r border"
                    wrap
                >
                    <User team={team} addUser={addUser} selected={selected} />
                </Menu>
            </div>
        </div>
    );
};

export default Navigation;
