import { useState, useEffect } from "react";

// Libraries
import { onSnapshot, query, collection, orderBy } from "firebase/firestore";
import { db } from "core/firebase";
import { unwrapList } from "core/utils";

// Core
import { authStore } from "core/authentication/store";
import { deleteFile } from "core/application/files/actions";

// Components
import Loader from "../utils/loader";
import File from "./file";

const Files = ({
	setFile = () => {},
	file = null,
	setFiles = () => {},
	files = [],
	isSingle = true,
}) => {
	const { uid } = authStore().user;

	const [list, setList] = useState(null);

	useEffect(() => {
		const q = query(
			collection(db, "users", uid, "library"),
			orderBy("created_at", "desc")
		);

		const unsubscribe = onSnapshot(q, (payload) =>
			setList(unwrapList(payload))
		);

		return () => unsubscribe();
	}, [uid]);

	const toggleFile = (payload) => {
		if (isSingle) {
			if (file?.id === payload.id) {
				setFile(null);
			} else {
				setFile(payload);
			}
		} else {
			if (files.some(({ id }) => id === payload.id)) {
				setFiles(files.filter(({ id }) => id !== payload.id));
			} else {
				setFiles([...files, payload]);
			}
		}
	};

	const removeFile = async (payload) => {
		await deleteFile(payload);
		// remove the file
		if (isSingle) {
			setFile(null);
		} else {
			setFiles(files.filter(({ id }) => id !== payload.id));
		}
	};

	return (
		<div className="files">
			<div className="files-container full">
				{!list && <Loader />}
				{!list?.length ? (
					<div className=" center pt-m mt-m c-sec">
						Er zijn nog geen bestanden in jouw bibliotheek
						opgeslagen. Voeg bestanden toe.
					</div>
				) : (
					list.map((item) => (
						<File
							key={item.id}
							item={item}
							canRemove
							files={files}
							file={file}
							isSingle={isSingle}
							toggleFile={toggleFile}
							removeFile={removeFile}
						/>
					))
				)}
			</div>
		</div>
	);
};

export default Files;
