const Grid = ({ children }) => {
    return (
        <div className="container pt-m pb-m row flex-space">
            {children}
            <div className="col__12 col_m__4 col_l__3" />
            <div className="col__12 col_m__4 col_l__3" />
        </div>
    );
};

export default Grid;
