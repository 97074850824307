import { writeBatch, doc, collection } from "firebase/firestore";

import { createPlanningStore } from "./store";
import { planningStore } from "core/application/project/planning/store";
import { applicationStore } from "core/application/application/store";
import { projectStore } from "core/application/project/project/store";
import { authStore } from "core/authentication/store";

import { db } from "core/firebase";

export const createPlanning = async (payload) => {
    const { user } = authStore.getState();
    const { project } = projectStore.getState();
    const { application } = applicationStore.getState();
    const { type, listId } = createPlanningStore.getState();
    const { list } = planningStore.getState();

    const batch = writeBatch(db);

    const filteredList = list
        .filter((item) => item.listId === listId)
        .sort((a, b) => a.position - b.position);

    if (filteredList && filteredList?.length) {
        filteredList.forEach((item, index) => {
            const ref = doc(
                db,
                "applications",
                application.id,
                "projects",
                project.id,
                "planning",
                item.id
            );

            batch.update(ref, { position: index + 1 });
        });
    }

    const planningRef = doc(
        collection(
            db,
            "applications",
            application.id,
            "projects",
            project.id,
            "planning"
        )
    );

    batch.set(planningRef, {
        ...payload,
        created_by: user,
        type,
        listId,
        applicationId: application.id,
        projectId: project.id,
        position: 0,
        visibility: true,
        archived: false,
        created_at: new Date(),
    });

    const error = await batch.commit().catch((error) => error);

    return error;
};
