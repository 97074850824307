// Components
import { IconLoading } from "../icons";

const Button = ({
    className = "",
    children,
    type = "button",
    text = "",
    loading = false,
    disabled = false,
    onClick = () => {},
}) => {
    return (
        <button
            className={`button ${className}`}
            onClick={onClick}
            type={type}
            disabled={disabled}
        >
            <span className="fs-s">{text}</span>
            {children}
            {loading && <IconLoading className="icon-s spin ml-s" />}
        </button>
    );
};

export default Button;
