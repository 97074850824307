import { Container, Label } from "../../columns";

const Columns = () => {
    return (
        <Container>
            <Label text="Aanmelding" className="flex-1" />
            <Label text="Email" className="flex-2" />
            <Label text="Deelnemer" className="flex-1" />
            <Label text="Expert" className="flex-1" />
            <Label text="Begeleider" className="flex-1" />
            <Label text="Datum" className="flex-2" />
            <Label text="Aanpassen" className="flex-1" />
        </Container>
    );
};

export default Columns;
