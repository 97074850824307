import { useEffect, useRef } from "react";

const EventHandler = ({ closeMenu, children }) => {
    const container = useRef();

    useEffect(() => {
        document.addEventListener("keyup", onKeyUp);
        document.addEventListener("mousedown", onMouseDown);
        return () => removeEventHandlers();
        // eslint-disable-next-line
    }, []);

    const removeEventHandlers = () => {
        document.removeEventListener("keyup", onKeyUp);
        document.removeEventListener("mousedown", onMouseDown);
    };

    const onKeyUp = (e) => {
        if (e.keyCode === 27) {
            removeEventHandlers();
            closeMenu();
        }
    };

    const onMouseDown = (e) => {
        if (!container.current.contains(e.target)) {
            removeEventHandlers();
            closeMenu();
        }
    };

    return (
        <div ref={container}>
            {children}
            <button className="menu-item" onClick={closeMenu}>
                <span className="fs-s">Sluiten (Esc)</span>
            </button>
        </div>
    );
};

export default EventHandler;
