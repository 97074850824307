import { onSnapshot } from "firebase/firestore";
import { authStore, initialState } from "./store";
import { unwrapSnapshot } from "../utils";
import { db } from "../firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";

export class UserListener {
    on(query) {
        authStore.setState({ isUserAuthenticated: true });

        const unsubscribe = onSnapshot(query, (doc) => {
            if (doc.exists()) {
                authStore.setState({
                    isUserLoaded: true,
                    user: unwrapSnapshot(doc),
                });
            } else {
                authStore.setState({ isUserNotFound: true });
            }
        });

        this._unsubscribe = () => unsubscribe();
    }
    off() {
        authStore.setState({ ...initialState });
        this._unsubscribe();
    }
}

export const createLocalUser = async (firebaseUser, formData) => {
    const user = {
        uid: firebaseUser.uid,
        displayName: `${formData.firstName} ${formData.lastName}`,
        email: firebaseUser.email,
        photoURL: firebaseUser.providerData[0].photoURL,
        created_at: new Date(),
    };

    await setDoc(doc(db, "users", firebaseUser.uid), user);

    return user;
};

export const createSocialUser = async (payload) => {
    const userSnapshot = await getDoc(doc(db, "users", payload.uid));

    if (userSnapshot.exists()) return userSnapshot.data();

    const user = {
        uid: payload.uid,
        displayName: payload.displayName,
        email: payload.email,
        photoURL: payload.photoURL || null,
        created_at: new Date(),
    };

    await setDoc(doc(db, "users", payload.uid), user);

    return user;
};
