import create from "zustand";

export const initialState = {
    active: false,
    id: null,
    item: null,
    itemNotFound: false,
};
export const editPlanningStore = create((set) => ({
    ...initialState,
    openForm: (id = null) => set(() => ({ active: true, id })),
    closeForm: () => set(() => ({ active: false })),
}));
