import { db } from "core/firebase";
import { applicationStore } from "core/application/application/store";
import { projectStore } from "../project/store";
import { writeBatch, doc } from "firebase/firestore";
import { roles } from "variables/roles";

export const createTeamMembers = (payload) => {
    const projectId = projectStore.getState().project.id;
    const applicationId = applicationStore.getState().application.id;

    const batch = writeBatch(db);

    payload.forEach((item) =>
        item.user
            ? createTeamMember(item, projectId, applicationId, batch)
            : createInvitation(item, projectId, applicationId, batch)
    );

    const error = batch.commit().catch((error) => ({ error }));

    return error;
};

const createTeamMember = (item, projectId, applicationId, batch) => {
    const teamRef = doc(
        db,
        "applications",
        applicationId,
        "projects",
        projectId,
        "team",
        item.id
    );

    batch.set(teamRef, {
        ...item,
        roles: {
            [roles.STUDENT_TEAM]: true,
        },
        created_at: new Date(),
    });

    const teamProjectRef = doc(
        db,
        "applications",
        applicationId,
        "projects",
        projectId
    );

    batch.update(teamProjectRef, {
        [`users.${item.id}`]: true,
    });
};

const createInvitation = (item, projectId, applicationId, batch) => {
    const refInvitation = doc(
        db,
        "invitations",
        item.email,
        "projects",
        projectId
    );

    batch.set(refInvitation, {
        ...item,
        roles: {
            [roles.STUDENT_TEAM]: true,
        },
        applicationId,
        created_at: new Date(),
    });

    const refInvitationProject = doc(
        db,
        "applications",
        applicationId,
        "projects",
        projectId,
        "invitations",
        item.email
    );

    batch.set(refInvitationProject, {
        ...item,
        roles: {
            [roles.STUDENT_TEAM]: true,
        },
        created_at: new Date(),
    });
};
