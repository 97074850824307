//  Components
import User from "components/utils/user";
import { IconCheck } from "components/icons";

const Team = ({ owner, team, updateTeam }) => {
    return team.map((item) => (
        <button
            key={item.uid}
            className="flex flex-align flex-space p-box bb full"
            onClick={() => updateTeam(item)}
        >
            <User user={item} />
            {!!(owner && owner.uid === item.uid) && (
                <IconCheck className="icon icon-xs ml color-main" />
            )}
        </button>
    ));
};

export default Team;
