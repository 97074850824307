import create from "zustand";

export const initialState = {
    project: null,
    projectNotFound: null,
};

export const projectStore = create((/*set*/) => ({
    ...initialState,
}));
