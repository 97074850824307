import { onSnapshot } from "firebase/firestore";
import { editPlanningStore, initialState } from "./store";
import { unwrapSnapshot } from "core/utils";

export class TaskListener {
    on(query) {
        const unsubscribe = onSnapshot(query, (doc) => {
            if (doc.exists()) {
                editPlanningStore.setState({
                    item: unwrapSnapshot(doc),
                });
            } else {
                editPlanningStore.setState({
                    itemNotFound: true,
                });
            }
        });

        this._unsubscribe = () => unsubscribe();
    }
    off() {
        editPlanningStore.setState((state) => ({ ...state, ...initialState }));
        this._unsubscribe();
    }
}
