// Core
import { planningArchiveStore } from "core/application/project/form-archive-planning";

//  Components
import Button from "components/button";
import User from "components/utils/user";
import Description from "./description";
import Team from "./team";
import { setUserRole } from "../utils";
import { IconArchive } from "components/icons";

const Info = ({ project, uid }) => {
    const { openForm } = planningArchiveStore();
    const { isAuthor } = setUserRole(project, uid);

    return (
        <div className="container flex row pt-l flex-space">
            <div className="col__12 col-4">
                <h1 className="mb-m">Opgave omschrijving</h1>
                <Description text={project.description} canEdit={isAuthor} />
                {isAuthor && (
                    <div className="mt-m">
                        <Button onClick={openForm} text="planningsarchief">
                            <IconArchive className="icon icon-xs ml-s" />
                        </Button>
                    </div>
                )}
            </div>
            <div className="col__12 col-8">
                <h1 className="mb-m">Projectteam</h1>
                <div className="flex row flex-space">
                    <div className=" col__12 col-6">
                        <div className="card">
                            <User
                                user={project.created_by}
                                text="Regisseur"
                                className="mb"
                            />
                            <User
                                user={project.supervised_by}
                                text="Begeleider"
                            />
                        </div>
                    </div>

                    <Team isAuthor={isAuthor} />
                </div>
            </div>
        </div>
    );
};

export default Info;
