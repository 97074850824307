// Libraries
import { NavLink } from "react-router-dom";

// Core
import { rolesStore } from "../../core/roles/store";
import { authStore } from "../../core/authentication/store";
import { createApplicationStore } from "../../core/platform/form-create-application/store";

// Config
import { routesPlatform } from "variables/routes.platform";

// Components
import { Logo, LogoSmall, IconPlus } from "../icons";
import Thumbnail from "../images/thumbnail";
import Alarm from "../utils/alarm";
import Button from "components/button";
const Navigation = () => {
    const roles = rolesStore(({ platformRoles }) => platformRoles);
    const user = authStore(({ user }) => user);
    const toggleForm = createApplicationStore(({ toggleForm }) => toggleForm);

    return (
        <nav className="navigation border-bottom">
            <div className="nav-main flex flex-align flex-space container">
                <NavLink to={routesPlatform.ROOT}>
                    <Logo className="logo-mobile  mb__off" />
                    <LogoSmall className="logo-mobile lap__off" />
                </NavLink>
                <div className="flex flex-align">
                    {roles?.ADMIN && (
                        <NavLink
                            className="button button-border mr"
                            to={routesPlatform.DASHBOARD}
                        >
                            <span>Beheer</span>
                        </NavLink>
                    )}
                    {roles?.ADMIN && (
                        <Button onClick={toggleForm} text="Applicatie aanmaken">
                            <IconPlus className="icon icon-s ml-s" />
                        </Button>
                    )}

                    <NavLink
                        to={routesPlatform.PROFILE}
                        className="relative flex flex-center ml "
                    >
                        <Thumbnail small user={user} />
                        <Alarm active /* write message handler */ />
                    </NavLink>
                </div>
            </div>
        </nav>
    );
};

export default Navigation;

// class Navigation extends Component {
//     componentDidMount() {
//         this.props.platformMessagesOn();
//     }

//     componentWillUnmount() {
//         this.props.platformMessagesOff();
//     }

//     render() {
//         const { user, toggleForm, roles, messagesSize } = this.props;

//         return (
//             <nav className="navigation nav-main flex flex-align flex-space container shadow">
//                 <NavLink to={routes.ROOT} exact>
//                     <Logo className="logo mb__off" />
//                     <LogoSmall className="logo-mobile lap__off" />
//                 </NavLink>
//                 <div className="flex flex-align">
//                     {roles.ADMIN && (
//                         <NavLink
//                             className="btn btn--gray fs-s mr"
//                             to={routes.DASHBOARD}
//                         >
//                             <span>Beheer</span>
//                         </NavLink>
//                     )}
//                     {roles.ADMIN && (
//                         <button className="btn" onClick={toggleForm}>
//                             <span className="mb__off fs-s  mr-s">
//                                 Applicatie aanmaken
//                             </span>
//                             <IconPlus className="icon icon-s" />
//                         </button>
//                     )}

//                     <NavLink
//                         to={routes.PROFILE}
//                         className="relative flex flex-center ml "
//                     >
//                         <UserImage small user={user} />
//                         <Alarm open={!!messagesSize} />
//                     </NavLink>
//                 </div>
//             </nav>
//         );
//     }
// }

// const mapStateToProps = createSelector(
//     getUser,
//     getMessagesSize,
//     (user, messagesSize) => ({
//         user,
//         messagesSize,
//     })
// );

// const mapDispatchToProps = {
//     toggleForm,
//     platformMessagesOn,
//     platformMessagesOff,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
