// Libraries
import { Link, useSearchParams } from "react-router-dom";

const LibraryLink = ({ name = "", query = "" }) => {
    let [searchParams] = useSearchParams();
    let type = searchParams.get("type") || "";

    return (
        <Link
            to={`${query && query.length ? `?type=${query}` : ""}`}
            className={`link ${type === query ? "link-active" : ""}`}
        >
            {name}
        </Link>
    );
};

export default LibraryLink;
