import create from "zustand";

export const createPlanningStore = create((set) => ({
    active: false,
    type: null,
    listId: null,
    openForm: ({ type = null, listId = null }) =>
        set(() => ({ active: true, type, listId })),
    closeForm: () => set(() => ({ active: false })),
}));
