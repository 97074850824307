//
import { editStageStore } from "core/application/project/form-edit-stage/store";

// Components
import Modal from "components/forms/modal";
import Container from "./container";

const EditStage = () => {
    const { active, closeForm, id } = editStageStore();

    return (
        <Modal active={active} toggle={closeForm} id={id}>
            <Container closeForm={closeForm} id={id} />
        </Modal>
    );
};

export default EditStage;
