import { useState } from "react";

// Variables
import { roles } from "variables/roles";

//  Components
import Menu from "components/menu/utils/menu";
import Item from "components/menu/utils/item";
import User from "components/utils/user";
import { IconEdit, IconCheck, IconTrash } from "components/icons";

const TeamMember = ({ item, isAuthor, editTeamMember, removeTeamMember }) => {
    const [active, setActive] = useState(false);

    const setRole = async (payload) => {
        let attrs = {};

        attrs.user = item.user;
        attrs.role = payload;

        await editTeamMember(attrs);
        setActive(false);
    };

    return (
        <div className="flex flex-align flex-space pb full">
            <User
                user={item.user}
                text={
                    item.roles.EXPERT || item.roles.EXPERT_TEAM
                        ? "expert"
                        : "deelnemer"
                }
            />

            {isAuthor && (
                <div className="relative">
                    <button className="button" onClick={() => setActive(true)}>
                        <IconEdit className="icon icon-xs" />
                    </button>
                    <Menu
                        className="p-t-r"
                        active={active}
                        closeMenu={() => setActive(false)}
                    >
                        <Item
                            text="Deelnemer"
                            onClick={() => setRole(roles.STUDENT_TEAM)}
                        >
                            {item.roles.STUDENT_TEAM && (
                                <IconCheck className="icon icon-xs mr-s color-main" />
                            )}
                        </Item>
                        <Item
                            text="Expert"
                            onClick={() => setRole(roles.EXPERT_TEAM)}
                        >
                            {item.roles.EXPERT_TEAM && (
                                <IconCheck className="icon icon-xs mr-s color-main" />
                            )}
                        </Item>
                        <Item
                            text="Verwijderen"
                            className="color-red"
                            onClick={() => {}}
                        >
                            <IconTrash className="icon icon-xs mr-s" />
                        </Item>
                    </Menu>
                </div>
            )}
        </div>
    );
};

export default TeamMember;

/* 
class TeamMember extends Component {
    state = {
        menuOpen: false,
    };

    toggleMenu = () => {
        this.setState((state) => ({
            menuOpen: !state.menuOpen,
        }));
    };

    editTeamMember = (payload) => {
        let attrs = {};

        attrs.user = this.props.item.user;
        attrs.role = payload;

        this.props.editTeamMember(attrs);
        this.toggleMenu();
    };

    render() {
        const { item, removeTeamMember, isAuthor } = this.props;

        return (
            <div className="flex flex-align flex-space pb full">
                <div className="col__6_no_gap">
                    <User user={item.user} />
                </div>

                <div className="flex flex-align col__6_no_gap">
                    {isAuthor ? (
                        <div className="relative">
                            <button
                                className="btn btn--border"
                                onClick={this.toggleMenu}
                            >
                                <span className="fs-s">
                                    {item.roles.EXPERT || item.roles.EXPERT_TEAM
                                        ? "expert"
                                        : "deelnemer"}
                                </span>
                                <IconOpen className="icon icon-s ml-s" />
                            </button>
                            <Menu
                                className="p-t-r b"
                                open={this.state.menuOpen}
                                toggle={this.toggleMenu}
                            >
                                <MenuItem
                                    text="Deelnemer"
                                    onClick={() =>
                                        this.editTeamMember(roles.STUDENT_TEAM)
                                    }
                                >
                                    {item.roles.STUDENT_TEAM && (
                                        <IconCheck className="icon icon-xs mr-s c-main" />
                                    )}
                                </MenuItem>
                                <MenuItem
                                    text="Expert"
                                    onClick={() =>
                                        this.editTeamMember(roles.EXPERT_TEAM)
                                    }
                                >
                                    {item.roles.EXPERT_TEAM && (
                                        <IconCheck className="icon icon-xs mr-s c-main" />
                                    )}
                                </MenuItem>
                            </Menu>
                        </div>
                    ) : (
                        <span className="fs-s fw-b c-sec">
                            {item.roles.EXPERT || item.roles.EXPERT_TEAM
                                ? "expert"
                                : "deelnemer"}
                        </span>
                    )}

                    {isAuthor && (
                        <button
                            className="p-box-s ml pl c-sec"
                            onClick={() => removeTeamMember(item)}
                        >
                            <span>
                                <IconError className="icon icon-s" />
                            </span>
                        </button>
                    )}
                </div>
            </div>
        );
    }
}

export default TeamMember;
*/
