// Libraries
import { Link } from "react-router-dom";

// Components
import User from "../../utils/user";

const Tag = ({ isOwner, isSupervisor, isTeamMember }) => {
    return Boolean(isOwner || isSupervisor || isTeamMember) ? (
        <div className="mb-s">
            <div
                style={{ verticalAlign: "middle" }}
                className={`tag fs-xs fw-b mr-s
                    ${isOwner && "bgc-main"}
                    ${isSupervisor && "bgc-r"}
                    ${isTeamMember && "bgc-y"}`}
            >
                <span>
                    {isOwner && "Mijn project"}
                    {isSupervisor && "Begeleider"}
                    {isTeamMember && "Mijn team"}
                </span>
            </div>
        </div>
    ) : null;
};

const Card = ({ item, uid }) => {
    const { created_by, supervised_by, users } = item;

    const isOwner = Boolean(created_by?.uid === uid);
    const isSupervisor = Boolean(supervised_by?.uid === uid);
    const isTeamMember = Boolean(!isOwner && !isSupervisor && users[uid]);

    return (
        <Link
            to={`project/${item.id}`}
            className={`flex card col__12 col_m__4 col_l__3 mb-m
            ${isSupervisor && "border-red"}
            ${isOwner && "border-main"}
            ${isTeamMember && "border-yellow"}`}
        >
            <div className="card-padding flex flex-col">
                <Tag
                    isOwner={isOwner}
                    isSupervisor={isSupervisor}
                    isTeamMember={isTeamMember}
                />
                <h2>{item.name}</h2>
                <div className="grow"></div>
                <div>
                    <User
                        user={item.created_by}
                        text="Regisseur"
                        className="mt"
                    />
                </div>
            </div>
        </Link>
    );
};

export default Card;
