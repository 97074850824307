import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { setUserListener } from "./actions";

export const checkUserAuthentication = () =>
    new Promise((resolve, reject) => {
        const unsubscribe = onAuthStateChanged(
            auth,
            (user) => {
                user && setUserListener(user);
                unsubscribe();
                resolve();
            },
            (error) => reject(error)
        );
    });
