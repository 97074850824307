import Modal from "../../../modal";
import Container from "./container";

const FormOrganizations = ({
    active,
    setActive,
    organization,
    setOrganization,
}) => {
    return (
        <Modal active={active} toggle={() => setActive(!active)}>
            <Container
                organization={organization}
                setOrganization={setOrganization}
            />
        </Modal>
    );
};

export default FormOrganizations;
