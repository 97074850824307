import { useState } from "react";

import { roles } from "../../../../variables/roles";
import { createInvitation } from "../../../../core/application/dashboard/form-create-invitation";
import { notificationStore } from "../../../../core/notifications/store";
import { messages } from "../../../../variables/messages";

// Components
import Slides from "../../slides";
import Slide from "../../slide";
import Header from "../../header";
import Input from "../../../inputs/input";
import Switch from "./switch";

const Container = ({ closeForm }) => {
    const { addNotification } = notificationStore();

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [role, setRole] = useState(roles.STUDENT);

    const onSubmit = async () => {
        setLoading(true);

        let attrs = {};
        attrs.email = email.toLowerCase().trim();
        attrs.roles = { [role]: true };

        const error = await createInvitation(attrs);

        setLoading(false);

        if (error) {
            addNotification({ message: messages.CREATE.ERROR });
            return;
        }

        addNotification({ message: messages.CREATE.SUCCESS });
        closeForm();
    };

    return (
        <Slides>
            <Slide required value={email}>
                <Header text="Type het emailadres van wie je wilt uitnodigen" />
                <Input
                    value={email}
                    onChange={({ target }) => setEmail(target.value)}
                    placeholder="Type het emailadres hier..."
                />
            </Slide>
            <Slide required value={role}>
                <Header text="Selecteer een rol" />
                <Switch
                    value={role}
                    role={roles.STUDENT}
                    text="Deelnemer"
                    setActive={setRole}
                />
                <Switch
                    value={role}
                    role={roles.EXPERT}
                    text="Expert"
                    setActive={setRole}
                />
                <Switch
                    value={role}
                    role={roles.SUPERVISOR}
                    text="Begeleider"
                    setActive={setRole}
                />
            </Slide>
            <Slide submit loading={loading} onSubmit={onSubmit}>
                <Header
                    className="mb-m center"
                    text="Weet je zeker dat alle informatie correct is?"
                />
            </Slide>
        </Slides>
    );
};

export default Container;
