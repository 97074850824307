import Modal from "components/forms/modal";
import Container from "./container";

const PlanningEdit = ({ active, closeForm, files = [], setFiles }) => {
    return (
        <Modal active={active} toggle={closeForm}>
            <Container
                closeForm={closeForm}
                files={files}
                setFiles={setFiles}
            />
        </Modal>
    );
};

export default PlanningEdit;
