//  Components
import { Container } from "../../rows";
import Button from "../../../button";
import Image from "../../../images/image";
import { dateFull } from "../../../utils/dates";
import { IconSettings } from "../../../icons";

const Row = ({ item }) => {
    return (
        <Container>
            <div className="flex-1">
                <Image
                    url={item.thumbnail.downloadURL}
                    width="100%"
                    className="flex flex-center img--sm b-r b p-box-s"
                />
            </div>
            <div className="flex-2">{item.name}</div>
            <div className="flex-2">
                <span className="mr-s fw-n">Op:</span>
                {dateFull(item.created_at)}
            </div>

            <div className="flex-1 ">
                <div
                    className={`light light-on ${
                        !item.visibility && "light-off"
                    }`}
                />
            </div>
            <div className="flex-1">
                <Button disabled className="full" text="Aanpassen">
                    <IconSettings className="icon icon-s ml-s" />
                </Button>
            </div>
        </Container>
    );
};

export default Row;
