import React from "react";

// - Components
import Image from "../../../images/image";
import User from "../../../utils/user";
import Label from "../../../inputs/label";

const Confirm = ({ name, organization, admin }) => {
    return (
        <div className="card card-padding b">
            <Label text="applicatie" className="pb-s" />
            <div className="flex flex-align">
                <Image
                    url={organization.thumbnail.downloadURL}
                    width="100%"
                    className="flex flex-center img--sm b-r b p-box-s"
                />

                <div className="ml flex-2">
                    <div className="fw-b">{name}</div>
                    <div className="fs-s c-sec">
                        Organisatie: <b>{organization.name}</b>
                    </div>
                </div>
            </div>
            <Label text="Beheerder" className="pt-s pb-s" />
            <User user={admin} desc="Applicatie beheerder" />
        </div>
    );
};

export default Confirm;
