const Navigation = ({ openList, setOpenList }) => {
	return (
		<nav className="flex pb">
			<button
				type="button"
				onClick={() => setOpenList(true)}
				className={`pb-m flex-1 flex flex-center fs-s ${
					openList ? "bb-main color-main" : "bb"
				}`}
			>
				Zoek en selecteer uit lijst
			</button>

			<button
				type="button"
				onClick={() => setOpenList(false)}
				className={`pb-m flex-1 flex flex-center  fs-s ${
					openList ? "bb" : "bb-main color-main"
				}`}
			>
				Toevoegen via uitnodiging
			</button>
		</nav>
	);
};

export default Navigation;
