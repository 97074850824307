// Libraries
import { NavLink } from "react-router-dom";

// Variables
import { filters } from "../../variables/routes.application";

// Core
import { planningArchiveStore } from "core/application/dashboard/form-archive-planning";

// Components
import Button from "components/button";
import { IconArchive } from "components/icons";

const Navigation = () => {
    const { openForm } = planningArchiveStore();
    return (
        <nav className="navigation border-bottom">
            <div className="flex flex-space nav-main container">
                <div className="flex flex-align">
                    <Button text="Archive" onClick={openForm}>
                        <IconArchive className="icon icon-s ml" />
                    </Button>
                </div>
                <div className="flex flex-align">
                    <NavLink
                        to={filters.ROOT}
                        end
                        className={({ isActive }) =>
                            `link ${isActive && "link-active"}`
                        }
                    >
                        <span>Planning</span>
                    </NavLink>
                    <NavLink
                        to={filters.INVITATIONS}
                        className={({ isActive }) =>
                            `link ${isActive && "link-active"}`
                        }
                    >
                        <span>Uitnodigingen</span>
                    </NavLink>
                    <NavLink
                        to={filters.SUPERVISORS}
                        className={({ isActive }) =>
                            `link ${isActive && "link-active"}`
                        }
                    >
                        <span>Begeleiders</span>
                    </NavLink>
                </div>
            </div>
        </nav>
    );
};

export default Navigation;
