import { db } from "core/firebase";
import { doc, deleteDoc } from "firebase/firestore";

import { authStore } from "core/authentication/store";

export const deleteFile = async (payload) => {
    const { uid } = authStore.getState().user;

    const ref = doc(db, "users", uid, "library", payload.id);

    const error = deleteDoc(ref).catch((error) => error);

    return error;
};
