// - Components
import { IconAlarm, IconError } from "components/icons";
import { dateFull } from "components/utils/dates";

const Deadline = ({ deadline }) => {
    const currentDate = new Date();
    const currentDeadline = new Date(deadline.seconds * 1000);

    if (currentDate > currentDeadline) {
        return (
            <div className="tag fs-xs c-sec text-wrap pr fw-b">
                <IconError className="icon icon-xs mr-s" />
                Deadline verlopen
            </div>
        );
    }

    return (
        <div className="flex flex-align fw-b">
            <IconAlarm className="icon icon-xs mr-s" />
            <span className="fs-xs">{dateFull(deadline)}</span>
        </div>
    );
};

export default Deadline;
/*
import React, { useState } from "react";

// - Components
import DatePicker from "react-datepicker";
import { datePerciseFull } from "../../utils/dates";
import { IconAlarm, IconOpen } from "../../icons";

export default ({ canEdit, deadline, update }) => {
    const [open, toggle] = useState(false);

    const changeDeadline = (payload) => {
        if (payload) {
            update({
                deadline: new Date(payload.setHours(23, 59, 59)),
            });
            toggle(false);
        }
    };

    return (
        <div>
            <div className="c-sec fw-b fs-s pb-s">Deadline:</div>
            {canEdit ? (
                <div className="flex flex-align">
                    <IconAlarm className="icon icon-xs mr-s" />
                    {open ? (
                        <DatePicker
                            name="deadline"
                            autoFocus={true}
                            selected={
                                deadline
                                    ? new Date(deadline.seconds * 1000)
                                    : null
                            }
                            minDate={new Date()}
                            placeholderText="Selecteer datum..."
                            className="fs-s fw-b"
                            onChange={changeDeadline}
                            onBlur={() => toggle(false)}
                        />
                    ) : (
                        <button
                            className="full flex flex-align flex-space"
                            onClick={() => toggle(!open)}
                        >
                            {deadline
                                ? datePerciseFull(deadline)
                                : "Geen deadline"}

                            <IconOpen className="icon icon-sm" />
                        </button>
                    )}
                </div>
            ) : (
                <div className="flex flex-align">
                    <IconAlarm className="icon icon-xs mr-s" />
                    <div className="fs-s fw-b">
                        {deadline ? datePerciseFull(deadline) : "Geen deadline"}
                    </div>
                </div>
            )}
        </div>
    );
};

*/
