import { useState, useRef } from "react";

// Core
import { updateProjectDescription } from "core/application/project/project/actions";

// Components
import Button from "components/button";
import TextArea from "components/inputs/textarea";

const Description = ({ text = "", canEdit, chars = 180 }) => {
    const [activeEdit, setActiveEdit] = useState(false);
    const [activeReadMore, setActiveReadMore] = useState(false);
    const [description, setDescription] = useState(text);
    
    const readMore = useRef(text.length > chars);
    const textRef = useRef(readMore.current ? text.substring(0, chars) : text);


    const onSubmit = (e) => {
        e.preventDefault();
        updateProjectDescription(description);
        setActiveEdit(false);
        textRef.current = description.substring(0, chars);
        readMore.current = description.length > chars;
    };


    if (activeEdit) {
        return (
            <form className="p-box b-r input-edit-form" onSubmit={onSubmit}>
                <TextArea
                    className="mb-s fw-n"
                    value={description}
                    onChange={({ target }) => setDescription(target.value)}
                    autoFocus
                />

                <div className="flex flex-reverse">
                    <Button
                        text="aanpassen (enter)"
                        className="ml"
                        onClick={onSubmit}
                    />
                    <Button
                        text="annuleren"
                        className="button-text"
                        onClick={() => setActiveEdit(false)}
                    />
                </div>
            </form>
        );
    }

    return (
        <p className={`p`}>
            {activeReadMore ? text : textRef.current}{" "}
            {readMore.current && (
                <button
                    className="color-main mr-s"
                    onClick={() => setActiveReadMore(!activeReadMore)}
                >
                    {activeReadMore ? "sluiten " : "... lees meer"}
                </button>
            )}
            {canEdit && (
                <button
                    className="color-main"
                    onClick={() => setActiveEdit(true)}
                >
                    - aanpassen
                </button>
            )}
        </p>
    );
};

export default Description;
