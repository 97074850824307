import { useRef, useEffect } from "react";

// Libraries
import { animated } from "react-spring";

// - Components
import { IconCheck, IconError } from "../icons";

const Notification = ({ removeNotification, item, styles }) => {
    const timer = useRef();

    useEffect(() => {
        const id = setInterval(() => {
            removeNotification(item);
        }, 4000);

        timer.current = id;

        return () => clearInterval(timer.current);
        //eslint-disable-next-line
    }, []);

    const closeNotification = () => removeNotification(item);

    return (
        <animated.div
            style={styles}
            onClick={closeNotification}
            className={`notification flex-align radius pt pb pl pr mb ${
                item.error ? "bgc-r" : "bgc-re"
            }`}
        >
            {item.error ? (
                <IconError className="icon icon-sm" />
            ) : (
                <IconCheck className="icon icon-sm" />
            )}

            <div className="fs-s fw-b ml">{item.message}</div>
        </animated.div>
    );
};

export default Notification;
