import { onSnapshot } from "firebase/firestore";
import { planningStore, initialState } from "./store";
import { unwrapList } from "core/utils";

export class PlanningListener {
    on(query) {
        const unsubscribe = onSnapshot(query, (payload) => {
            planningStore.setState({
                list: unwrapList(payload),
            });
        });

        this._unsubscribe = () => unsubscribe();
    }
    off() {
        planningStore.setState({ ...initialState });
        this._unsubscribe();
    }
}
