import { updatePlanning } from "core/application/project/form-edit-planning/actions";

// Components
import User from "components/utils/user";
import Label from "components/inputs/label";
import Deadline from "components/planning/edit/deadline";
import Owner from "../utils/owner";
import { IconPlus } from "components/icons";
import { dateFull } from "components/utils/dates";

const Meta = ({ item, canEdit = false, canEditContent = false, team = [] }) => {
    const setDate = (payload) => {
        updatePlanning({
            deadline: payload,
        });
    };

    return (
        <div className="pt-m mt-m bt">
            <div className="row flex-space">
                <div className="col-6">
                    <Label text="Verantwoordelijke:" className="mb-s" />
                    <Owner
                        owner={item.owner}
                        canEdit={canEdit}
                        updatePlanning={updatePlanning}
                        team={team}
                    />
                </div>
                <div className="col-6">
                    <Label text="Gestart door:" className="mb-s" />
                    <User user={item.author || item.created_by} />
                </div>
            </div>
            <div className="row flex-space mt">
                <div className="col-6">
                    <Deadline
                        deadline={item.deadline}
                        canEdit={canEditContent}
                        setDate={setDate}
                    />
                </div>
                <div className="col-6">
                    <Label text="Aangemaakt op:" className="mb-s" />
                    <div className="flex flex-align fs-s fw-b">
                        <IconPlus className="icon icon-xs mr-s" />
                        {dateFull(item.created_at)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Meta;

/*
{item.owner ? (
            <User user={item.owner} />
        ) : (
            <div className="flex flex-align full">
                <div>
                    <div className="flex flex-center img img--s b-r bgc-g c-sec">
                        <IconHelp className="icon icon-s" />
                    </div>
                </div>
                <div className="fs-s fw-b ml-s c-sec full text-wrap">
                    Geen verantwoordelijke
                </div>
            </div>
        )}
        <Label text="Gestart door:" className="mb-s mt" />
        <User user={item.created_by} />
*/
