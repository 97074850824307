import { useState } from "react";

// Core
import { createMessage } from "core/application/project/form-edit-planning/actions";

// Components
import Button from "components/button";
import TextArea from "components/inputs/textarea";

const Form = ({ selected, clearUsers }) => {
    const [message, setMessage] = useState("");

    const onSubmit = async () => {
        const error = await createMessage({
            message,
            users: selected,
        });

        console.log(error);
        clearUsers();
        setMessage("");
    };

    return (
        <div>
            <TextArea
                rows={4}
                placeholder="Schijf hier je bericht..."
                onChange={({ target }) => setMessage(target.value)}
                value={message}
            />

            <Button
                className={`mt ${!message.length && "button-disabled"}`}
                disabled={!message.length}
                text="Versturen"
                onClick={onSubmit}
            />
        </div>
    );
};

export default Form;
