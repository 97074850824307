// Components
import User from "components/utils/user";
import { IconError, IconSend } from "components/icons";

const Invitation = ({ item, removeInvitation, isAuthor }) => {
    return (
        <div className="flex flex-align flex-space pb">
            <div className="flex-2">
                {item.user ? (
                    <User user={item.user} />
                ) : (
                    <div className="flex flex-align">
                        <div className="img img--s brc-g b-r" />
                        <div className="ml">
                            <div className="fw-b fs-s">{item.email}</div>
                            <div className="flex flex-align fs-xs c-sec relative">
                                <div>Uitnodiging verstuurd</div>
                                <IconSend className="icon icon-xxs ml-s" />
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {isAuthor && (
                <button
                    className="button"
                    onClick={() => removeInvitation(item)}
                >
                    <IconError className="icon icon-xs" />
                </button>
            )}
        </div>
    );
};

export default Invitation;
