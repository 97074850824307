export const sortByDate = (payload = []) => {
    return payload.sort(
        (a, b) => b.created_at.toDate() - a.created_at.toDate()
    );
};

export const setFormate = (bytes) => {
    const k = 1000;
    const dm = 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
