// - Variables
import { types } from "variables/types.planning";

const Tag = ({ type, className = "" }) => {
    return (
        <span
            className={`tag fs-xs fw-b
        ${type === types.INSTRUCTION && "bgc-y"}
        ${type === types.REFLECTION && "bgc-re"}
        ${type === types.ACTION && "bgc-r"}
        ${type === types.TOOL && "bgc-p"}
        ${type === types.ASSIGNMENT && "bgc-p"}
        ${type === types.TASK && "bgc-r"}
        ${className}`}
        >
            {type === types.INSTRUCTION && "Instructie"}
            {type === types.REFLECTION && "Reflectie"}
            {type === types.ACTION && "Actie"}
            {type === types.TOOL && "Tool"}
            {type === types.ASSIGNMENT && "Opdracht"}
            {type === types.TASK && "Taak"}
        </span>
    );
};

export default Tag;
