// Components
import Image from "../images/image";

const Organization = ({ item }) => {
    return (
        <div className="flex flex-align">
            <Image
                url={item.thumbnail.downloadURL}
                width="100%"
                className="flex flex-center img--sm b-r b p-box-s"
            />
            <div className="ml fw-b fs-s">{item.name}</div>
        </div>
    );
};

export default Organization;
