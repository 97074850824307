import { useState, useEffect, Fragment } from "react";

// Core
import { db } from "../../../../core/firebase";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import { unwrapList } from "../../../../core/utils";

// Store
import { applicationStore } from "../../../../core/application/application/store";
import { editSupervisorStore } from "../../../../core/application/dashboard/form-edit-supervisor";

// Components
import Loader from "../../../../components/utils/loader";
import PageEmpty from "../../../loading";
import Column from "../../../../components/columns/columns/application/supervisors";
import Row from "../../../../components/columns/rows/application/supervisors";
import EditSupervisor from "../../../../components/forms/application/supervisor-edit";

const Supervisors = () => {
    const { id } = applicationStore().application;
    const { openForm } = editSupervisorStore();

    const [list, setList] = useState(null);

    useEffect(() => {
        const q = query(
            collection(db, "applications", id, "projects"),
            orderBy("created_at", "desc")
        );

        const unsubscribe = onSnapshot(q, (payload) =>
            setList(unwrapList(payload))
        );

        return () => unsubscribe();
    }, [id]);

    if (!list) {
        return <Loader pageWithNav />;
    }

    if (!list.length) {
        return (
            <PageEmpty
                pageWithSubNav
                text="Er staan helaas nog geen projecten"
            />
        );
    }

    return (
        <Fragment>
            <div className="p-wrap">
                <Column />
                {list.map((item) => (
                    <Row key={item.id} item={item} openForm={openForm} />
                ))}
            </div>
            <EditSupervisor />
        </Fragment>
    );
};

export default Supervisors;
