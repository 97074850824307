export const routesApplication = {
    ROOT: "",
    DASHBOARD: "dashboard",
    LIBRARY: "bibliotheek",
    EXPERT: "expert",
    PROJECT: "project/:projectId",
    TOOLS: "tools",
    WORK: "work",
    MESSAGES: "berichten",
    MODAL_PLANNING: "planning/:projectId/:taskId",
    MODAL_ASSIGNMENT: "opdracht/:projectId/:taskId",
    MODAL_QUESTION: "vraag/:projectId/:questionId",
    UNAUTHORIZED: "geen-toestemming",
    PLATFORM: "/platform",
    APPLICATION: "/app/:applicationId/*",
};

export const filters = {
    ROOT: "",
    INVITATIONS: "uitnodigingen",
    USERS: "gebruikers",
    SUPERVISORS: "begeleiders",
};
