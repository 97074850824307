import { useEffect, useState } from "react";

// Core
import { db } from "core/firebase";
import { unwrapList } from "core/utils";
import {
    query,
    collection,
    onSnapshot,
    orderBy,
    where,
} from "firebase/firestore";
import { authStore } from "core/authentication/store";
import { editPlanningStore } from "core/application/project/form-message/store";

import {
    updateMessage,
    removeMessage,
} from "core/application/project/form-message/actions";

// Components
import Message from "../../../project-planning-edit/messages/messages/message";

const Messages = ({ addUser }) => {
    const { uid } = authStore().user;
    const { id, applicationId, projectId } = editPlanningStore().item;

    const [list, setList] = useState(null);

    useEffect(() => {
        const q = query(
            collection(
                db,
                "applications",
                applicationId,
                "projects",
                projectId,
                "planning",
                id,
                "messages"
            ),
            where("visibility", "==", true),
            orderBy("created_at", "desc")
        );

        const unsubscribe = onSnapshot(q, (payload) =>
            setList(unwrapList(payload))
        );

        return () => unsubscribe();
    }, [id, applicationId, projectId]);

    if (!list) {
        return null;
    }

    return list.map((item) => (
        <Message
            key={item.id}
            item={item}
            addUser={addUser}
            uid={uid}
            updateMessage={updateMessage}
            removeMessage={removeMessage}
        />
    ));
};

export default Messages;
