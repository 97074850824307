// Libraries
import { NavLink } from "react-router-dom";

// Variables
import { routes } from "../config";

// Logos
import { Logo } from "../../../components/icons";

const Header = () => {
    return (
        <nav className="auth_header">
            <NavLink to={routes.ROOT}>
                <Logo className="logo-m" />
            </NavLink>
        </nav>
    );
};

export default Header;
