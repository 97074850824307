// Libraries
import { Droppable } from "react-beautiful-dnd";

// Components
import Header from "components/planning/utils/header-project";
import Card from "components/planning/card/dashboard";
import Menu from "components/menu/project-planning";
import Disabled from "components/planning/utils/disabled";

const Column = ({
    list,
    title,
    id,
    disabledText,
    stages,
    openForm,
    index,
    isInTeam = false,
    isSupervisor = false,
}) => {
    const disabled = !stages[id];

    return (
        <Droppable droppableId={id} isDropDisabled={disabled}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={`col__12 col_m__3 dnd-list
                    ${disabled && "dnd-list-disabled"}
                ${snapshot.isDraggingOver && ""}`}
                >
                    <Header title={title} listId={id} disabled={disabled} />

                    {!!disabled ? (
                        <Disabled
                            stages={stages}
                            disabledText={disabledText}
                            index={index}
                            id={id}
                            title={title}
                        />
                    ) : (
                        list.map((item, index) => (
                            <Card
                                key={item.id}
                                item={item}
                                index={index}
                                openForm={openForm}
                            />
                        ))
                    )}
                    {provided.placeholder}
                    {!disabled && (isInTeam || isSupervisor) && (
                        <Menu
                            id={id}
                            isInTeam={isInTeam}
                            isSupervisor={isSupervisor}
                        />
                    )}
                </div>
            )}
        </Droppable>
    );
};

export default Column;
