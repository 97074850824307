import { list } from "./config";

// Components
import Grid from "../../../components/utils/grid";
import Card from "../../../components/cards/tool";

const Tools = () => {
    return (
        <Grid>
            {list.map((item) => (
                <Card key={item.tool} item={item} />
            ))}
        </Grid>
    );
};

export default Tools;
