import { Fragment } from "react";

// Components
import Profile from "./components/account";
import Messages from "./components/messages";

const Account = () => {
    return (
        <Fragment>
            <Profile />
            <Messages />
        </Fragment>
    );
};

export default Account;
