import { useEffect, useState, Fragment } from "react";

import { planningStore } from "core/application/project/planning/store";
import { projectStore } from "core/application/project/project/store";
import { editPlanningStore } from "core/application/project/form-edit-planning/store";
import { authStore } from "core/authentication/store";

import {
    setPlanningListener,
    removePlanningListener,
    updateBoard,
} from "core/application/project/planning/actions";

// Libraries
import { DragDropContext } from "react-beautiful-dnd";

// Variables
import { stages } from "variables/stages";

// Components
import Loader from "components/utils/loader";
import Column from "components/planning/column/project";
import EditStage from "components/forms/application/stage-edit";
import InfoStage from "components/forms/application/stage-info";
import { setPlanning } from "components/planning/utils/helpers";
import { setUserRole } from "../utils/index";

const Planning = () => {
    const { openForm } = editPlanningStore();

    const { list } = planningStore();
    const { project } = projectStore();
    const { uid } = authStore().user;

    const { isSupervisor, isInTeam } = setUserRole(project, uid);

    const [planning, setStages] = useState(setPlanning([]));

    useEffect(() => {
        setPlanningListener();
        return () => removePlanningListener();
    }, []);

    useEffect(() => {
        if (list) {
            setStages(setPlanning(list));
        }
    }, [list]);

    const onDragEnd = ({ destination, source }) => {
        if (!destination) {
            return;
        }
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        // Check if the column has changed
        if (destination.droppableId !== source.droppableId) {
            let sourceColumn = planning[source.droppableId];
            let destinationColumn = planning[destination.droppableId];

            const [item] = sourceColumn.list.splice(source.index, 1);
            destinationColumn.list.splice(destination.index, 0, item);

            const newPlanning = {
                ...planning,
                [sourceColumn.id]: sourceColumn,
                [destinationColumn.id]: destinationColumn,
            };

            setStages(newPlanning);
            updateBoard(sourceColumn, destinationColumn);

            return;
        }

        let sourceColumn = planning[destination.droppableId];
        const [item] = sourceColumn.list.splice(source.index, 1);
        sourceColumn.list.splice(destination.index, 0, item);

        const newPlanning = {
            ...planning,
            [sourceColumn.id]: sourceColumn,
        };

        setStages(newPlanning);
        updateBoard(sourceColumn);
    };

    if (!planning) {
        return <Loader pageWithSubNav />;
    }

    return (
        <Fragment>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className="container row flex-space pb-m pt-m">
                    {stages.map((item, index) => (
                        <Column
                            stages={
                                project.stages || {
                                    ONTMOETINGSFASE: true,
                                }
                            }
                            key={item.id}
                            {...item}
                            list={planning[item.id].list}
                            index={index}
                            openForm={openForm}
                            isSupervisor={isSupervisor}
                            isInTeam={isInTeam}
                        />
                    ))}
                </div>
            </DragDropContext>
            <EditStage />
            <InfoStage />
        </Fragment>
    );
};

export default Planning;
