// Core
import { createTeamStore } from "core/application/project/form-create-team/store";

// Components
import Modal from "components/forms/modal";
import Container from "./container";
const CreateTeam = () => {
    const { active, closeForm } = createTeamStore();

    return (
        <Modal active={active} toggle={closeForm}>
            <Container closeForm={closeForm} />
        </Modal>
    );
};

export default CreateTeam;
