//  Libraries
import { useTransition, animated } from "react-spring";

//  Variables
import { modalAnimation } from "../../variables/animations";

//  Components
import EventHandler from "./event-handler";
import Navigation from "./navigation";

const Modal = ({
    active,
    children,
    toggle,
    center = false,
    className = "",
}) => {
    const transitions = useTransition(active, modalAnimation);

    return transitions(
        (styles, item) =>
            item && (
                <animated.div className="modal" style={styles}>
                    <Navigation toggle={toggle} />
                    <div
                        className={`${
                            center ? "modal-center" : "modal-scroll"
                        } ${className}`}
                    >
                        {children}
                    </div>
                    <EventHandler toggle={toggle} />
                </animated.div>
            )
    );
};

export default Modal;
