import { doc, updateDoc } from "firebase/firestore";
import { applicationStore } from "core/application/application/store";
import { projectStore } from "./store";
import { db } from "core/firebase";
import { ProjectListener } from "./utils";

const projectListener = new ProjectListener();

export const setProjectListener = (id) => {
    const { application } = applicationStore.getState();
    projectListener.on(doc(db, "applications", application.id, "projects", id));
};

export const removeProjectListener = () => {
    projectListener.off();
};

export const updateProjectDescription = (payload) => {
    const { application } = applicationStore.getState();
    const { project } = projectStore.getState();

    const ref = doc(db, "applications", application.id, "projects", project.id);

    updateDoc(ref, {
        description: payload,
    });
};

export const updateProjectName = (payload) => {
    const { application } = applicationStore.getState();
    const { project } = projectStore.getState();

    const ref = doc(db, "applications", application.id, "projects", project.id);

    updateDoc(ref, {
        name: payload,
    });
};
