import React, { useEffect } from "react";

// Libraries
import { NavLink } from "react-router-dom";

// Variables
import { routes, titles } from "../config";

// Components
import Image from "../../../components/images/illustration";
import { setTitle } from "../../../components/utils/title";
import { IconLogin, IconPlus } from "../../../components/icons";

const Select = () => {
    useEffect(() => {
        setTitle(titles.AUTH_WELCOME);
    }, []);

    return (
        <section className="flex auth_layout pb-xl">
            <div
                className="flex flex-center full"
                style={{ minHeight: "100%" }}
            >
                <div className="container pb-xl">
                    <Image slug="welcome" className="full lap__off" />
                    <h1 className="center mt-m">Welkom op YouLab</h1>
                    <div className=" pt-m">
                        <NavLink to={routes.SIGN_IN} className="button-select">
                            <IconLogin className="icon icon-s" />
                            <div className="ml">
                                <div className="fs-s">Inloggen</div>
                                <div className="fs-xs fw-n">
                                    Ik heb al een youlab account
                                </div>
                            </div>
                        </NavLink>
                        <NavLink
                            to={routes.REGISTER}
                            className="button-select button-border mt"
                        >
                            <IconPlus className="icon icon-s" />
                            <div className="ml">
                                <div className="fs-s">Account aanmaken</div>
                                <div className="fs-xs fw-n">
                                    Ik heb nog geen Youlab account
                                </div>
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Select;
