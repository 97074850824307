import { doc } from "firebase/firestore";

import { db } from "../../firebase";
import { ApplicationListener } from "./utils";

const applicationListener = new ApplicationListener();

export const setApplicationListener = (id) => {
    applicationListener.on(doc(db, "applications", id));
};

export const removeApplicationListener = () => {
    applicationListener.off();
};
