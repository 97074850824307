// Components
import Admins from "../../create-application/admins";
import Header from "../../../header";

const Container = ({ admin, setAdmin }) => {
    return (
        <div className="modal-container">
            <Header text="Selecteer een applicatie begeleider" />
            <Admins admin={admin} setAdmin={setAdmin} />
        </div>
    );
};

export default Container;
