//  Libraries
import { NavLink } from "react-router-dom";

// Core
import { authStore } from "core/authentication/store";
import { rolesStore } from "core/roles/store";

// Variables
import { routesApplication } from "variables/routes.application";

// Components
import { LogoSmall } from "components/icons";
import ThumbNail from "components/images/thumbnail";

const Links = ({
    applicationRoles = {},
    platformRoles = {},
    className = "",
}) => {
    return (
        <div className={className}>
            <NavLink
                className={({ isActive }) =>
                    `link ${isActive && "link-active"}`
                }
                to={routesApplication.ROOT}
                end
            >
                Alle opgaves
            </NavLink>
            <NavLink
                className={({ isActive }) =>
                    `link ${isActive && "link-active"}`
                }
                to={`${routesApplication.LIBRARY}`}
            >
                <span>Bibliotheek</span>
            </NavLink>
            <NavLink
                className={({ isActive }) =>
                    `link ${isActive && "link-active"}`
                }
                to={`${routesApplication.TOOLS}`}
            >
                <span>Toolbox</span>
            </NavLink>
            {(applicationRoles?.APPLICATION_ADMIN || platformRoles?.ADMIN) && (
                <NavLink
                    className={({ isActive }) =>
                        `link ${isActive && "link-active"}`
                    }
                    to={routesApplication.DASHBOARD}
                >
                    Beheer
                </NavLink>
            )}
        </div>
    );
};
const Navigation = () => {
    const { user } = authStore();
    const { platformRoles, applicationRoles } = rolesStore();

    return (
        <nav className="navigation border-bottom">
            <div className="nav-main container flex flex-align flex-space">
                <NavLink to={routesApplication.PLATFORM}>
                    <LogoSmall className="logo-mobile mr" />
                </NavLink>

                <div className="flex flex-align h-full">
                    <Links
                        className="h-full flex flex-align flex-space container nav-wrap"
                        applicationRoles={applicationRoles}
                        platformRoles={platformRoles}
                    />
                    <NavLink
                        className="ml relative"
                        to={routesApplication.MESSAGES}
                    >
                        <ThumbNail user={user} />
                    </NavLink>
                </div>
            </div>
            <div className="nav-sub lap__off">
                <Links
                    className="flex flex-align flex-space container nav-wrap"
                    applicationRoles={applicationRoles}
                    platformRoles={platformRoles}
                />
            </div>
        </nav>
    );
};

export default Navigation;
