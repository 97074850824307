import { useEffect, useState, Fragment } from "react";

// Core
import { db } from "../../../../core/firebase";
import { onSnapshot, orderBy, collection, query } from "firebase/firestore";
import { unwrapList } from "../../../../core/utils";
import { editApplicationStore } from "../../../../core/platform/form-edit-application/store";

// Components
import Loader from "../../../../components/utils/loader";
import PageEmpty from "../../../loading";
import Columns from "../../../../components/columns/columns/platform/applications";
import Row from "../../../../components/columns/rows/platform/applications";
import EditApplication from "../../../../components/forms/platform/edit-application";

const Applications = () => {
    const { openForm } = editApplicationStore();

    const [list, setList] = useState(null);

    useEffect(() => {
        const q = query(
            collection(db, "applications"),
            orderBy("created_at", "desc")
        );

        const unsubscribe = onSnapshot(q, (payload) =>
            setList(unwrapList(payload))
        );

        return () => unsubscribe();
    }, []);

    if (!list) {
        return <Loader pageWithSubNav />;
    }

    if (!list.length) {
        return (
            <PageEmpty
                pageWithSubNav
                text="Er zijn helaas nog geen applicaties zichtbaar."
            />
        );
    }

    return (
        <Fragment>
            <div className="p-wrap">
                <Columns />
                {list.map((item) => (
                    <Row key={item.id} item={item} openForm={openForm} />
                ))}
            </div>
            <EditApplication />
        </Fragment>
    );
};

export default Applications;
