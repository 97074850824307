//  Components
import { dateFull } from "../../../utils/dates";
import Image from "../../../images/image";

const Organization = ({ item, organization, setOrganization }) => {
    const selected = organization && organization.id === item.id;

    return (
        <button
            className={`flex a-l flex-align p-box-s full b-r b mb ${
                selected && "bg-cloud"
            }`}
            onClick={() => setOrganization(item)}
        >
            <Image
                url={item.thumbnail.downloadURL}
                width="100%"
                className="flex flex-center img--sm b-r b p-box-s"
            />
            <div className="ml">
                <div className="fs-s fw-b">
                    {item.name}
                    {selected && (
                        <span className="c-main"> - Geselecteerd</span>
                    )}
                </div>
                <div className="fw-b fs-xs c-sec">
                    {dateFull(item.created_at)}
                </div>
            </div>
        </button>
    );
};

export default Organization;
