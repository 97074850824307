const Item = ({ children, text, onClick, className = "" }) => {
    return (
        <button
            className={`menu-item flex flex-align a-l bb ${className}`}
            onClick={onClick}
        >
            {children}
            <span className="fs-s">{text}</span>
        </button>
    );
};

export default Item;
