import create from "zustand";
import { v4 as uuidv4 } from "uuid";

export const notificationStore = create((set) => ({
    list: [],
    addNotification: (payload) => {
        set(({ list }) => {
            return {
                list: [...list, { id: uuidv4(), ...payload }],
            };
        });
    },
    removeNotification: (payload) => {
        set(({ list }) => {
            return {
                list: list.filter(({ id }) => id !== payload.id),
            };
        });
    },
}));
