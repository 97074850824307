import { useState } from "react";

// - Components
import Button from "components/button";
import Thumbnail from "components/images/thumbnail";
import Menu from "components/menu/utils/menu";
import Item from "components/menu/utils/item";
import TextArea from "components/inputs/textarea";
import {
    IconSettings,
    IconEdit,
    IconTrash,
    IconMessage,
} from "components/icons";
import { dateFull } from "components/utils/dates";

const Message = ({ item, removeMessage, updateMessage, uid, addUser }) => {
    const [activeMenu, setMenu] = useState(false);
    const [activeEdit, setEdit] = useState(false);
    const [message, setMessage] = useState(item.message || "");

    const submitMessage = (e) => {
        e.preventDefault();

        updateMessage({
            messageId: item.id,
            message: message,
        });

        setEdit(false);
    };

    return (
        <div className="mt">
            <div className="flex flex-align flex-space fs-s pb-s">
                <div className="col__8 text-wrap">
                    <Thumbnail user={item.created_by} />
                    <b className="ml-s mr-s">{item.created_by.displayName}</b>
                    op:
                    <b className="ml-s">{dateFull(item.created_at)}</b>
                </div>
                <button
                    className="tag button-border ml-s fs-xs radius"
                    onClick={() => addUser(item.created_by)}
                >
                    <span>Reply</span>
                    <IconMessage className="icon icon-xs ml-s" />
                </button>
            </div>
            <div className="card-info p-box radius border relative">
                {activeEdit ? (
                    <form
                        className="p-box-s b-r bg-edit"
                        onSubmit={submitMessage}
                    >
                        <TextArea
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            autoFocus
                        />
                        <div className="mt">
                            <Button
                                className="button-border mr"
                                text="Annuleren"
                                onClick={() => setEdit(false)}
                            />

                            <Button text="Versturen" type="submit" />
                        </div>
                    </form>
                ) : (
                    <p className="p pr mr">
                        {item.users &&
                            item.users.map((item) => (
                                <span
                                    key={item.uid}
                                    className="bgc-main radius pl-s pr-s fw-b fs-xs mr-s"
                                >
                                    @{item.displayName}
                                </span>
                            ))}
                        {item.message}
                    </p>
                )}
                {uid === item.created_by.uid && (
                    <div className="p-t-r">
                        <button
                            className="p-box c-sec"
                            onClick={() => setMenu(true)}
                        >
                            <IconSettings className="icon icon-s" />
                        </button>
                        <Menu
                            className="p-t-r border"
                            active={activeMenu}
                            closeMenu={() => setMenu(false)}
                        >
                            <Item
                                text="Aanpassen"
                                onClick={() => {
                                    setMenu(false);
                                    setEdit(true);
                                }}
                            >
                                <IconEdit className="icon icon-s mr-s" />
                            </Item>
                            <Item
                                text="Verwijderen"
                                onClick={() => removeMessage(item)}
                            >
                                <IconTrash className="icon icon-s mr-s" />
                            </Item>
                        </Menu>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Message;
