export const text = {
    ONTMOETINGSFASE: {
        name: "Ontmoetingsfase",
        description:
            "Welkom bij de ontmoetingsfase. De eerste fase van YouLab waarin je gaat verkennen wat jouw opgave inhoudt en wie erbij betrokken zijn. In YouLab staat de opgave en de eindgebruiker centraal. Een opgave is een stug probleem of uitdaging die jij ziet in je omgeving en waarvan je vindt dat er beweging in moet komen. De eindgebruiker is een persoon, organisatie of groep die het probleem ervaart of baat heeft bij de uitdaging die achter de opgave zit. Er kunnen meerder soorten eindgebruikers zijn die relevant zijn voor je opgaven. Daarom ga je in de ontmoetingsfase de mensen, organisaties en systemen in kaart brengen die te maken hebben met je opgave. ",
    },
    ONTDEKKINGSFASE: {
        name: "Ontdekkingsfase",
        description:
            "Welkom bij de ontdekkingsfase. In deze fase ga je ontdekken en inzoomen op wat er allemaal speelt binnen de omgeving en context van je opgave. Deze speurtocht naar relevante informatie gebeurt via interviews, enquêtes, creatieve cross-overs, artikelen, boeken, webinars, etc. Door verbanden te leggen tussen de informatie ontstaat er kennis over en inzicht in het speelveld van de opgave. Het speelveld van spelers, regels, afhankelijkheden, doelen en wat er op het spel staat. Dit speelveld noemen we ook wel het systeem. Met een goed begrip van het systeem waarbinnen je opgave zich afspeelt kan er worden gekozen welke oplossingsrichting interessant is voor de ontwikkelfase.",
    },
    ONTWIKKELFASE: {
        name: "Ontwikkelfase",
        description:
            "Welkom bij de ontwikkelfase. In deze fase ga je bezig met het ontwikkelen van een oplossing voor jouw opgave. Oftewel jouw hack in het systeem. Opgaven veranderen niet met dezelfde manier van denken en doen als wat ze heeft veroorzaakt. Daarom draait deze fase om creativiteit en experiment. Je gaat vanuit de behoeften van de eindgebruikers ideeën bedenken en een prototype ontwerpen. Door middel van een prototype werk je naar een eerste versie van je idee toe en kun je testen wat er wel en niet werkt. Deze lessen neem je mee naar de organisatiefase waar je van je idee en concept maakt.",
    },
    ORGANISATIEFASE: {
        name: "Organisatiefase",
        description:
            "Welkom bij de organisatiefase. In deze fase ga je bezig met het conceptualiseren van je prototype. Een idee is goed, een prototype beter, maar een uitvoerbaar plan met daadkracht vanuit de betrokkenen is het allerbeste. En daar werk je in deze fase van YouLab naartoe. Voor het uitvoeren van ideeën heb je praktisch altijd andere mensen en middelen nodig. Daarom moet er een goed plan komen om dit te organiseren en de mensen die erin moeten investeren of zich moeten inspannen te overtuigen van de waarde. Dit alles komt samen in een concept: een concept met een sterke waardepropositie, een uitvoerbaar plan en een verhaal dat mensen mobiliseert.",
    },
};
