import create from "zustand";

export const roles = {
    ADMIN: false,
    APPLICATION_ADMIN: false,
    SUPERVISOR: false,
    EXPERT: false,
    EXPERT_TEAM: false,
    STUDENT: false,
    STUDENT_TEAM: false,
};

export const initialState = {
    platformRoles: null,
    platformRolesNotFound: false,
    applicationRoles: null,
    applicationRolesNotFound: null,
};

export const rolesStore = create((/*set*/) => ({
    ...initialState,
}));
