// Library
import { Link } from "react-router-dom";

// Components
import { dateFull } from "../../utils/dates";
import User from "../../utils/user";

const Card = ({ item }) => {
    return (
        <div className="card col__12 col_m__4 col_l__3 mb-m">
            <Link to={`/app/${item.id}`} className="card-padding flex-push">
                <div>
                    <h2 className="fw-b">{item.name}</h2>
                    <div className="c-sec fs-xs text-wrap mt-s">
                        <span className="fw-n">
                            Organisatie: <b>{item.organization.name} </b>
                        </span>
                        <span className="fw-n">
                            - Gestart op: <b>{dateFull(item.created_at)}</b>
                        </span>
                    </div>
                </div>

                <div className="full">
                    <User
                        user={item.supervised_by}
                        text="Beheerder"
                        className="mt mb"
                    />

                    <div className="row flex-space center c-sec">
                        <div className="">
                            <div className="fs-xxs">Gebruikers</div>
                            <h2>{item.userSize}</h2>
                        </div>
                        <div className="">
                            <div className="fs-xxs">Projecten</div>
                            <h2>{item.projectSize}</h2>
                        </div>
                        <div className="">
                            <div className="fs-xxs">Beheerders</div>
                            <h2>1</h2>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default Card;
