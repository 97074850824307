import { editStageStore } from "core/application/project/form-edit-stage/store";

// Components
import { list, types } from "variables/stages";
import { IconLock, IconUnlock } from "components/icons";
import Button from "components/button";

const Disabled = ({ disabledText = "", title, index, stages, id }) => {
    const { openForm } = editStageStore();
    const nextIndex = list[index - 1];
    const unLockable = stages[nextIndex?.id || types.ONTDEKKINGSFASE];

    return (
        <div className="flex  justify-center h-full">
            <div className="center pt-m pb-m">
                {unLockable ? (
                    <IconUnlock className="icon icon-m color-gray" />
                ) : (
                    <IconLock className="icon icon-m color-gray" />
                )}
                {unLockable ? (
                    <div className="mt-m">
                        <Button
                            text={`${title} unlocken`}
                            onClick={() => openForm(id)}
                        >
                            <IconUnlock className="icon icon-xs ml-s" />
                        </Button>
                    </div>
                ) : (
                    <p className="p mt-m color-gray pl pr">{disabledText}</p>
                )}
            </div>
        </div>
    );
};

export default Disabled;
