import { config } from "react-spring";

export const alarmAnimation = {
    from: { position: "absolute", opacity: 0, transform: "scale(0)" },
    enter: { opacity: 1, transform: "scale(1)" },
    leave: { opacity: 0, transform: "scale(0)" },
};

export const errorAnimation = {
    from: { height: "0px", opacity: 0 },
    enter: { height: "40px", opacity: 1 },
    leave: { height: "0px", opacity: 0 },
};

export const modalAnimation = {
    from: {
        opacity: 0,
        transform: "translate3d(0, 100%, 0)",
    },
    enter: {
        opacity: 1,
        transform: "translate3d(0, 0%, 0)",
    },
    leave: {
        opacity: 0,
        transform: "translate3d(0, 100%, 0)",
    },
    config: config.default,
};

export const slideAnimation = {
    from: {
        opacity: 0,
        transform: "translate3d(100%, 0, 0)",
    },
    enter: {
        opacity: 1,
        transform: "translate3d(0%, 0, 0)",
    },
    leave: {
        opacity: 0,
        transform: "translate3d(-50%, 0, 0)",
    },
    // initial: false,
};

export const animationNotifications = {
    from: { opacity: 0, transform: "translate3d(0, 60%, 0)", height: "0rem" },
    enter: {
        opacity: 1,
        transform: "translate3d(0, 0%, 0)",
        height: "2.88rem",
    },
    config: config.stiff,
};

export const animationMenu = {
    from: { opacity: 0, transform: "translate3d(0,75px,0)" },
    enter: { opacity: 1, transform: "translate3d(0,0px,0)" },
    leave: { opacity: 0, transform: "translate3d(0,75px,0)" },
    config: config.stiff,
};

export const animationTooltip = {
    from: {
        transform: "scale(0.8)  translate3d(0,30%,0)",
        opacity: 0,
    },
    enter: {
        transform: "scale(1)  translate3d(0,0%,0)",
        opacity: 1,
    },
    leave: {
        transform: "scale(0.8) translate3d(0,30%,0)",
        opacity: 0,
    },
    config: config.stiff,
};
