import { useState } from "react";

// Core
import { notificationStore } from "core/notifications/store";
import { createTeamMembers } from "core/application/project/form-create-team/actions";

// Variables
import { messages } from "variables/messages";

// Components
import InvitationForm from "./form-invitation";
import Invitation from "./invitation";
import Button from "components/button";
import InfoMessage from "./info";

const Invitations = ({ closeForm }) => {
	const { addNotification } = notificationStore();

	const [invitations, setInvitations] = useState([]);

	const [loading, setLoading] = useState(false);

	const createInvitation = (payload) => {
		setInvitations([payload, ...invitations]);
	};

	const removeInvitation = (item) => {
		setInvitations(invitations.filter(({ id }) => id !== item.id));
	};

	const submitTeamMembers = async () => {
		setLoading(true);

		const error = await createTeamMembers([...invitations]);

		setLoading(false);

		if (error) {
			addNotification({ message: messages.CREATE.ERROR, error });
			return;
		}

		addNotification({ message: messages.CREATE.SUCCESS });
		closeForm();
	};

	return (
		<>
			<InvitationForm createInvitation={createInvitation} />

			<div className="fs-s fw-b mb">
				De lijst met personen die je gaat uitnodigen
			</div>
			{!!(invitations && invitations.length) ? (
				invitations.map((item) => (
					<Invitation
						key={item.id}
						item={item}
						removeInvitation={removeInvitation}
					/>
				))
			) : (
				<InfoMessage className="mb">
					<p className="fs-xs color-main">
						Er zijn nog geen uitnodigingen toegevoegd. Voeg een
						uitnodiging toe via het bovenstaande formulier.
					</p>
				</InfoMessage>
			)}
			<div className="flex mt-m">
				<Button
					className="button-border flex-1"
					text="annuleren"
					onClick={closeForm}
				/>
				<Button
					className={`flex-1 ml ${
						!invitations.length && "button-disabled"
					}`}
					text="Uitnodigingen versturen"
					loading={loading}
					disabled={!invitations.length}
					onClick={submitTeamMembers}
				/>
			</div>
		</>
	);
};

export default Invitations;
