import { useEffect, useState, Fragment } from "react";

// Core
import { planningStore } from "core/application/dashboard/planning/store";

import {
    setPlanningListener,
    removePlanningListener,
    updateBoard,
} from "core/application/dashboard/planning/actions";

import { editPlanningStore } from "core/application/dashboard/form-edit-planning";

// Libraries
import { DragDropContext } from "react-beautiful-dnd";

// Variables
import { stages } from "variables/stages";

// Components
import Loader from "components/utils/loader";
import Column from "components/planning/column/dashboard";
import CreatePlanning from "components/forms/application/dashboard-planning-create";
import EditPlanning from "components/forms/application/dashboard-planning-edit";
import ArchivePlanning from "components/forms/application/dashboard-planning-archive";

import { setPlanning } from "components/planning/utils/helpers";

const Planning = () => {
    const { openForm } = editPlanningStore();
    const { list } = planningStore();

    const [planning, setStages] = useState(setPlanning([]));

    useEffect(() => {
        setPlanningListener();
        return () => removePlanningListener();
    }, []);

    useEffect(() => {
        if (list) {
            setStages(setPlanning(list));
        }
    }, [list]);

    const onDragEnd = ({ destination, source }) => {
        if (!destination) {
            return;
        }
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        // Check if the column has changed
        if (destination.droppableId !== source.droppableId) {
            let sourceColumn = planning[source.droppableId];
            let destinationColumn = planning[destination.droppableId];

            const [item] = sourceColumn.list.splice(source.index, 1);
            destinationColumn.list.splice(destination.index, 0, item);

            const newPlanning = {
                ...planning,
                [sourceColumn.id]: sourceColumn,
                [destinationColumn.id]: destinationColumn,
            };

            setStages(newPlanning);
            updateBoard(sourceColumn, destinationColumn);

            return;
        }

        let sourceColumn = planning[destination.droppableId];
        const [item] = sourceColumn.list.splice(source.index, 1);
        sourceColumn.list.splice(destination.index, 0, item);

        const newPlanning = {
            ...planning,
            [sourceColumn.id]: sourceColumn,
        };

        setStages(newPlanning);
        updateBoard(sourceColumn);
    };

    if (!planning) {
        return <Loader pageWithSubNav />;
    }

    return (
        <Fragment>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className="container row flex-space pb-m pt-m">
                    {stages.map((item) => (
                        <Column
                            key={item.id}
                            {...item}
                            list={planning[item.id].list}
                            openForm={openForm}
                        />
                    ))}
                </div>
            </DragDropContext>
            <ArchivePlanning />
            <CreatePlanning />
            <EditPlanning />
        </Fragment>
    );
};

export default Planning;
