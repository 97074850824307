import create from "zustand";

import { authStore } from "../../../authentication/store";
import { db } from "../../../firebase";
import { setDoc, doc } from "firebase/firestore";
import { applicationStore } from "../../../application/application/store";
import { types } from "variables/stages";

export const createProjectStore = create((set) => ({
    active: false,
    openForm: () => set({ active: true }),
    closeForm: () => set({ active: false }),
}));

export const createProject = async (payload) => {
    const { user } = authStore.getState();

    const { application } = applicationStore.getState();

    const error = await setDoc(
        doc(db, "applications", application.id, "projects", user.uid),
        {
            ...payload,
            applicationId: application.id,
            created_at: new Date(),
            created_by: user,
            visibility: true,
            credits: 0,
            stages: {
                [types.ONTMOETINGSFASE]: true,
            },
            users: {
                [user.uid]: true,
            },
        }
    ).catch((error) => ({ error }));

    return error;
};
