import { IconDown } from "components/icons";

const Select = ({
    className = "",
    list = [],
    onChange = () => {},
    selected = null,
}) => {
    return (
        <div className='relative'>
            <select
            className={`input ${className}`}
            onChange={({ target }) => onChange(target.value)}
            value={selected}
        >
            {list.map(({ name, value }) => (
                <option value={value} key={value}>
                    {name}
                </option>
            ))}
            
             
        </select>
        <span className="absolute right" style={{top: '8px', right: '10px'}}>
                <IconDown className="icon icon-s color-main" />
            </span>
        </div>
        
    );
};

export default Select;
