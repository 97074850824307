// Core
import { editPlanningStore } from "core/application/project/form-edit-planning/store";

// Components
import Modal from "components/forms/modal";
import TaskLoader from "./task-loader";

const EditPlanning = () => {
    const { active, closeForm } = editPlanningStore();

    return (
        <Modal active={active} toggle={closeForm}>
            <TaskLoader />
        </Modal>
    );
};

export default EditPlanning;
