import { useState } from "react";

// Components
import Navigation from "./navigation";
import Files from "./files";
import Uploader from "./uploader";

const FilesContainer = ({
	setFile = () => {},
	file = null,
	setFiles = () => {},
	files = [],
	isSingle = true,
}) => {
	const [openUpload, setOpenUpload] = useState(true);

	return (
		<>
			<Navigation openUpload={openUpload} setOpenUpload={setOpenUpload} />
			{openUpload ? (
				<Uploader
					files={files}
					file={file}
					setFiles={setFiles}
					setFile={setFile}
					isSingle={isSingle}
				/>
			) : (
				<Files
					files={files}
					file={file}
					setFiles={setFiles}
					setFile={setFile}
					isSingle={isSingle}
				/>
			)}
		</>
	);
};

export default FilesContainer;
