export const text = {
	ONTDEKKINGSFASE: {
		name: "Ontdekkingsfase",
		title: "Weet je zeker dat je de ontdekkingsfase wilt unlocken?",
		description:
			"Je staat op het punt om verder te gaan naar de ontdekkingsfase. Check nog één keer of je alle dingen hebt voltooid en unlock dan de ontdekkingsfase.",
	},
	ONTWIKKELFASE: {
		name: "Ontwikkelfase",
		title: "Weet je zeker dat je de ontwikkelfase wilt unlocken?",
		description:
			"Je staat op het punt om verder te gaan naar de ontwikkelfase. Check nog één keer of je alle dingen hebt voltooid en unlock dan de ontwikkelfase.",
	},
	ORGANISATIEFASE: {
		name: "Organisatiefase",
		title: "Weet je zeker dat je de organisatiefase wilt unlocken?",
		description:
			"Je staat op het punt om verder te gaan naar de organisatiefase. Dit is de laatste fase van je project. Check nog één keer of je alle dingen hebt voltooid en unlock dan de organisatiefase.",
	},
};
