import { useState, useRef } from "react";

const TextExpanded = ({ className = "", text = "", chars = 60 }) => {
    const [active, setActive] = useState(false);

    const textRef = useRef(
        text.length > chars ? text.substring(0, chars) + "..." : text
    );

    return (
        <div className={className}>
            <p className="p">
                {active ? text : textRef.current}

                {text.length > chars && (
                    <button
                        className="ml-s color-main"
                        onClick={() => setActive(!active)}
                    >
                        {active ? "Sluiten" : "Lees meer..."}
                    </button>
                )}
            </p>
        </div>
    );
};

export default TextExpanded;
