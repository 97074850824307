import { IconEdit } from "../../../icons";

const EditWrap = ({ onClick, children }) => {
    return (
        <button
            className="flex flex-align flex-space input-edit-name full fw-b mt-s mb-s"
            onClick={onClick}
        >
            {children}
            <IconEdit className="icon icon-s c-sec" />
        </button>
    );
};

export default EditWrap;
