import { useEffect, useState } from "react";

// Components
import Card from "./index";
import CardEmpty from "./card-empty";

const CardProject = ({ list = [], uid }) => {
    const [project, setProject] = useState(null);

    useEffect(() => {
        if (list.length) {
            if (list.some(({ created_by }) => created_by.uid === uid)) {
                setProject(
                    list.find(({ created_by }) => created_by.uid === uid)
                );
            }
        }
    }, [list, uid]);

    return (
        <div className="container">
            <h1 className="mb-m mt-m fw-n">Mijn opgave</h1>
            {project ? <Card item={project} uid={uid} /> : <CardEmpty />}
        </div>
    );
};

export default CardProject;
