// Components
import { IconRestore, IconTrash, IconPlus } from "../icons";
import { setFormate } from "../utils/helpers";
import FileType from "../utils/file-type";

const File = ({
	item,
	isSingle = true,
	file,
	files,
	removeFile,
	toggleFile,
	canRemove = false,
}) => {
	const isSelected = isSingle
		? !!(file?.id === item.id)
		: files.some(({ id }) => item.id === id);

	return (
		<div
			className={`file flex flex-space flex-align mb ${
				isSelected ? "border-main bgc-main" : ""
			}`}
		>
			<div className="flex-2 flex flex-align text-wrap pr">
				<FileType type={item.type} />
				<div>
					<a
						className="fs-s text-wrap"
						href={item.downloadURL}
						target="_black"
						rel="noreferrer noopener"
					>
						{item.name}
					</a>

					<div className="fs-xs c-sec fw-b up">
						{isSelected ? (
							<span className="color-main">
								Toegevoegd aan de lijst
							</span>
						) : (
							setFormate(item.size)
						)}
					</div>
				</div>
			</div>

			<div className="flex flex-align color-main pl">
				<button
					onClick={() => toggleFile(item)}
					className="flex flex-center"
				>
					{isSelected ? (
						<span className="flex flex-align">
							<IconRestore className="icon icon-xs mr-s" />
							<span className="fs-xs">Uit de lijst halen</span>
						</span>
					) : (
						<span className="flex flex-align">
							<IconPlus className="icon icon-xs mr-s" />
							<span className="fs-xs">Toevoegen</span>
						</span>
					)}
				</button>
				{!isSelected && canRemove && (
					<button
						className="flex flex-center ml"
						onClick={() => removeFile(item)}
					>
						<IconTrash className="icon icon-s" />
					</button>
				)}
			</div>
		</div>
	);
};

export default File;
