// Components
import Header from "../../../header";
import Organizations from "../../create-application/organizations";

const Container = ({ organization, setOrganization }) => {
    return (
        <div className="modal-container">
            <Header text="Selecteer een organisatie" />
            <Organizations
                organization={organization}
                setOrganization={setOrganization}
            />
        </div>
    );
};

export default Container;
