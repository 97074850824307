import { useState } from "react";

//  Components
import DatePicker from "react-datepicker";
import { dateFull } from "components/utils/dates";
import { IconAlarm, IconOpen } from "components/icons";
import Label from "components/inputs/label";
const Deadline = ({ canEdit = false, deadline = null, setDate = () => {} }) => {
    const [active, setActive] = useState(false);

    const changeDeadline = (payload) => {
        if (payload) {
            setDate(new Date(payload.setHours(23, 59, 59)));
            setActive(false);
        }
    };

    return (
        <div>
            <Label text="Deadline" className="mb-s" />
            {canEdit ? (
                <div className="flex flex-align">
                    <IconAlarm className="icon icon-xs mr-s" />
                    {active ? (
                        <DatePicker
                            name="deadline"
                            autoFocus={true}
                            selected={
                                deadline?.seconds
                                    ? new Date(deadline.seconds * 1000)
                                    : null
                            }
                            minDate={new Date()}
                            placeholderText="Selecteer datum..."
                            className="fs-s fw-b"
                            onChange={changeDeadline}
                            onBlur={() => setActive(false)}
                        />
                    ) : (
                        <button
                            className="full flex flex-align flex-space fs-s"
                            onClick={() => setActive(true)}
                        >
                            {deadline ? dateFull(deadline) : "Geen deadline"}

                            <IconOpen className="icon icon-s color-main" />
                        </button>
                    )}
                </div>
            ) : (
                <div className="flex flex-align fs-s fw-b">
                    <IconAlarm className="icon icon-xs mr-s" />
                    {deadline ? dateFull(deadline) : "Geen deadline"}
                </div>
            )}
        </div>
    );
};

export default Deadline;
