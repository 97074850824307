import { stages } from "variables/stages";

export const setPlanning = (planning) => {
    let state = {};

    stages.forEach((list) => {
        state[list.id] = {
            title: list.title,
            id: list.id,
            list: planning
                .filter((item) => item.listId === list.id)
                .sort((a, b) => a.position - b.position),
        };
    });

    /*  - the code below is a hack created to show older planning items with deprecated listId's  */

    if (planning.length) {
        const listId = planning[0].listId;

        if (
            listId === "STARTUP" ||
            listId === "CREATIE" ||
            listId === "ONTWIKKEL" ||
            listId === "REALISATIE"
        ) {
            state[stages[0].id].list = planning;
        }
    }

    return state;
};
