//  Components
import { IconError } from "../icons";

const Navigation = ({ toggle }) => {
    return (
        <div className="modal-nav container flex flex-align flex-space">
            <span />
            <button className="btn btn-gray lap__off" onClick={toggle}>
                <IconError className="icon icon-s" />
            </button>
            <button
                className="center c-w pt-m mb__off z-index"
                onClick={toggle}
            >
                <IconError className="icon icon-m" />
                <div className="fs-xs">Sluiten (Esc)</div>
            </button>
        </div>
    );
};

export default Navigation;
