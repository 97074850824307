import { Container, Label } from "../../columns";

const Columns = () => {
    return (
        <Container>
            <Label text="user" className="flex-2" />
            <Label text="email" className="flex-2" />
            <Label text="datum" className="flex-2" />
            <Label text="edit" className="flex-1" />
        </Container>
    );
};

export default Columns;
