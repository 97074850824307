import { useState } from "react";

const Image = ({
    url = "",
    height = "auto",
    width = "auto",
    className = "",
    alt = "Illustration",
}) => {
    const [loaded, toggleLoading] = useState(false);

    return (
        <img
            src={url}
            onLoad={() => toggleLoading(true)}
            className={`loading img-animated ${
                loaded && "loaded"
            } ${className}`}
            height={height}
            width={width}
            alt={alt}
        ></img>
    );
};

export default Image;
