export const text = {
    REFLECTION: {
        name: "Wat is de titel van de reflectie?",
        description: "Geef een beschrijving van waar de reflectie over gaat.",
        files: "Horen er bestanden bij deze reflectie? Voeg deze dan hieronder toe.",
        deadline: "Welke deadline wil je aan deze reflectie meegeven?",
    },
    INSTRUCTION: {
        name: "Welke instructie wil je meegeven?",
        description:
            "Geef een korte beschrijving die meer uitlegt geeft over de instructie.",
        files: "Horen er bestanden bij deze instructie? Voeg deze dan hieronder toe.",
        deadline: "Welke deadline wil je aan deze instructie meegeven?",
    },
    TOOL: {
        name: "Welke tool wil je toevoegen?",
        description: "Geef een korte beschrijving bij de tool.",
        files: "Horen er bestanden bij deze tool? Voeg deze dan hieronder toe.",
        deadline: "Welke deadline wil je aan deze tool meegeven?",
    },
    ACTION: {
        name: "Welke actie wil je gaan uitvoeren?",
        description: "Geef een korte beschrijving van wat de actie inhoud.",
        files: "Horen er bestanden bij deze actie? Voeg deze dan hieronder toe.",
        deadline: "Welke deadline wil je aan deze actie meegeven?",
    },
};
