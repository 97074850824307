export const messages = {
    UPDATE: {
        SUCCESS: "De content is succesvol aangepast",
        ERROR: "Er is een fout opgetreden bij het wijzigen van de content",
    },
    DELETE: {
        SUCCESS: "De content is succesvol verwijderd",
        ERROR: "Er is een fout opgetreden bij het verwijderen van de content",
    },
    CREATE: {
        SUCCESS: "De content is succesvol aangemaakt",
        ERROR: "Er is een fout opgetreden bij het aanmaken van de content",
    },
};
