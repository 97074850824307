const PageEmpty = ({
    className = "",
    imageSlug = "empty_projects",
    text = "",
    page = false,
    pageWithNav = false,
    pageWithSubNav = false,
}) => {
    return (
        <section
            className={`
                w-screen p-wrap flex items-center justify-center
                ${page && "h-screen"}
                ${pageWithNav && "h-screen-navigation"}
                ${pageWithSubNav && "h-screen-subNavigation"}
                `}
        >
            <div className={`box-empty-wrap center ${className}`}>
                <img
                    width="auto"
                    style={{ height: "20rem" }}
                    alt=""
                    src={`${process.env.PUBLIC_URL}/illustrations/${imageSlug}.svg`}
                />

                <h1 className="center mt">{text}</h1>
            </div>
        </section>
    );
};

export default PageEmpty;
