import { useState } from "react";

// Core
import { updateProjectName } from "core/application/project/project/actions";

// Components
import Button from "components/button";
import Input from "components/inputs/input";
import { IconEdit } from "components/icons";
import { setUserRole } from ".";

const Name = ({ project, uid }) => {
    const [active, setActive] = useState(false);
    const [text, setText] = useState(project?.name || "");

    const { isAuthor } = setUserRole(project, uid);

    const onSubmit = (e) => {
        e.preventDefault();
        updateProjectName(text);
        setActive(false);
    };

    if (active) {
        return (
            <form className="p-box b-r input-edit-form" onSubmit={onSubmit}>
                <Input
                    className="mb-s fw-n"
                    value={text}
                    onChange={({ target }) => setText(target.value)}
                    autoFocus
                />

                <div className="flex flex-reverse">
                    <Button
                        text="aanpassen (enter)"
                        className="ml"
                        onClick={onSubmit}
                    />
                    <Button
                        text="annuleren"
                        className="button-text"
                        onClick={() => setActive(false)}
                    />
                </div>
            </form>
        );
    }
    return (
        <div className="bg-white border-bottom">
            <div className="container pt-l pb-l flex flex-align flex-space">
                <h1>{project.name}</h1>
                {isAuthor && (
                    <Button onClick={() => setActive(true)}>
                        <IconEdit className="icon icon-s" />
                    </Button>
                )}
            </div>
        </div>
    );
};

export default Name;
