import { editPlanningStore } from "core/application/dashboard/form-edit-planning";

// Components
import Modal from "../../modal";
import Container from "./container";

const PlanningEdit = () => {
    const { closeForm, active } = editPlanningStore();

    return (
        <Modal active={active} toggle={closeForm}>
            <Container closeForm={closeForm} />
        </Modal>
    );
};

export default PlanningEdit;
