// Core
import { createPlanningStore } from "core/application/project/form-create-planning/store";

// Components
import Modal from "components/forms/modal";
import Container from "./container";

const CreatePlanning = () => {
    const { active, closeForm, type } = createPlanningStore();

    return (
        <Modal active={active} toggle={closeForm}>
            <Container type={type} closeForm={closeForm} />
        </Modal>
    );
};

export default CreatePlanning;
