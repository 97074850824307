//  Components
import { dateFull } from "../../../utils/dates";
import { IconSettings } from "../../../icons";
import User from "../../../utils/user";
import Organization from "../../../utils/organization";
import { Container } from "../../rows";
import Button from "components/button";

const Row = ({ item, openForm }) => {
    return (
        <Container>
            <div className="flex-2 pr text-wrap">{item.name}</div>
            <div className="flex-2">
                <User user={item.supervised_by} />
            </div>
            <div className="flex-2">
                <Organization item={item.organization} />
            </div>
            <div className="flex-2">
                <span className="mr-s fw-n">Op:</span>
                {dateFull(item.created_at)}
            </div>

            <div className="flex-1 ">
                <div
                    className={`light light-on ${
                        !item.visibility && "light-off"
                    }`}
                />
            </div>
            <div className="flex-1">
                <Button text="Aanpassen" onClick={() => openForm(item.id)}>
                    <IconSettings className="icon icon-s ml-s" />
                </Button>
            </div>
        </Container>
    );
};

export default Row;
