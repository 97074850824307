import { useState } from "react";

const Image = ({
    slug = "welcome",
    height = "auto",
    width = "auto",
    className = "",
    alt = "Illustration",
}) => {
    const [loaded, toggleLoading] = useState(false);

    return (
        <img
            src={`${process.env.PUBLIC_URL}/illustrations/${slug}.svg`}
            onLoad={() => toggleLoading(true)}
            className={`loading img-animated ${
                loaded && "loaded"
            } ${className}`}
            height={height}
            width={width}
            alt={alt}
        ></img>
    );
};

export default Image;

// import React, { Component } from "react";

// class Image extends Component {
//     _isMounted = false;
//     state = { loaded: false, error: false };

//     componentDidMount() {
//         this._isMounted = true;
//     }

//     componentWillUnmount() {
//         this._isMounted = false;
//     }

//     changeLoaded = () => {
//         this._isMounted && this.setState(() => ({ loaded: true }));
//     };

//     render() {
//         const { slug, className } = this.props;

//         return [
//             <img
//                 key="1"
//                 src={`${process.env.PUBLIC_URL}/illustrations/${slug}.svg`}
//                 onLoad={this.changeLoaded}
//                 className="hidden"
//                 alt="loader"
//             />,
//             <img
//                 key="2"
//                 src={`${process.env.PUBLIC_URL}/illustrations/${slug}.svg`}
//                 className={`full bg-loading ${className && className} ${
//                     this.state.loaded && "bg-loaded"
//                 }`}
//                 alt="loader"
//             />,
//         ];
//     }
// }

// export default Image;
