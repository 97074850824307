import React, { useState, useEffect, useRef } from "react";

import { ref, getDownloadURL } from "firebase/storage";
// - Firebase
import { storage } from "../../core/firebase";

const Thumbnail = ({
    className = "",
    user: { displayName, photoURL, uid },
}) => {
    // ref to cancel updating the state
    const isMounted = useRef(true);
    // state
    const [error, toggleError] = useState(false);
    const [loaded, toggleLoading] = useState(false);
    const [url, setUrl] = useState(null);

    // on unmount cancel update in case the component is unmounted before it finished loading
    useEffect(() => () => (isMounted.current = false), []);
    // get the photo url
    useEffect(() => {
        async function getURL() {
            const { url, error } = await getDownloadURL(
                ref(storage, `users/${uid}/profile.jpg`)
            )
                .then((payload) => ({ url: payload }))
                .then((error) => ({ error }));

            if (isMounted.current) {
                if (error) {
                    toggleError(true);
                } else {
                    setUrl(url);
                }
            }
        }
        getURL();
    }, [photoURL, uid]);

    if (!displayName) {
        return null;
    }

    if (error) {
        return (
            <div className={`img img--s ${className} b-r flex-center bgc-g`}>
                <span className="fs-xs fw-b">{displayName[0]}</span>
            </div>
        );
    }

    if (url) {
        return (
            <div className="img bgc-g b-r">
                <img
                    src={url}
                    onLoad={() => toggleLoading(true)}
                    className="hidden"
                    alt="profiel foto"
                />
                <div
                    className={`bg bg-loading img--s b-r ${
                        loaded && "bg-loaded"
                    } ${className}`}
                    style={loaded ? { backgroundImage: `url(${url})` } : null}
                />
            </div>
        );
    }

    return (
        <div className={`img img--s b-r flex-center bgc-g ${className}`}>
            <span className="fs-xs fw-b">{displayName[0]}</span>
        </div>
    );
};

export default Thumbnail;
