import { useState, useEffect, useRef, Fragment } from "react";

import { db } from "../../../../core/firebase";
import { onSnapshot, doc, updateDoc } from "firebase/firestore";
import { unwrapSnapshot } from "core/utils";

//Core
import { editPlanningStore } from "core/application/dashboard/form-edit-planning";
import { applicationStore } from "core/application/application/store";

// Components
import Button from "components/button";
import Loader from "components/utils/loader";
import InputEdit from "components/inputs/input-edit";
import Tag from "components/planning/utils/tag";
import File from "components/utils/file";
import Deadline from "components/planning/edit/deadline";
import Switch from "components/inputs/switch";
import User from "components/utils/user";
import Files from "./files";
import { IconPlus } from "components/icons";
import { dateFull } from "components/utils/dates";
import { IconEdit, IconView } from "components/icons";

const Container = () => {
	const { id } = editPlanningStore();
	const { application } = applicationStore();

	const ref = useRef(doc(db, "applications", application.id, "planning", id));

	const [item, setItem] = useState(null);
	const [active, setActive] = useState(false);

	useEffect(() => {
		const unsubscribe = onSnapshot(ref.current, (payload) =>
			setItem(unwrapSnapshot(payload))
		);

		return () => unsubscribe();
		// eslint-disable-next-line
	}, [id]);

	const setName = (payload) => {
		updateDoc(ref.current, {
			name: payload,
		});
	};

	const setDescription = (payload) => {
		updateDoc(ref.current, {
			description: payload,
		});
	};

	const setDate = (payload) => {
		updateDoc(ref.current, {
			deadline: payload,
		});
	};

	const setVisibility = (payload) => {
		updateDoc(ref.current, {
			visibility: payload,
		});
	};

	const setFiles = (payload) => {
		updateDoc(ref.current, {
			files: payload,
		});
	};

	const removeFile = (payload) => {
		updateDoc(ref.current, {
			files: item.files.filter((file) => payload.id !== file.id),
		});
	};

	if (!item) {
		return <Loader modal />;
	}

	return (
		<Fragment>
			<div className="modal-container modal-container-l">
				<div className="flex flex-align flex-space bb pb">
					<Tag type={item.type} />
				</div>
				<div className="flex pt pb">
					<div className="flex-2 pr mr">
						<InputEdit
							placeholder="Deze taak heeft nog geen naam."
							text={item.name}
							setText={setName}
							className="fw-b fs-l"
						/>
						<InputEdit
							placeholder="Nog geen omschrijving"
							textarea
							text={item.description}
							setText={setDescription}
						/>

						<div className="flex flex-align flex-space fw-b fs-s c-sec pb pt">
							bestanden
							<Button
								text="Bestand toevoegen"
								onClick={() => setActive(true)}
							>
								<IconPlus className="icon icon-s ml-s" />
							</Button>
						</div>
						{item.files &&
							item.files?.map((item) => (
								<File
									key={item.id}
									item={item}
									remove
									removeFile={removeFile}
								/>
							))}
					</div>
					<div className="flex-1">
						<Deadline
							canEdit
							deadline={item.deadline}
							setDate={setDate}
						/>
						<div className="pb bb">
							<div className="fw-b fs-s c-sec pb-s pt-s">
								Zichtbaarheid
							</div>
							<div className="flex flex-align flex-space">
								<span className="fs-s fw-b">
									<IconView className="icon icon-xs mr-s" />
									{item.visibility
										? "Zichtbaar"
										: "Niet zichtbaar"}
								</span>
								<Switch
									active={item.visibility}
									setActive={setVisibility}
								/>
							</div>
						</div>
						<div className="fw-b fs-s c-sec pb-s pt">
							Aangemaakt door
						</div>
						<User user={item.created_by} />
						<div className="fw-b fs-s c-sec pb-s pt">
							Aangemaakt op
						</div>
						<div className="flex flex-align fs-s fw-b">
							<IconPlus className="icon icon-xs mr-s" />
							{dateFull(item.created_at)}
						</div>
						<div className="fw-b fs-s c-sec pb-s pt-s">
							Laatst aangepast
						</div>
						{item.updated_at && (
							<div className="flex flex-align fs-s fw-b">
								<IconEdit className="icon icon-xs mr-s" />
								{dateFull(item.updated_at)}
							</div>
						)}
					</div>
				</div>
			</div>
			<Files
				active={active}
				closeForm={() => setActive(false)}
				files={item?.files}
				setFiles={setFiles}
			/>
		</Fragment>
	);
};

export default Container;
