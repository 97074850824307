import {
    doc,
    query,
    collection,
    where,
    updateDoc,
    writeBatch,
} from "firebase/firestore";

import { db } from "core/firebase";
import { PlanningListener } from "./utils";
import { applicationStore } from "core/application/application/store";
import { projectStore } from "core/application/project/project/store";

const planningListener = new PlanningListener();

export const setPlanningListener = () => {
    const { id } = applicationStore.getState().application;
    const { project } = projectStore.getState();

    const q = query(
        collection(db, "applications", id, "projects", project.id, "planning"),
        where("visibility", "==", true),
        where("archived", "==", false)
    );

    planningListener.on(q);
};

export const removePlanningListener = () => {
    planningListener.off();
};

export const activateStage = async (payload) => {
    const { id } = applicationStore.getState().application;
    const { project } = projectStore.getState();

    const ref = doc(db, "applications", id, "projects", project.id);

    const error = await updateDoc(ref, {
        [`stages.${payload}`]: true,
    }).catch((error) => error);

    return error;
};

export const updateBoard = (sourceList, destinationList) => {
    const { id } = applicationStore.getState().application;
    const { project } = projectStore.getState();

    const batch = writeBatch(db);

    sourceList.list.forEach((item, index) => {
        const cardRef = doc(
            db,
            "applications",
            id,
            "projects",
            project.id,
            "planning",
            item.id
        );

        batch.update(cardRef, { position: index });
    });

    if (destinationList) {
        destinationList.list.forEach((item, index) => {
            const cardRef = doc(
                db,
                "applications",
                id,
                "projects",
                project.id,
                "planning",
                item.id
            );

            batch.update(cardRef, {
                position: index,
                listId: destinationList.id,
            });
        });
    }

    batch.commit();
};
