import { useEffect, useState, Fragment } from "react";
import { collection, query, where, doc } from "firebase/firestore";
import { db } from "core/firebase";
import { unwrapQuery, unwrapQueryDoc } from "core/utils";
import { authStore } from "core/authentication/store";

// Core
import { editPlanningStore } from "core/application/project/form-message/store";
import { updatePlanning } from "core/application/project/form-edit-planning/actions";

// Components
import Navigation from "../project-planning-edit/navigation";
import RoutingNavigation from "./utils/navigation";
import Completed from "../project-planning-edit/utils/completed";
import Tag from "components/planning/utils/tag";
import Content from "../project-planning-edit/task-content";
import Meta from "../project-planning-edit/meta";
import Messages from "./messages";
import Files from "../project-planning-edit/files";

const Container = ({ closeForm }) => {
    const { applicationId, projectId } = editPlanningStore().task;
    const { item } = editPlanningStore();
    const { uid } = authStore().user;

    const [active, setFilesActive] = useState(false);

    const [
        {
            isInTeam,
            isCreatedBySupervisor,
            teamWithCreator,
            teamWithSupervisor,
        },
        setState,
    ] = useState({
        isInTeam: false,
        isCreatedBySupervisor: false,
        teamWithCreator: [],
        teamWithSupervisor: [],
    });

    useEffect(() => {
        const initiateRoles = async () => {
            const qSupervisors = query(
                collection(db, "applications", applicationId, "users"),
                where("roles.SUPERVISOR", "==", true)
            );

            const qAdmins = query(
                collection(db, "applications", applicationId, "users"),
                where("roles.APPLICATION_ADMIN", "==", true)
            );

            const qTeam = collection(
                db,
                "applications",
                applicationId,
                "projects",
                projectId,
                "team"
            );

            const qProject = doc(
                db,
                "applications",
                applicationId,
                "projects",
                projectId
            );

            const [supervisors, admins, team, project] = await Promise.all([
                unwrapQuery(qSupervisors),
                unwrapQuery(qAdmins),
                unwrapQuery(qTeam),
                unwrapQueryDoc(qProject),
            ]);

            const list = [...admins, ...supervisors];

            const isCreatedBySupervisor = list.some(
                ({ id }) => id === item.created_by.uid
            );

            const teamList = team.map((item) => item.user);

            // create a list of team members & the author
            const teamWithCreator = [
                ...teamList.filter(
                    (user) => user.uid !== project.created_by.uid
                ),
                project.created_by,
            ];

            // create a list of the team and the supervisor
            const teamWithSupervisor = [
                ...teamWithCreator.filter(
                    (user) => user.uid !== project.supervised_by.uid
                ),
                project.supervised_by,
            ];

            setState({
                isInTeam: !!project.users[uid],
                teamWithCreator,
                teamWithSupervisor,
                isCreatedBySupervisor,
            });
        };

        initiateRoles();
        // eslint-disable-next-line
    }, []);

    const checkContentEditPermissions = () => {
        if (isCreatedBySupervisor) {
            if (!!(item?.created_by?.uid === uid)) {
                return true;
            } else {
                return false;
            }
        }

        if (isInTeam) {
            return true;
        }

        return false;
    };

    const checkMetaEditPermissions = () => {
        if (isInTeam) {
            return true;
        }

        return false;
    };

    const setFiles = (payload) => {
        updatePlanning({ files: payload });
    };

    const canEditContent = checkContentEditPermissions();
    const canEditMeta = checkMetaEditPermissions();

    return (
        <Fragment>
            <div className="modal-planning-container">
                <RoutingNavigation
                    applicationId={applicationId}
                    projectId={projectId}
                    closeForm={closeForm}
                />
                {canEditMeta && <Navigation item={item} />}
                {item.completed && (
                    <Completed item={item} canEdit={!!canEditMeta} />
                )}
                <Tag type={item.type} />
                <Content
                    item={item}
                    canEdit={canEditContent}
                    setFilesActive={setFilesActive}
                />
                <Meta
                    item={item}
                    canEditContent={canEditContent}
                    canEdit={canEditMeta}
                    team={teamWithCreator}
                />
                <Messages canEdit={canEditMeta} team={teamWithSupervisor} />
            </div>
            <Files
                active={active}
                closeForm={() => setFilesActive(false)}
                files={item?.files}
                setFiles={setFiles}
            />
        </Fragment>
    );
};

export default Container;
