import { useEffect, Fragment } from "react";

// Libraries
import { useParams } from "react-router-dom";

// Core
import { projectStore } from "core/application/project/project/store";
import { authStore } from "core/authentication/store";

// Listener
import {
    setProjectListener,
    removeProjectListener,
} from "core/application/project/project/actions";

// Components
import Loader from "components/utils/loader";
import PageNotFound from "pages/utils/page-not-found";
import Name from "./utils/name";
import Info from "./info";
import Planning from "./planning";
import CreateTeam from "components/forms/application/team-create";
import CreatePlanning from "components/forms/application/project-planning-create";
import EditPlanning from "components/forms/application/project-planning-edit";
import ArchivedPlanning from "components/forms/application/project-planning-archive";

const Project = () => {
    const { projectId } = useParams();
    const { user } = authStore();
    const { project, projectNotFound } = projectStore();

    useEffect(() => {
        setProjectListener(projectId);

        return () => removeProjectListener();
    }, [projectId]);

    if (projectNotFound) {
        return <PageNotFound />;
    }

    if (!project || !user) {
        return <Loader pageWithNav />;
    }

    return (
        <Fragment>
            <Name project={project} uid={user.uid} />
            <Info project={project} uid={user.uid} />
            <Planning />
            <CreateTeam />
            <CreatePlanning />
            <ArchivedPlanning />
            <EditPlanning />
        </Fragment>
    );
};

export default Project;
