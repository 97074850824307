import { useState } from "react";
import { NavLink } from "react-router-dom";

// Core
import { registerWithEmail } from "../../../core/authentication/actions";

// Config
import { errorCodes, routes } from "../config";

// Components
import Button from "components/button";
import Navigation from "../components/navigation";
import Socials from "../components/social";
import Label from "../../../components/inputs/label";
import Input from "../../../components/inputs/input-login";
import InputError from "../components/error";
import { IconLogin } from "../../../components/icons";

const Register = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const onSubmitRegistration = async (e) => {
        e.preventDefault();

        setLoading(true);

        const firstName = e.target["firstName"].value.trim();
        const lastName = e.target["lastName"].value.trim();
        const email = e.target["email"].value.toLowerCase().trim();
        const password = e.target["password"].value.trim();

        if (!firstName || !lastName) {
            setLoading(false);
            setError(errorCodes.NAME);
            return;
        }

        const error = await registerWithEmail({
            firstName,
            lastName,
            email,
            password,
        });

        if (error) {
            setLoading(false);
            setError(error);
        }
    };

    const errorName = !!(
        error &&
        (error === errorCodes.NAME || error === errorCodes.NAME_CHARS)
    );

    const errorEmail = !!(
        error &&
        (error === errorCodes.EMAIL ||
            error === errorCodes.EMAIL_IN_USE ||
            error === errorCodes.USER_NOT_FOUND)
    );

    const errorPassword = !!(
        error &&
        (error === errorCodes.PWD_WRONG || error === errorCodes.PWD_WEAK)
    );

    return (
        <section className="auth_layout pb-xl">
            <Navigation />
            <form
                onSubmit={onSubmitRegistration}
                noValidate
                className="container"
            >
                <div className="full">
                    <h1 className=" center">Welkom</h1>
                    <p className="fs-s c-sec pb center">
                        Voer je gegevens in om verder te gaan
                    </p>
                    <div className="row flex-space">
                        <div className="col__6">
                            <Label text="Voornaam" className="pb-s" />
                            <Input name="firstName" placeholder="Voornaam" />
                        </div>
                        <div className="col__6">
                            <Label text="Achternaam" className="pb-s" />
                            <Input name="lastName" placeholder="Achternaam" />
                        </div>
                    </div>
                    <InputError error={error} active={errorName} />
                    <Label text="Email" className="pb-s" />
                    <Input
                        name="email"
                        type="email"
                        autocomplete="on"
                        placeholder="Emailadres"
                        error={errorEmail}
                    />
                    <InputError error={error} active={errorEmail} />
                    <Label text="Wachtwoord" className="pb-s" />
                    <Input
                        name="password"
                        type="password"
                        autocomplete="new-password"
                        placeholder="Nieuw wachtwoord"
                        error={errorPassword}
                    />
                    <InputError error={error} active={errorPassword} />
                    <div className="flex flex-align mt">
                        <Button
                            text="Registreren"
                            type="submit"
                            loading={loading}
                            disabled={loading}
                        >
                            {!loading && (
                                <IconLogin className="icon icon-s ml-s" />
                            )}
                        </Button>
                        <p className="fs-s ml">
                            Heb je al een account?
                            <NavLink
                                to={routes.SIGN_IN}
                                className="color-main ml-s"
                            >
                                Klik hier
                            </NavLink>
                        </p>
                    </div>
                    <Socials />
                </div>
            </form>
        </section>
    );
};

export default Register;
