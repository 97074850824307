// Components
import Thumbnail from "../images/thumbnail";

const User = ({ user, className = "", text = "" }) => {
    if (!user) {
        return null;
    }

    return (
        <div className={`flex flex-align ${className}`}>
            <Thumbnail user={user} />
            <div className="ml" style={{ maxWidth: "10em" }}>
                <div className="fs-s fw-b text-wrap">{user.displayName}</div>
                <div className="fs-xs c-sec fw-n relative">{text}</div>
            </div>
        </div>
    );
};

export default User;
