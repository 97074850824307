import { RouterProvider } from "react-router-dom";

// Core
import { authStore } from "./core/authentication/store";

// Routers
import loginRouter from "./pages/login/router";
import applicationRouter from "pages/authenticated/router";

// Components
import Loader from "./pages/loading";
import UserNotFound from "./pages/utils/page-not-found";

const App = () => {
	const { isUserAuthenticated, isUserLoaded, isUserNotFound } = authStore();

	if (!isUserAuthenticated) {
		return <RouterProvider router={loginRouter} />;
	}

	if (!isUserLoaded) {
		return <Loader text="Account aan het ophalen..." page />;
	}

	if (isUserNotFound) {
		return <UserNotFound text="Gebruiker niet gevonden" />;
	}

	return <RouterProvider router={applicationRouter} />;
};

export default App;
