import { Fragment, useEffect, useState } from "react";

// Core
import { db } from "../../../../core/firebase";

import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import { unwrapList } from "../../../../core/utils";

// Store
import { applicationStore } from "../../../../core/application/application/store";

// Components
import Loader from "../../../../components/utils/loader";
import PageEmpty from "../../../loading";
import ButtonRound from "../../../../components/button/button-round";
import CreateInvitation from "../../../../components/forms/application/invitation-create";
import Column from "../../../../components/columns/columns/application/invitations";
import Row from "../../../../components/columns/rows/application/invitation";

const Invitations = () => {
    const { id } = applicationStore().application;

    const [list, setList] = useState(null);
    const [active, setActive] = useState(false);

    useEffect(() => {
        const q = query(
            collection(db, "applications", id, "invitations"),
            orderBy("created_at", "desc")
        );

        const unsubscribe = onSnapshot(q, (payload) =>
            setList(unwrapList(payload))
        );

        return () => unsubscribe();
    }, [id]);

    if (!list) {
        return <Loader pageWithNav />;
    }

    return (
        <Fragment>
            {list.length ? (
                <div className="p-wrap">
                    <Column />
                    {list.map((item) => (
                        <Row key={item.id} item={item} />
                    ))}
                </div>
            ) : (
                <PageEmpty
                    pageWithSubNav
                    text="Er staan helaas nog geen uitnodigingen."
                />
            )}
            <ButtonRound onClick={() => setActive(true)} />
            <CreateInvitation
                active={active}
                closeForm={() => setActive(false)}
            />
        </Fragment>
    );
};

export default Invitations;
