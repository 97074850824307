const PageNotFound = ({
    text = "Pagina helaas niet gevonden",
    slug,
    page = false,
    pageWithNav = false,
    pageWithSubNav = false,
}) => {
    return (
        <section
            className={`
                w-screen h-screen p-wrap flex items-center justify-center
                ${page && "h-screen"}
                ${pageWithNav && "h-screen-navigation"}
                ${pageWithSubNav && "h-screen-subNavigation"}
                `}
        >
            <div className="box-empty-wrap center">
                <img
                    width="100%"
                    style={{ maxHeight: "20rem" }}
                    alt="Pagina niet gevonden"
                    src={`${process.env.PUBLIC_URL}/illustrations/${
                        slug || "empty_projects"
                    }.svg`}
                />
                <h1 className="mt">{text}</h1>
            </div>
        </section>
    );
};

export default PageNotFound;

/*
<NavLink className="button mt-m" to={routesPlatform.BASE}>
<IconLeft className="icon icon-xs mr-s" />
<span className="fs-s">Terug</span>
</NavLink> 
</div>
*/
