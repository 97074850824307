// Libraries
import { useTransition } from "react-spring";

// Core
import { notificationStore } from "../../core/notifications/store";

// Variables
import { animationNotifications } from "../../variables/animations";

//  Components
import Notification from "./notification";

const Notifications = () => {
    const { list, removeNotification } = notificationStore();

    const transitions = useTransition(list, animationNotifications);

    return (
        <div className="notification-wrap flex-end container">
            {transitions((style, item) => (
                <Notification
                    style={style}
                    item={item}
                    removeNotification={removeNotification}
                />
            ))}
        </div>
    );
};

export default Notifications;
