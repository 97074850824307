import { useState } from "react";

// Core
import { createLibraryStore } from "../../../core/application/library/form-create-library";

//  Variables
import { types } from "../../../variables/types.library";

//  Components
import Button from "../../button";
import Menu from "../utils/menu";
import Item from "../utils/item";
import { IconLink, IconFile, IconPlay, IconPlus } from "../../icons";

const LibraryMenu = () => {
    const [active, setActive] = useState();
    const { openForm } = createLibraryStore();

    const closeMenu = () => {
        setActive(false);
    };

    const openMenu = () => {
        setActive(true);
    };

    return (
        <div className="relative ml">
            <Menu closeMenu={closeMenu} active={active} className="p-t-r">
                <Item text="Link" onClick={() => openForm(types.LINK)}>
                    <IconLink className="icon icon-xs mr-s" />
                </Item>
                <Item text="Document" onClick={() => openForm(types.DOCUMENT)}>
                    <IconFile className="icon icon-xs mr-s" />
                </Item>
                <Item text="Video" onClick={() => openForm(types.VIDEO)}>
                    <IconPlay className="icon icon-xs mr-s" />
                </Item>
            </Menu>
            <Button text="Toevoegen" onClick={openMenu}>
                <IconPlus className="icon icon-xs ml-s" />
            </Button>
        </div>
    );
};

export default LibraryMenu;
