import { useRef, useState, useEffect } from "react";

// Core
import {
    collection,
    query,
    orderBy,
    where,
    startAfter,
    limit,
    getDocs,
} from "firebase/firestore";

import { db } from "core/firebase";
import { authStore } from "core/authentication/store";
import { applicationStore } from "core/application/application/store";
import { unwrapList } from "core/utils";
import { editPlanningStore } from "core/application/project/form-message/store";

// Components
import Button from "components/button";
import Message from "components/inbox";
import Loader from "components/utils/loader";
import PageEmpty from "pages/loading";
import Image from "components/images/image";
import EditPlanning from "components/forms/application/message-planning-edit";
import { IconLink } from "components/icons";

const Messages = () => {
    const { uid } = authStore().user;
    const { id } = applicationStore().application;
    const { openForm } = editPlanningStore();

    const last = useRef(null);
    const [list, setList] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const q = query(
            collection(db, "messages"),
            where("applicationId", "==", id),
            where("userId", "==", uid),
            orderBy("created_at", "desc"),
            limit(5)
        );

        getDocs(q).then((payload) => {
            last.current = payload.docs[payload.docs.length - 1] || null;
            setList(unwrapList(payload));
        });
    }, [uid, id]);

    const getNextMessages = async () => {
        setLoading(true);

        const q = query(
            collection(db, "messages"),
            where("applicationId", "==", id),
            where("userId", "==", uid),
            orderBy("created_at", "desc"),
            startAfter(last.current),
            limit(5)
        );

        await getDocs(q).then((payload) => {
            last.current = payload.docs[payload.docs.length - 1] || null;
            setList([...list, ...unwrapList(payload)]);
        });

        setLoading(false);
    };

    if (!list) {
        return <Loader pageWithNav />;
    }

    return (
        <div className="container">
            <div className="card padding-card h-64 mt-m flex">
                <div className="col__6">
                    <Image
                        height="100%"
                        url={`${process.env.PUBLIC_URL}/logo/afbeelding-bij-reader.png`}
                    />
                </div>
                <div className="col__6 flex flex-col p-box">
                    <h1 className="mt-m">Reader 21 eeuwse professional</h1>
                    <div className="grow"></div>
                    <div>
                        <a
                            className="button"
                            target="blank"
                            rel="noreferrer noopener"
                            href="https://www.reader.youlab.nl/"
                        >
                            Reader lezen
                            <IconLink className="icon icon-s ml-s" />
                        </a>
                    </div>
                </div>
            </div>
            {!list.length ? (
                <PageEmpty
                    className="mt-m mb-m pt-m pb-m"
                    imageSlug="empty_inbox"
                    text="Er zijn nog geen berichten zichtbaar"
                />
            ) : (
                <div className="auto container-m full">
                    <h3 className="mb">Berichten</h3>
                    {list.map((item) => (
                        <Message
                            key={item.id}
                            item={item}
                            openForm={openForm}
                        />
                    ))}
                    {last.current && (
                        <Button
                            className="full mt mb-m"
                            text={loading ? "Laaden..." : "Meer berichten"}
                            disabled={loading}
                            onClick={getNextMessages}
                        ></Button>
                    )}
                </div>
            )}
            <EditPlanning />
        </div>
    );
};

export default Messages;
// import React, { useEffect } from "react";

// // - Libraries
// import { connect } from "react-redux";
// import { createSelector } from "reselect";

// // - Firebase
// import {
//     getInitialMessages,
//     getNextMessages,
//     getLastNode,
//     getMessages,
//     getLoading,
// } from "../../../../core/application/user-messages";

// // - Variables
// import locations from "../../../../variables/locations-error";

// // - Components
// import { IconSpin } from "../../../components/icons";
// import Button from "../../../components/buttons";
// import Loader from "../../../components/utils/loader";
// import EmptyPage from "../../../components/utils/empty-pages";
// import AnimatedList from "../../../components/animations/animation-list";
// import Message from "../../../components/inbox";
// import ErrorHandler from "../../../components/errors";

// const Messages = ({
//     getInitialMessages,
//     getNextMessages,
//     list,
//     loading,
//     lastNode,
// }) => {
//     useEffect(() => {
//         getInitialMessages();
//     }, [getInitialMessages]);

//     if (!list) {
//         return (
//             <div className="box-empty flex flex-center">
//                 <Loader />
//             </div>
//         );
//     }

//     if (!list.size) {
//         return (
//             <EmptyPage
//                 imageSlug="empty_inbox"
//                 text="Er zijn helaas nog geen berichten zichtbaar"
//             />
//         );
//     }

//     return (
//         <div className="pt-m pt-m p-wrap flex flex-middle">
//             <div className="full" style={{ maxWidth: "32rem" }}>
//                 <AnimatedList list={list} Component={Message} />
//                 {lastNode && (
//                     <Button
//                         className="full mt mb-m"
//                         text={loading ? "Laaden..." : "Meer berichten"}
//                         disabled={loading}
//                         onClick={getNextMessages}
//                     >
//                         {loading && (
//                             <IconSpin className="icon icon-s ml-s spin" />
//                         )}
//                     </Button>
//                 )}
//             </div>
//         </div>
//     );
// };

// const mapStateToProps = createSelector(
//     getMessages,
//     getLoading,
//     getLastNode,
//     (list, loading, lastNode) => ({ list, loading, lastNode })
// );

// const mapDispatchToProps = {
//     getInitialMessages,
//     getNextMessages,
// };

// const ComponentWithConnection = connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(Messages);

// export default () => (
//     <ErrorHandler
//         Component={ComponentWithConnection}
//         type={locations.APPLICATION_MESSAGES}
//     />
// );
