import React, { Component } from "react";

// - Components
import Label from "./label";

class Slides extends Component {
    state = { index: 0 };

    increaseState = () => {
        this.setState((state) => ({
            index: state.index + 1,
        }));
    };

    decreaseState = () => {
        this.setState((state) => ({
            index: state.index - 1,
        }));
    };

    indexUp = () => {
        const { index } = this.state;
        // - make sure index does over the amount of children
        if (index < this.props.children.length - 1) {
            // - check if it is required
            if (this.props.children[index].props.required) {
                // - check if there is a value and if yes increate the index
                if (this.props.children[index].props.value) {
                    this.increaseState();
                }
            } else {
                this.increaseState();
            }
        }
    };

    indexDown = () => {
        const { index } = this.state;
        // - make sure index does not go below 0
        if (index > 0) {
            this.decreaseState();
        }
    };

    renderDots = () => {
        return this.props.children.map((n, i) => (
            <Label key={i} index={i} position={this.state.index} />
        ));
    };

    render() {
        const { children } = this.props;

        return (
            <div>
                {React.Children.map(children, (child, index) =>
                    React.cloneElement(child, {
                        key: index,
                        indexUp: this.indexUp,
                        indexDown: this.indexDown,
                        position: this.state.index,
                        index,
                    })
                )}
                <div className="nav-footer pb-m flex flex-center">
                    <div className="flex flex-align">{this.renderDots()}</div>
                </div>
            </div>
        );
    }
}

export default Slides;
