import { db } from "core/firebase";
import { writeBatch, doc } from "firebase/firestore";
import { applicationStore } from "core/application/application/store";
import { projectStore } from "core/application/project/project/store";

export const editTeamMember = async (payload) => {
    const batch = writeBatch(db);

    const { id } = applicationStore.getState().application;
    const { project } = projectStore.getState();

    const refTeamMember = doc(
        db,
        "applications",
        id,
        "projects",
        project.id,
        "team",
        payload.user.uid
    );

    batch.update(refTeamMember, {
        roles: {
            [payload.role]: true,
        },
    });

    const error = await batch.commit().catch((error) => error);

    return error;
};
