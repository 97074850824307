import { useRef, useState } from "react";

// Libraries
import { addDoc, collection } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";

// Core
import { authStore } from "../../core/authentication/store";
import { applicationStore } from "../../core/application/application/store";
import { notificationStore } from "../../core/notifications/store";
import { storage, db } from "../../core/firebase";
import { deleteFile } from "core/application/files/actions";

// Components
import { IconLoading, IconUpload } from "../icons";
import File from "./file";

const Uploader = ({
	setFile = () => {},
	file = null,
	setFiles = () => {},
	files = [],
	isSingle = true,
}) => {
	const { uid } = authStore().user;
	const { id } = applicationStore().application;
	const { addNotification } = notificationStore();

	const input = useRef();

	const [loading, setLoading] = useState(false);

	const onClick = () => {
		input.current.click();
	};

	const onUpload = async ({ target }) => {
		setLoading(true);

		const file = target.files[0];
		target.value = null;

		const fileRef = ref(storage, `users/${uid}/library/${file.name}`);

		const upload = await uploadBytes(fileRef, file).catch((error) => ({
			error,
		}));

		if (upload.error) {
			addNotification({
				error: upload.error,
				message:
					"Er is een fout opgetreden tijdens het uploaden van het bestand. Probeer het opnieuw.",
			});

			setLoading(false);
			return;
		}

		const downloadUrl = await getDownloadURL(fileRef);

		const data = {
			name: file.name,
			applicationId: id,
			downloadUrl: downloadUrl,
			size: upload.metadata.size,
			type: upload.metadata.contentType,
			bucket: upload.metadata.bucket,
			fullPath: upload.metadata.fullPath,
			storageName: upload.metadata.name,
			created_at: new Date(),
		};

		const libraryRef = await addDoc(
			collection(db, "users", uid, "library"),
			data
		);

		addFile({ id: libraryRef.id, ...data });

		setLoading(false);
	};

	const addFile = (payload) => {
		if (isSingle) {
			setFile(payload);
		} else {
			setFiles([payload, ...files]);
		}
	};

	const toggleFile = (payload) => {
		if (isSingle) {
			setFile(payload);
		} else {
			if (files.some(({ id }) => id === payload.id)) {
				setFiles(files.filter(({ id }) => id !== payload.id));
			} else {
				setFiles([...files, payload]);
			}
		}
	};

	const removeFile = async (payload) => {
		await deleteFile(payload);
		// remove the file
		if (isSingle) {
			setFile(null);
		} else {
			setFiles(files.filter(({ id }) => id !== payload.id));
		}
	};

	return (
		<>
			<button
				className="p-4 b-dashed-main b-r bgc-main color-main w-full"
				onClick={onClick}
				disabled={loading}
			>
				<div className="flex flex-center pt-m pb-m">
					{loading ? (
						<IconLoading className="icon icon-m spin" />
					) : (
						<IconUpload className="icon icon-m" />
					)}
				</div>
				<p className="fs-s center fw-n">
					Klik <span className="fw-b underline">hier</span> om een
					bestand te uploaden.
				</p>
				<input
					className="hidden"
					type="file"
					style={{ display: "none" }}
					onChange={onUpload}
					ref={input}
					multiple={false}
				/>
			</button>
			<div className="pt-m pb-m">
				<p className="fs-s fw-b color-gray pb">
					Bestanden die al zijn toegevoegd:
				</p>
				<div className="flex flex-wrap">
					{isSingle ? (
						file ? (
							<File
								key={file.id}
								item={file}
								canRemove
								files={files}
								file={file}
								isSingle={isSingle}
								toggleFile={toggleFile}
								removeFile={removeFile}
							/>
						) : null
					) : (
						files.map((item) => (
							<File
								key={item.id}
								item={item}
								canRemove
								files={files}
								file={file}
								isSingle={isSingle}
								toggleFile={toggleFile}
								removeFile={removeFile}
							/>
						))
					)}
				</div>
			</div>
		</>
	);
};

export default Uploader;
