import create from "zustand";
import { applicationStore } from "../../application/store";
import { db } from "../../../firebase";
import { doc, updateDoc } from "firebase/firestore";

export const editSupervisorStore = create((set) => ({
    active: false,
    project: null,
    openForm: (project = null) => set(() => ({ active: true, project })),
    closeForm: () => set(() => ({ active: false })),
}));

export const editSupervisor = (payload) => {
    const { id } = applicationStore.getState().application;
    const { project } = editSupervisorStore.getState();

    const error = updateDoc(
        doc(db, "applications", id, "projects", project.id),
        {
            supervised_by: payload,
        }
    );

    return error;
    // .catch((error) => ({ error }))
    // .collection("applications")
    // .doc(applicationId)
    // .collection("projects")
    // .doc(projectId)
    // .update({
    //     supervised_by: payload,
    // })
    // .then(() => {
    //     dispatch(toggleForm());
    //     dispatch(addNotification({ message: message.UPDATE.SUCCESS }));
    // })
    // .catch((error) =>
    //     dispatch(addNotification({ error, message: message.UPDATE.ERROR }))
    // );
};

export const editVisibility = (payload) => {
    const { id } = applicationStore.getState().application;
    const { project } = editSupervisorStore.getState();

    const error = updateDoc(
        doc(db, "applications", id, "projects", project.id),
        {
            visibility: !!payload,
        }
    );

    return error;
};
