// Components
import Thumbnail from "../../../images/thumbnail";

const Admin = ({ user, admin, setAdmin }) => {
    const selected = admin && admin.uid === user.user.uid;

    return (
        <button
            className={`flex a-l flex-align p-box-s full b-r b mb ${
                selected && "bg-cloud"
            }`}
            onClick={() => setAdmin(user.user)}
        >
            <Thumbnail user={user.user} />
            <div className="ml">
                <div className="fs-s fw-b">
                    {user.user.displayName}
                    {selected && (
                        <span className="c-main"> - Geselecteerd</span>
                    )}
                </div>
            </div>
        </button>
    );
};

export default Admin;
