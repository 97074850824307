import {
	signOut,
	signInWithPopup,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	sendPasswordResetEmail,
} from "firebase/auth";

import { doc } from "firebase/firestore";

import { auth, db } from "../firebase";
import { googleProvider, facebookProvider } from "./providers";
import { UserListener, createSocialUser, createLocalUser } from "./utils";

const userListener = new UserListener();

export const setUserListener = (user) => {
	userListener.on(doc(db, "users", user.uid));
};
export const removeUserListener = async () => {
	await signOut(auth);
	userListener.off();
};

export const registerWithEmail = async (payload) => {
	const { user, error } = await createUserWithEmailAndPassword(
		auth,
		payload.email,
		payload.password
	)
		.then(({ user }) => ({ user }))
		.catch((error) => ({ error }));

	if (error) {
		return error.code;
	}

	const result = await createLocalUser(user, payload);

	setUserListener(result);
};

export const signInWithEmail = async (payload) => {
	const { user, error } = await signInWithEmailAndPassword(
		auth,
		payload.email,
		payload.password
	)
		.then(({ user }) => ({ user }))
		.catch((error) => ({ error }));

	if (error) {
		return error.code;
	}

	setUserListener(user);
};

export const resetWithEmail = async (email) => {
	const error = await sendPasswordResetEmail(auth, email).catch(
		(error) => error
	);

	return error;
};

export const signInWithFacebook = async () => {
	const { user, error } = await signInWithPopup(auth, facebookProvider)
		.then(({ user }) => ({ user }))
		.catch((error) => ({ error }));

	if (error) {
		return;
	}

	const result = await createSocialUser(user);
	setUserListener(result);
};

export const signInWithGoogle = async () => {
	const { user, error } = await signInWithPopup(auth, googleProvider)
		.then(({ user }) => ({ user }))
		.catch((error) => ({ error }));

	if (error) {
		return;
	}

	const result = await createSocialUser(user);
	setUserListener(result);
};
