import React from "react";
import { createRoot } from "react-dom/client";

import "./styles/index.scss";

import App from "./app";
import Notifications from "./components/notifications";
import { checkUserAuthentication } from "./core/authentication";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const renderApp = (App) =>
    root.render(
        <React.Fragment>
            <Notifications />
            <App />
        </React.Fragment>
    );

checkUserAuthentication().then(() => renderApp(App));
