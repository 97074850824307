import { Fragment } from "react";

// Libraries
import { Outlet } from "react-router-dom";

// Components
import Navigation from "../../components/navigations/application";

const RootApplication = () => {
    return (
        <Fragment>
            <Navigation />
            <Outlet />
        </Fragment>
    );
};

export default RootApplication;
