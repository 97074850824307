// Core
import { createProjectStore } from "../../../../core/application/project/form-create-project";

// Components
import Modal from "../../modal";
import Container from "./container";

const CreateProject = () => {
    const { closeForm, active } = createProjectStore();

    return (
        <Modal active={active} toggle={closeForm}>
            <Container closeForm={closeForm} />
        </Modal>
    );
};

export default CreateProject;
