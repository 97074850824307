import { useState, useEffect } from "react";

// Core
import { db } from "../../../../core/firebase";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import { createOrganization } from "../../../../core/platform/form-create-application/actions";

//  Components
import Loader from "../../../utils/loader";
import Label from "../../../inputs/label";
import Input from "../../../inputs/input";
import Uploader from "../../../inputs/upload";
import Organization from "./organization";
import { IconEdit, IconLeft } from "../../../icons";
import { unwrapList } from "../../../../core/utils";

const Organizations = ({ organization, setOrganization }) => {
    const [active, setActive] = useState(false);
    const [name, setName] = useState("");
    const [thumbnail, setThumbnail] = useState(null);
    const [list, setList] = useState(null);

    useEffect(() => {
        const q = query(
            collection(db, "organizations"),
            orderBy("created_by", "desc")
        );

        const unsubscribe = onSnapshot(q, (payload) => {
            setList(unwrapList(payload));
        });

        return () => unsubscribe();
    }, []);

    const onSubmitOrganization = async () => {
        const { error = false } = await createOrganization({ name, thumbnail });

        if (error) {
            return;
        }
        setActive(false);
    };

    if (!list) {
        return <Loader />;
    }

    if (active) {
        return (
            <div>
                <Label
                    text="Wat is de naam van de organisatie?"
                    className="pb-s"
                />
                <Input
                    placeholder="Type hier de naam..."
                    type="text"
                    onChange={({ target }) => setName(target.value)}
                    value={name}
                />
                <Label
                    className="pb-s pt-s"
                    text="Voeg een bedrijfslogo of foto toe."
                />

                <Uploader
                    button
                    uploadFile={setThumbnail}
                    folderName="organizations"
                    databaseRef="uploads_organization"
                    className="btn full"
                    accept="image/*"
                />

                {thumbnail && (
                    <div className="mt flex flex-center img--m b-r bgc-g p-box">
                        <img
                            width="100%"
                            alt="logo"
                            src={thumbnail.downloadURL}
                        />
                    </div>
                )}

                <div className="flex flex-align mt">
                    <button
                        className="btn btn--gray fs-s flex-2 mr"
                        onClick={() => setActive(!active)}
                    >
                        Terug
                        <IconLeft className="icon icon-s ml-s" />
                    </button>
                    <button
                        className={`btn fs-s flex-2 ${
                            (!thumbnail || !name) && "btn--disabled"
                        }`}
                        disabled={!name || !thumbnail}
                        onClick={onSubmitOrganization}
                    >
                        Versturen
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="relative">
            <div
                className={`modal-select ${!list.length && "flex flex-center"}`}
            >
                {list && list.length ? (
                    list.map((item) => (
                        <Organization
                            key={item.id}
                            item={item}
                            organization={organization}
                            setOrganization={setOrganization}
                        />
                    ))
                ) : (
                    <div className="fs-s fw-b c-sec">Nog geen organisaties</div>
                )}
            </div>
            <button className="btn-round p-b-r" onClick={() => setActive(true)}>
                <span>
                    <IconEdit className="icon icon-s" />
                </span>
            </button>
        </div>
    );
};

export default Organizations;
