import { useState } from "react";

// Components
import Navigation from "./navigation";
import Form from "./form";
import User from "./utils/selected";
import List from "./messages";

const Messages = ({ team = [], canEdit, setMessage = () => {} }) => {
    const [selected, setSelected] = useState([]);
    const [active, setActive] = useState(false);

    const addUser = (payload) => {
        if (selected.some(({ uid }) => uid === payload.uid)) {
            setSelected(selected.filter((item) => item.uid !== payload.uid));
        } else {
            setSelected([payload, ...selected]);
        }
    };

    const removeUser = (payload) => {
        setSelected(selected.filter((item) => item.uid !== payload.uid));
    };

    const clearUsers = () => {
        setSelected([]);
    };

    return (
        <div className="mt-m pt-m border-top">
            <Navigation
                active={active}
                team={team}
                selected={selected}
                setActive={setActive}
                addUser={addUser}
            />
            <div className="flex flex-align pb">
                <span className="fw-b fs-s inline mr">Getagt:</span>
                {selected.length ? (
                    selected.map((item) => (
                        <User
                            key={item.id}
                            user={item}
                            removeUser={removeUser}
                        />
                    ))
                ) : (
                    <div className="fs-s ml-s fw-n color-gray">
                        Er zijn nog geen teamleden geselecteerd
                    </div>
                )}
            </div>
            <Form selected={selected} clearUsers={clearUsers} />
            <List addUser={addUser} />
        </div>
    );
};

export default Messages;
