//  Components
import Thumbnail from "../../../images/thumbnail";

const Supervisor = ({ item, supervisor, setSupervisor }) => {
    const selected = supervisor.uid === item.user.uid;

    return (
        <button
            className={`flex a-l flex-align p-box full modal-list-item radius ${
                selected && "bg-cloud"
            }`}
            disabled={selected}
            onClick={() => setSupervisor(item.user)}
        >
            <Thumbnail user={item.user} small />
            <div className="ml">
                <div className="fs-s fw-b">
                    {item.user.displayName}
                    {selected && (
                        <span className="color-main"> - Geselecteerd</span>
                    )}
                </div>
            </div>
        </button>
    );
};

export default Supervisor;
