// Components
import { dateFull } from "../utils/dates";

import { IconMessage, IconPlus } from "../icons";

const Icon = ({ Component, className = "" }) => (
    <div>
        <div className={`img img--sm flex-center b-r ${className}`}>
            <Component className="icon icon-sm" />
        </div>
    </div>
);

const Title = ({ title, created_at }) => (
    <div className="flex flex-align flex-space">
        <div className="fw-b fs-s">{title}</div>
        <div className="fs-xs c-sec fw-b ml-s">{dateFull(created_at)}</div>
    </div>
);

const MessageComment = ({ item, openForm }) => (
    <div className="card mb">
        <button onClick={() => openForm(item)} className="flex p-box">
            <Icon Component={IconMessage} className="bg-message" />
            <div className="ml full">
                <Title title="Nieuw bericht" created_at={item.created_at} />
                <p className="fs-s fw-n a-l pt-s">
                    {item.message.created_by.displayName}
                    heeft je een persoonlijk bericht gestuurd. Klik verder om
                    dit bericht te lezen.
                </p>
            </div>
        </button>
    </div>
);

const MessageTaskCreated = ({ item, openForm }) => (
    <div className="card mb">
        <button className="flex p-box" onClick={() => openForm(item)}>
            <Icon Component={IconPlus} className="bg-task" />
            <div className="ml full">
                <Title title="Nieuwe Taak" created_at={item.created_at} />
                <p className="fs-s fw-n a-l">
                    {item.submitted_by.displayName} heeft een taak toegevoegd
                    aan je planning. Klik verder om deze taak te bekijken.
                </p>
            </div>
        </button>
    </div>
);

const Message = ({ item, openForm }) => {
    const types = {
        MESSAGE_PLANNING: "MESSAGE_PLANNING",

        TASK_CREATED: "TASK_CREATED",
    };

    if (item.type === types.MESSAGE_PLANNING) {
        return <MessageComment item={item} openForm={openForm} />;
    }

    if (item.type === types.TASK_CREATED) {
        return <MessageTaskCreated item={item} openForm={openForm} />;
    }

    return null;
};

export default Message;

/*
   const types = {
        MESSAGE_PLANNING: "MESSAGE_PLANNING",
        QUESTION_SUBMITTED: "QUESTION_SUBMITTED",
        ASSIGNMENT_SUBMITTED: "ASSIGNMENT_SUBMITTED",
        QUESTION_REVIEWED: "QUESTION_REVIEWED",
        ASSIGNMENT_REVIEWED: "ASSIGNMENT_REVIEWED",
        TASK_CREATED: "TASK_CREATED",
        NEW_QUESTION_ANSWERED: "NEW_QUESTION_ANSWERED",
    };

    //  return (
    //     <div className="card mb">
    //         {/* {item.type === types.ASSIGNMENT_SUBMITTED && (
    //             <MessageAssignment item={item} />
    //         )}
    //         {item.type === types.QUESTION_SUBMITTED && (
    //             <MessageQuestion item={item} />
    //         )}
    //         {item.type === types.QUESTION_REVIEWED && (
    //             <MessageQuestionReviewed item={item} />
    //         )}
    //         {item.type === types.ASSIGNMENT_REVIEWED && (
    //             <MessageAssignmentReviewed item={item} />
    //         )} */
//         {item.type === types.MESSAGE_PLANNING && (
//             <MessageComment item={item} openForm={openForm} />
//         )}
//         {item.type === types.TASK_CREATED && (
//             <MessageTaskCreated item={item} openForm={openForm} />
//         )}
//         {/* {item.type === types.NEW_QUESTION_ANSWERED && (
//             <MessageQuestionAnswered item={item} />
//         )} */}
//     </div>
// );
// const MessageQuestion = ({ item }) => (
//     <Link
//         className="flex p-box"
//         to={{
//             pathname: `/app/${item.applicationId}/vraag/${item.projectId}/${item.questionId}`,
//             state: { modal: true },
//         }}
//     >
//         <Icon Component={IconHelp} className="bg-question" />
//         <div className="ml full">
//             <Title
//                 title="Vraag ingeleverd"
//                 created_at={item.created_at}
//                 viewed={item.viewed}
//             />
//             <p className="fs-s fw-n">
//                 Er is een vraag gesteld door{" "}
//                 <b>{item.submitted_by.displayName}</b> Klik verder om te
//                 beantwoorden.
//             </p>
//         </div>
//     </Link>
// );

// const MessageQuestionAnswered = ({ item }) => {
//     return (
//         <Link
//             className="flex p-box"
//             to={`/app/${item.applicationId}/vraag/${item.projectId}/${item.questionId}`}
//         >
//             <Icon Component={IconHelp} className="bg-question" />
//             <div className="ml full">
//                 <Title
//                     title="Je vraag is beantwoord"
//                     created_at={item.created_at}
//                     viewed={item.viewed}
//                 />
//                 <p className="fs-s fw-n">
//                     Je vraag is beantwoord door {item.submitted_by.displayName}.
//                     Klik verder om de beoordeling te bekijken
//                 </p>
//             </div>
//         </Link>
//     );
// };

// const MessageAssignment = ({ item }) => (
//     <Link
//         className="flex p-box"
//         to={`/app/${item.applicationId}/opdracht/${item.projectId}/${item.taskId}`}
//     >
//         <Icon Component={IconFile} className="bg-assignment" />
//         <div className="ml full">
//             <Title
//                 title="Opdracht ingeleverd"
//                 created_at={item.created_at}
//                 viewed={item.viewed}
//             />
//             <p className="fs-s fw-n ">
//                 Je opdracht is beoordeeld door{" "}
//                 <b>{item.submitted_by.displayName}</b>. Klik verder om de
//                 beoordeling te bekijken
//             </p>
//         </div>
//     </Link>
// );

// const MessageQuestionReviewed = ({ item }) => (
//     <Link
//         className="flex p-box"
//         to={`/app/${item.applicationId}/planning/${item.projectId}/${item.taskId}`}
//     >
//         <Icon Component={IconHelp} className="bg-question" />
//         <div className="ml full">
//             <Title
//                 title="Je vraag is beoordeeld"
//                 created_at={item.created_at}
//                 viewed={item.viewed}
//             />
//             <p className="fs-s fw-n">
//                 Je vraag is beantwoord door Hans Oude Avenhuis. Klik verder om
//                 de beoordeling te bekijken
//             </p>
//         </div>
//     </Link>
// );

// const MessageAssignmentReviewed = ({ item }) => (
//     <Link
//         className="flex p-box"
//         to={`/app/${item.applicationId}/planning/${item.projectId}/${item.taskId}`}
//     >
//         <Icon Component={IconCheck} className="bg-assignment" />
//         <div className="ml full">
//             <Title
//                 title="Je opdracht is beoordeeld"
//                 created_at={item.created_at}
//                 viewed={item.viewed}
//             />
//             <p className="fs-s fw-n">
//                 Je opdracht is beoordeeld met 80 credits door Hans Oude
//                 Avenhuis. Klik verder om de beoordeling te bekijken
//             </p>
//         </div>
//     </Link>
// );
