import { Draggable } from "react-beautiful-dnd";

// Components
import Tag from "components/planning/utils/tag";
import Deadline from "components/planning/utils/deadline";
import { IconAttachment, IconCheck } from "components/icons";

const Card = ({ item, openForm, index = 0 }) => {
    return (
        <Draggable draggableId={item.id} index={index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`card mb p-box a-l ${
                        snapshot.isDragging && "card-drag"
                    }`}
                    onClick={() => openForm(item.id)}
                >
                    <Tag type={item.type} />
                    <div className={`fw-b pt-s pb-s ${item.completed && "ts"}`}>
                        {item.name}
                    </div>
                    <div className="flex flex-align">
                        {!!item.deadline && !item.completed && (
                            <Deadline deadline={item.deadline} />
                        )}
                        {item.completed && (
                            <div className="fw-b flex flex-align fs-xs">
                                <IconCheck className="icon icon-xs mr-s" />
                                Voltooid
                            </div>
                        )}

                        {!!item.files.length && (
                            <div className="flex flex-align fs-xs ml fw-b">
                                <IconAttachment className="icon icon-xs mr-s" />
                                {item.files.length}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </Draggable>
    );
};

export default Card;
