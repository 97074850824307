// Variables
import { list } from "../../variables/types.library";

// Components
import Link from "../links/library";
import Button from "../button";
import Menu from "../menu/library";
import { IconArchive } from "../icons";

const Navigation = ({ setActiveArchive, canEdit = false }) => {
    return (
        <nav className="navigation border-bottom">
            <div className="container nav-main flex justify-between">
                <div className="flex align-items">
                    {list.map((item) => (
                        <Link key={item.name} {...item} />
                    ))}
                </div>
                <div className="flex items-center">
                    {canEdit && (
                        <Button
                            text="Archief"
                            onClick={() => setActiveArchive(true)}
                        >
                            <IconArchive className="icon icon-xs ml-s" />
                        </Button>
                    )}
                    {canEdit && <Menu />}
                </div>
            </div>
        </nav>
    );
};

export default Navigation;
