// Libraries
import { NavLink } from "react-router-dom";

// Core
import { applicationStore } from "core/application/application/store";

// Variables
import { routesApplication } from "variables/routes.application";

// Components
import { IconHelp, IconLeft, IconLink } from "components/icons";

const Navigation = () => {
	const { name } = applicationStore().application;

	return (
		<nav className="navigation border-bottom">
			<div className="flex flex-align flex-space nav-main container">
				<div className="flex flex-align">
					<a
						href="https://www.figma.com/proto/vBaRNtDjlHC2GC0kPnfXct/YouLab-handleiding?page-id=0%3A1[…]caling=scale-down&starting-point-node-id=46%3A2661&mode=design"
						className="button mr-s"
						target="_blank"
						rel="noopener noreferrer"
					>
						<IconLink className="icon icon-xs mr-s" />
						<span>Handleiding</span>
					</a>
					<NavLink to={routesApplication.PLATFORM} className="button">
						<IconLeft className="icon icon-xs mr-s" />
						<span>Alle applicaties</span>
					</NavLink>
					<h2 className="ml fw-n">{name}</h2>
				</div>
				<a
					href="https://faq.youlab.nl"
					className="button"
					target="_blank"
					rel="noopener noreferrer"
				>
					<span>FAQ</span>
					<IconHelp className="icon icon-xs ml-s" />
				</a>
			</div>
		</nav>
	);
};

export default Navigation;
