import { useRef, useState, useEffect } from "react";

// - Components
import { IconDownload, IconTrash } from "components/icons";
import { setFormate } from "./helpers";
import FileType from "components/utils/file-type";

const File = ({ item, remove = false, removeFile = null }) => {
    const documentLink = useRef();
    const downloadLink = useRef();

    const [downloadUrl, setDownloadUrl] = useState(null);

    useEffect(() => {
        if (downloadUrl) {
            downloadLink.current.click();
        }
    }, [downloadUrl]);

    const downloadFile = () => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = () => {
            downloadUrl
                ? downloadLink.current.click()
                : setDownloadUrl(URL.createObjectURL(xhr.response));
        };
        xhr.open("GET", item.downloadUrl);
        xhr.send();
    };

    const openLink = () => {
        documentLink.current.click();
    };

    return (
        <div className="file flex flex-space flex-align mb">
            <div className="flex flex-align text-wrap pr">
                <FileType type={item.type} />
                <div className="text-wrap pr">
                    <button className="fs-s text-wrap" onClick={openLink}>
                        {item.name}
                    </button>

                    <div className="fs-xs c-sec fw-b up">
                        {setFormate(item.size)}
                    </div>
                </div>
            </div>

            <div className="flex flex-align color-main">
                <button
                    className="ml pl pt-s pb-s flex flex-center"
                    onClick={downloadFile}
                >
                    <IconDownload className="icon icon-s" />
                </button>
                {remove && (
                    <button
                        className="ml pl pt-s pb-s flex flex-center"
                        onClick={() => removeFile(item)}
                    >
                        <IconTrash className="icon icon-s" />
                    </button>
                )}
            </div>
            <a
                hidden
                ref={downloadLink}
                href={downloadUrl} // from the state
                download={item.name}
            >
                download link
            </a>
            <a
                hidden
                ref={documentLink}
                href={item.downloadUrl}
                target="_blank"
                rel="noreferrer noopener"
            >
                open link
            </a>
        </div>
    );
};

export default File;
