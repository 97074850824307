import { Fragment, useEffect } from "react";

// Libraries
import { Outlet } from "react-router-dom";

// Core
import {
	setPlatformRoleListener,
	removePlatformRoleListener,
} from "../../core/roles/actions";

// Components
import Navigation from "../../components/navigations/platform";
import CreateApplication from "../../components/forms/platform/create-application";

const Platform = () => {
	useEffect(() => {
		setPlatformRoleListener();
		return () => removePlatformRoleListener();
	}, []);

	return (
		<Fragment>
			<Navigation />
			<Outlet />
			<CreateApplication />
		</Fragment>
	);
};

export default Platform;
