import create from "zustand";

export const initialState = {
    active: false,
    item: null,
    task: null,
    itemNotFound: false,
};
export const editPlanningStore = create((set) => ({
    ...initialState,
    openForm: (task = null) => set(() => ({ active: true, task })),
    closeForm: () => set(() => ({ active: false })),
}));
