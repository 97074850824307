import { Fragment } from "react";

// Core
import { updatePlanning } from "core/application/project/form-edit-planning/actions";

//  Components
import Button from "components/button";
import File from "components/utils/file";
import InputEdit from "components/inputs/input-edit";
import { IconPlus } from "components/icons";

const Content = ({ canEdit, item, setFilesActive }) => {
	const setName = (payload) => {
		updatePlanning({ name: payload });
	};

	const setDescription = (payload) => {
		updatePlanning({ description: payload });
	};

	const removeFile = (payload) => {
		if (!item.files) return;

		updatePlanning({
			files: item.files.filter((item) => item.id !== payload.id),
		});
	};

	const { name, description, files } = item;

	return (
		<Fragment>
			{canEdit ? (
				<InputEdit
					className="fw-b pl-s fs-l"
					placeholder="Deze taak heeft nog geen naam."
					text={name}
					setText={setName}
				/>
			) : (
				<div className="pt-s pb-s pl-s fs-l fw-b">{name}</div>
			)}
			{canEdit ? (
				<InputEdit
					className="pl-s fw-n pt-s p"
					placeholder="Deze taak heeft nog geen naam."
					text={description}
					setText={setDescription}
					textarea
				/>
			) : (
				<div className="pl-s pt-s pb-s fw-n p">
					{description
						? description
						: "Deze taak heeft nog geen omschrijving."}
				</div>
			)}
			{canEdit && (
				<div className="flex flex-align flex-space mt-m mb-m">
					<h3>Bestanden</h3>
					<Button
						text="Bestanden toevoegen"
						onClick={() => setFilesActive(true)}
					>
						<IconPlus className="icon icon-s ml-s" />
					</Button>
				</div>
			)}
			{files &&
				files.map((item) => (
					<File
						key={item.id}
						remove={canEdit}
						removeFile={removeFile}
						item={item}
					/>
				))}
		</Fragment>
	);
};

export default Content;
