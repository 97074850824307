// Libraries
import { NavLink } from "react-router-dom";

//  Variables
import { routesPlatformDashboard } from "../../variables/routes.platform.dashboard";

const Navigation = () => {
    return (
        <nav className="navigation border-bottom">
            <div className="nav-main flex flex-space container">
                <div />
                <div className="flex flex-align">
                    <NavLink
                        to={routesPlatformDashboard.ROOT}
                        end
                        className={({ isActive }) =>
                            `link ${isActive && "color-main"}`
                        }
                    >
                        <span>Applicaties</span>
                    </NavLink>
                    <NavLink
                        to={routesPlatformDashboard.ADMINS}
                        className={({ isActive }) =>
                            `link ${isActive && "color-main"}`
                        }
                    >
                        <span>Beheerders</span>
                    </NavLink>
                    <NavLink
                        to={routesPlatformDashboard.ORGANIZATIONS}
                        className={({ isActive }) =>
                            `link ${isActive && "color-main"}`
                        }
                    >
                        <span>Organisaties</span>
                    </NavLink>
                    <NavLink
                        to={routesPlatformDashboard.USERS}
                        className={({ isActive }) =>
                            `link ${isActive && "color-main"}`
                        }
                    >
                        <span>Gebruikers</span>
                    </NavLink>
                </div>
            </div>
        </nav>
    );
};

export default Navigation;

/*
<NavLink
                    className="link"
                    activeClassName="link--active"
                    to={`${routes.DASHBOARD}/${filters.ERRORS_UI}`}
                >
                    <span>Errors UI</span>
                </NavLink>
                <NavLink
                    className="link"
                    activeClassName="link--active"
                    to={`${routes.DASHBOARD}/${filters.ERRORS_DB}`}
                >
                    <span>Errors DB</span>
                </NavLink>
*/
