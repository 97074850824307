import { editStage } from "core/application/project/form-edit-stage/actions";

// Variables
import { text } from "./config";

// Components
import Button from "components/button";

const Container = ({ id, closeForm }) => {
    const config = text[id];

    const setStage = async () => {
        await editStage(id);
        closeForm();
    };

    return (
        <div className="modal-container">
            <h2 className="fs-m">{config.title}</h2>
            <p className="p mt mb-m">{config.description}</p>
            <div className="flex flex-end">
                <Button
                    className="button-text mr"
                    text="Annuleren"
                    onClick={closeForm}
                />
                <Button text={`${config.name} unlocken`} onClick={setStage} />
            </div>
        </div>
    );
};

export default Container;
