import { db } from "../../../firebase";

import { applicationStore } from "../../application/store";

import { writeBatch, doc } from "firebase/firestore";

export const createInvitation = async (payload) => {
    const { id } = applicationStore.getState().application;

    const batch = writeBatch(db);

    const invitationRef = doc(
        db,
        "invitations",
        payload.email,
        "applications",
        id
    );

    const applicationInvitationRef = doc(
        db,
        "applications",
        id,
        "invitations",
        payload.email
    );

    batch.set(
        invitationRef,
        { ...payload, created_at: new Date() },
        { merge: true }
    );

    batch.set(
        applicationInvitationRef,
        {
            ...payload,
            created_at: new Date(),
        },
        { merge: true }
    );

    const error = await batch.commit().catch((error) => ({ error }));

    return error;
};
