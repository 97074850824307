export const stages = [
    {
        id: "ONTMOETINGSFASE",
        title: "Ontmoetingsfase",
    },
    {
        id: "ONTDEKKINGSFASE",
        title: "Ontdekkingsfase",
    },
    {
        id: "ONTWIKKELFASE",
        title: "Ontwikkelfase",

        disabledText:
            "Unlock eerst de ontdekkingsfase voordat je verder kunt met de ontwikkelfase.",
    },
    {
        id: "ORGANISATIEFASE",
        title: "Organisatiefase",

        disabledText:
            "Unlock eerst de ontwikkelfase voordat verder kunt met de organisatiefase.",
    },
];

export const types = {
    ONTMOETINGSFASE: "ONTMOETINGSFASE",
    ONTDEKKINGSFASE: "ONTDEKKINGSFASE",
    ONTWIKKELFASE: "ONTWIKKELFASE",
    ORGANISATIEFASE: "ORGANISATIEFASE",
};

export const list = [
    {
        id: "ONTMOETINGSFASE",
        title: "Ontmoetingsfase",
    },
    {
        id: "ONTDEKKINGSFASE",
        title: "Ontdekkingsfase",
    },
    {
        id: "ONTWIKKELFASE",
        title: "Ontwikkelfase",

        disabledText:
            "Unlock eerst de ontdekkingsfase voordat je verder kunt met de ontwikkelfase.",
    },
    {
        id: "ORGANISATIEFASE",
        title: "Organisatiefase",

        disabledText:
            "Unlock eerst de ontwikkelfase voordat verder kunt met de organisatiefase.",
    },
];
