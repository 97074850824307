import { text } from "./config";

const Container = ({ id }) => {
    const { name, description } = text[id];

    return (
        <div className="modal-planning-container">
            <h1>{name}</h1>
            <p className="p pt-m pb-m">{description}</p>
        </div>
    );
};

export default Container;
