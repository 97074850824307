import { db } from "core/firebase";
import { updateDoc, doc } from "firebase/firestore";
import { applicationStore } from "core/application/application/store";
import { projectStore } from "../project/store";

export const editStage = async (payload) => {
    const { application } = applicationStore.getState();
    const { project } = projectStore.getState();

    const ref = doc(db, "applications", application.id, "projects", project.id);

    const error = await updateDoc(ref, {
        [`stages.${payload}`]: true,
        [`stages.ONTMOETINGSFASE`]: true,
    });

    return error;
};
