import create from "zustand";

export const initialState = {
    isUserAuthenticated: false,
    isUserLoaded: false,
    isUserNotFound: false,
    user: null,
};
export const authStore = create((/*set*/) => ({
    ...initialState,
}));
