import create from "zustand";
import { applicationStore } from "core/application/application/store";
import { planningStore } from "core/application/dashboard/planning/store";
import { authStore } from "core/authentication/store";
import { db } from "core/firebase";
import { writeBatch, doc, collection } from "firebase/firestore";

export const createPlanningStore = create((set) => ({
    active: false,
    type: null,
    listId: null,
    openForm: ({ type = null, listId = null }) =>
        set(() => ({ active: true, type, listId })),
    closeForm: () => set(() => ({ active: false })),
}));

export const createPlanning = async (payload) => {
    const { id } = applicationStore.getState().application;
    const { type, listId } = createPlanningStore.getState();
    const { list } = planningStore.getState();
    const { user } = authStore.getState();

    const batch = writeBatch(db);

    let filteredList = list
        .filter((item) => item.listId === listId)
        .sort((a, b) => a.position - b.position);
    if (filteredList && filteredList?.length) {
        // Update the index of each list item and give the new listItem the index of 0
        filteredList.forEach((item, index) => {
            const taskRef = doc(db, "applications", id, "planning", item.id);

            return batch.update(taskRef, { position: index + 1 });
        });
    }

    const planningRef = doc(collection(db, "applications", id, "planning"));

    batch.set(planningRef, {
        ...payload,
        type,
        listId,
        position: 0,
        visibility: true,
        archived: false,
        created_by: user,
        created_at: new Date(),
    });

    const error = await batch.commit().catch((error) => ({ error }));

    return error;
};
