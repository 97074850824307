import { useEffect } from "react";

// Libraries
import { useParams } from "react-router-dom";

// Core
import {
	setApplicationListener,
	removeApplicationListener,
} from "../../core/application/application/actions";

import {
	setApplicationRoleListener,
	removeApplicationRoleListener,
} from "../../core/roles/actions";

import { applicationStore } from "../../core/application/application/store";
import { rolesStore } from "../../core/roles/store";

// Components
import PageNotFound from "../utils/page-not-found";
import Loading from "../loading";
import Root from "./root";

const Application = () => {
	const { applicationId } = useParams();

	const { application, applicationNotFound } = applicationStore();
	const { applicationRoles, applicationRolesNotFound } = rolesStore();

	useEffect(() => {
		setApplicationListener(applicationId);
		return () => removeApplicationListener();
	}, [applicationId]);

	useEffect(() => {
		setApplicationRoleListener(applicationId);
		return () => removeApplicationRoleListener();
	}, [applicationId]);

	if (applicationNotFound) {
		return <PageNotFound text="Pagina niet gevonden" page />;
	}

	if (applicationRolesNotFound) {
		return <PageNotFound text="Pagina niet gevonden" page />;
	}

	if (!application || !applicationRoles) {
		return <Loading text="Applicatie en rollen worden geladen" page />;
	}

	return <Root />;
};

export default Application;
