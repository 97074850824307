//  Libraries
import { createBrowserRouter, Navigate } from "react-router-dom";

// Variables
import { routesPlatform } from "variables/routes.platform";
import { routesApplication } from "variables/routes.application";
import { routesPlatformDashboard } from "variables/routes.platform.dashboard";
import { routesApplicationDashboard } from "variables/routes.application.dashboard";

// Platform
import Dashboard from "pages/platform/dashboard";
import Applications from "pages/platform/applications";
import Account from "pages/platform/account";
import DashboardApplications from "pages/platform/dashboard/pages/applications";
import Admins from "pages/platform/dashboard/pages/admins";
import Organizations from "pages/platform/dashboard/pages/organizations";
import Users from "pages/platform/dashboard/pages/users";

// Application
import Projects from "pages/application/projects";
import Library from "pages/application/library";
import Tools from "pages/application/tools";
import Project from "pages/application/project";
import DashboardApplication from "pages/application/dashboard";
import Planning from "pages/application/dashboard/planning";
import Invitations from "pages/application/dashboard/invitations";
import Supervisor from "pages/application/dashboard/supervisors";
import Messages from "pages/application/messages";

// Roots
import Root from "pages/platform/loader";
import RootPlatform from "pages/platform/root";
import RootApplication from "pages/application/loader";

const applicationRouter = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <Navigate to={routesPlatform.BASE} replace={true} />,
        children: [
            {
                path: routesPlatform.BASE,
                element: <RootPlatform />,
                children: [
                    {
                        path: routesPlatform.ROOT,
                        index: true,
                        element: <Applications />,
                    },
                    {
                        path: routesPlatform.DASHBOARD,
                        element: <Dashboard />,
                        children: [
                            {
                                path: routesPlatformDashboard.ROOT,
                                element: <DashboardApplications />,
                                index: true,
                            },

                            {
                                path: routesPlatformDashboard.ADMINS,
                                element: <Admins />,
                            },
                            {
                                path: routesPlatformDashboard.ORGANIZATIONS,
                                element: <Organizations />,
                            },
                            {
                                path: routesPlatformDashboard.USERS,
                                element: <Users />,
                            },
                        ],
                    },
                    {
                        path: routesPlatform.PROFILE,
                        element: <Account />,
                    },
                ],
            },
            {
                path: routesApplication.APPLICATION,
                element: <RootApplication />,
                children: [
                    {
                        path: routesApplication.ROOT,
                        index: true,
                        element: <Projects />,
                    },
                    {
                        path: routesApplication.LIBRARY,
                        element: <Library />,
                    },
                    {
                        path: routesApplication.TOOLS,
                        element: <Tools />,
                    },
                    {
                        path: routesApplication.DASHBOARD,
                        element: <DashboardApplication />,
                        children: [
                            {
                                path: routesApplicationDashboard.ROOT,
                                index: true,
                                element: <Planning />,
                            },
                            {
                                path: routesApplicationDashboard.INVITATIONS,
                                element: <Invitations />,
                            },
                            {
                                path: routesApplicationDashboard.SUPERVISORS,
                                element: <Supervisor />,
                            },
                        ],
                    },
                    {
                        path: routesApplication.PROJECT,
                        element: <Project />,
                    },
                    {
                        path: routesApplication.MESSAGES,
                        element: <Messages />,
                    },
                ],
            },
        ],
    },
]);

export default applicationRouter;
