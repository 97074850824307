import { createBrowserRouter, Navigate } from "react-router-dom";

// Config
import { routes } from "./config";

// Views
import Root from "./components/root";
import Reset from "./pages/reset";
import Select from "./pages/select";
import SignIn from "./pages/login";
import Register from "./pages/register";

const loginRouter = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <Navigate to={routes.ROOT} replace={true} />,
        children: [
            { path: routes.ROOT, element: <Select />, index: true },
            { path: routes.SIGN_IN, element: <SignIn /> },
            { path: routes.REGISTER, element: <Register /> },
            { path: routes.RESET, element: <Reset /> },
        ],
    },
]);

export default loginRouter;
