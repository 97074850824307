// Core
import { editLibraryStore } from "../../../../core/application/library/form-edit-library";

// Components
import Modal from "../../modal";
import Container from "./container";

const FormEditLibrary = () => {
    const { closeForm, active } = editLibraryStore();

    return (
        <Modal active={active} toggle={closeForm}>
            <Container />
        </Modal>
    );
};

export default FormEditLibrary;
