import { useState } from "react";

// Variables
import { types, list } from "../../../../variables/types.library";
import { messages } from "../../../../variables/messages";

// Core
import {
	createLibraryStore,
	createLibrary,
} from "../../../../core/application/library/form-create-library";
import { notificationStore } from "../../../../core/notifications/store";

// Components
import Slides from "../../slides";
import Slide from "../../slide";
import Header from "../../header";
import Input from "../../../inputs/input";
import TextArea from "../../../inputs/textarea";
import Files from "../../../files";
import Select from "../../../inputs/select";

const Container = () => {
	const { type, closeForm } = createLibraryStore();
	const { addNotification } = notificationStore();

	const [name, setName] = useState("");
	const [description, setDescription] = useState("");

	const [url, setUrl] = useState("");
	const [file, setFile] = useState(null);
	const [stage, setStage] = useState(list[0].value);

	const [loading, setLoading] = useState(false);

	const onSubmitLibrary = async () => {
		setLoading(true);

		const error = await createLibrary({
			name,
			description,
			url,
			file,
			stage,
		});

		setLoading(false);

		if (error) {
			addNotification({ message: messages.CREATE.ERROR, error });
			return;
		}

		closeForm();
	};

	return (
		<Slides>
			<Slide required value={name}>
				<Header text="Voeg een naam toe" />
				<Input
					placeholdertext="Type de naam hier..."
					value={name}
					onChange={({ target }) => setName(target.value)}
				/>
			</Slide>
			<Slide required value={types.DOCUMENT === type ? file : url}>
				{type === types.DOCUMENT && (
					<div>
						<Header text="Voeg een document toe" />
						<Files isSingle file={file} setFile={setFile} />
					</div>
				)}
				{type === types.LINK && (
					<div>
						<Header text="Voeg een link toe" />
						<Input
							placeholdertext="Plaats de link hier..."
							value={url}
							onChange={({ target }) => setUrl(target.value)}
						/>
					</div>
				)}
				{type === types.VIDEO && (
					<div>
						<Header text="Voeg een youtube/vimeo link toe" />
						<Input
							placeholdertext="Plaats de link hier..."
							value={url}
							onChange={({ target }) => setUrl(target.value)}
						/>
					</div>
				)}
			</Slide>
			<Slide required value={description}>
				<Header text="Geef een omschrijving" />
				<TextArea
					placeholder="Type de omschrijving hier..."
					value={description}
					onChange={({ target }) => setDescription(target.value)}
				/>
			</Slide>
			<Slide required value={stage}>
				<Header text="Selecteer een fase" />
				<Select list={list} onChange={setStage} selected={stage} />
			</Slide>
			<Slide submit loading={loading} onSubmit={onSubmitLibrary}>
				<Header text="Klopt alle informatie?" />
			</Slide>
		</Slides>
	);
};

export default Container;
