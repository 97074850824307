// Firebase
import { removeUserListener } from "../../../../core/authentication/actions";
import { authStore } from "../../../../core/authentication/store";

// Components
import Thumbnail from "../../../../components/images/thumbnail";
import Button from "components/button";

const Account = () => {
    const { user } = authStore();

    return (
        <div className="container flex flex-middle mb-m mt-m">
            <div className="card p-box container-m full mt-m">
                <div className="flex flex-align">
                    <div>
                        <Thumbnail user={user} className="img--m" />
                    </div>
                    <div className="ml full">
                        <h3>{user.displayName}</h3>
                        <div className="fw-b c-sec fs-s text-wrap">
                            {user.email}
                        </div>
                    </div>
                </div>
                <div className="row flex-space mt">
                    <Button
                        text="Afmelden"
                        onClick={removeUserListener}
                        className="full"
                    />
                </div>
            </div>
        </div>
    );
};

export default Account;
