// Components
import Modal from "../../modal";
import Container from "./container";

const FormLibraryArchive = ({ active, closeForm }) => {
    return (
        <Modal active={active} toggle={closeForm}>
            <Container />
        </Modal>
    );
};

export default FormLibraryArchive;
