import { IconTrash } from "components/icons";

const Selected = ({ user, removeUser }) => {
    return (
        <button
            className="radius h-7 pl-s pr-s bgc-main flex-inline flex-align fs-xs fw-b mr-s"
            onClick={() => removeUser(user)}
        >
            @{user.displayName.split(" ")[0]}
            <IconTrash className="icon icon-xxs ml-s" />
        </button>
    );
};

export default Selected;
