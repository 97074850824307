// - Libraries
import { useTransition, animated } from "react-spring";

// - Components
import { errorAnimation } from "../../../variables/animations";
import { errorMessage /* requiresRouteChange */ } from "../config";

const InputError = ({ error, active }) => {
    const transitions = useTransition(active, { ...errorAnimation });

    return transitions(
        (styles, item) =>
            item && (
                <animated.div
                    className="bgc-r fw-b flex flex-align flex-space b-r pl pr fs-xs mb-xs"
                    style={styles}
                >
                    <span className="fs-s">{errorMessage(error)}</span>
                </animated.div>
            )
    );
};

export default InputError;
