import { useEffect, useState, useRef, Fragment } from "react";

import { db } from "../../../../core/firebase";
import { onSnapshot, doc, updateDoc } from "firebase/firestore";
import { unwrapSnapshot } from "../../../../core/utils";
import { editApplicationStore } from "../../../../core/platform/form-edit-application/store";

// Components
import Loader from "../../../utils/loader";
import Label from "../../../inputs/label";
import InputEdit from "../../../inputs/input-edit";
import Switch from "../../../inputs/switch";
import User from "../../../utils/user";
import Organization from "../../../utils/organization";
import FormAdmins from "./admins";
import FormOrganization from "./organizations";
import EditWrap from "./edit-wrap";

const Container = () => {
    const { id } = editApplicationStore();
    const ref = useRef(doc(db, "applications", id));

    const [application, setApplication] = useState(null);
    const [activeAdmins, setActiveAdmins] = useState(false);
    const [activeOrganizations, setActiveOrganizations] = useState(false);

    useEffect(() => {
        const unsubscribe = onSnapshot(ref.current, (payload) =>
            setApplication(unwrapSnapshot(payload))
        );

        return () => unsubscribe();
    }, [id]);

    const setVisibility = (active) => {
        updateDoc(ref.current, {
            visibility: active,
        });
    };

    const setName = (name) => {
        updateDoc(ref.current, {
            name,
        });
    };

    const setOrganization = (organization) => {
        updateDoc(ref.current, {
            organization,
        });
    };

    const setAdmin = (admin) => {
        updateDoc(ref.current, {
            supervised_by: admin,
        });
    };

    if (!application) {
        return <Loader modal />;
    }

    return (
        <Fragment>
            <div className="modal-container">
                <Label text="Name:" />
                <InputEdit text={application.name} setText={setName} />
                <Label text="Zichtbaarheid:" />
                <div className="flex flex-align mt-s mb-s">
                    <Switch
                        active={application.visibility}
                        setActive={setVisibility}
                    />
                    <span className="fs-s fw-b ml">
                        {application.visibility
                            ? "Zichtbaar voor gebruikers"
                            : "Niet zichtbaar voor gebruikers"}
                    </span>
                </div>
                <Label text="Begeleider:" />
                <EditWrap onClick={() => setActiveAdmins(true)}>
                    <User user={application.supervised_by} />
                </EditWrap>

                <Label text="Organisatie:" />
                <EditWrap onClick={() => setActiveOrganizations(true)}>
                    <Organization item={application.organization} />
                </EditWrap>
            </div>
            <FormAdmins
                active={activeAdmins}
                setActive={setActiveAdmins}
                admin={application.supervised_by}
                setAdmin={setAdmin}
            />
            <FormOrganization
                active={activeOrganizations}
                setActive={setActiveOrganizations}
                organization={application.organization}
                setOrganization={setOrganization}
            />
        </Fragment>
    );
};

/*
<button
                onClick={() => {}}
                className={`btn full fs-s ${
                    application.visibility ? "btn--red" : "btn--green"
                }`}
            >
                {application.visibility
                    ? "Zichtbaarheid uitzetten"
                    : "Zichtbaarheid aanzetten"}
            </button>
*/

export default Container;
