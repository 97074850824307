import { useState } from "react";

// Core
import { createPlanningStore } from "core/application/project/form-create-planning/store";

// Variables
import { types } from "variables/types.planning";

// Components
import { IconPlus } from "../../icons";
import Menu from "../utils/menu";
import Item from "../utils/item";

const MenuPlanning = ({ id, disabled, isInTeam, isSupervisor }) => {
    const { openForm } = createPlanningStore();
    const [active, setActive] = useState();

    const closeMenu = () => {
        setActive(false);
    };

    const openMenu = () => {
        setActive(true);
    };

    return (
        <div className="center relative">
            {!active && (
                <button
                    className={`btn-planning flex-center mb
                    ${id === "ONTMOETINGSFASE" && "btn-yellow"}
                    ${id === "ONTDEKKINGSFASE" && "btn-red"}
                    ${id === "ONTWIKKELFASE" && "btn-blue"}
                    ${id === "ORGANISATIEFASE" && "btn-purple"}`}
                    onClick={openMenu}
                    disabled={disabled}
                >
                    <IconPlus className="icon icon-sm" />
                </button>
            )}
            <Menu
                closeMenu={closeMenu}
                active={active}
                className="menu-top-right"
            >
                {isSupervisor && (
                    <Item
                        text="Instructie"
                        onClick={() =>
                            openForm({ type: types.INSTRUCTION, listId: id })
                        }
                    >
                        <IconPlus className="icon icon-s mr-s" />
                    </Item>
                )}
                {isInTeam && (
                    <Item
                        text="Actie"
                        onClick={() =>
                            openForm({ type: types.ACTION, listId: id })
                        }
                    >
                        <IconPlus className="icon icon-s mr-s" />
                    </Item>
                )}
                {(isInTeam || isSupervisor) && (
                    <Item
                        text="Tool"
                        onClick={() =>
                            openForm({ type: types.TOOL, listId: id })
                        }
                    >
                        <IconPlus className="icon icon-s mr-s" />
                    </Item>
                )}
                {isSupervisor && (
                    <Item
                        text="Reflectie"
                        onClick={() =>
                            openForm({ type: types.REFLECTION, listId: id })
                        }
                    >
                        <IconPlus className="icon icon-s mr-s" />
                    </Item>
                )}
            </Menu>
        </div>
    );
};

export default MenuPlanning;
