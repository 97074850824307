//  Components
import Button from "components/button";
import Files from "components/files";

const Container = ({ files, setFiles, closeForm }) => {
	return (
		<div className="modal-container modal-container-l">
			<Files isSingle={false} files={files} setFiles={setFiles} />
			<div className="pt mt bt">
				<Button className="full" text="Sluiten" onClick={closeForm} />
			</div>
		</div>
	);
};

export default Container;
