import { doc } from "firebase/firestore";

import { db } from "../firebase";
import { authStore } from "../authentication/store";
import { PlatformListener, ApplicationListener } from "./utils";

const platformListener = new PlatformListener();
const applicationListener = new ApplicationListener();

export const setPlatformRoleListener = () => {
    const { uid } = authStore.getState().user;
    platformListener.on(doc(db, "admins", uid));
};

export const removePlatformRoleListener = () => {
    platformListener.off();
};

export const setApplicationRoleListener = (applicationId) => {
    const { uid } = authStore.getState().user;

    applicationListener.on(
        doc(db, "applications", applicationId, "users", uid)
    );
};

export const removeApplicationRoleListener = () => {
    applicationListener.off();
};
