// Core
import { createApplicationStore } from "../../../../core/platform/form-create-application/store";

// Components
import Modal from "../../modal";
import Container from "./container";

const FormCreateApplication = () => {
    const { toggleForm, active } = createApplicationStore((state) => ({
        ...state,
    }));

    return (
        <Modal active={active} toggle={toggleForm}>
            <Container />
        </Modal>
    );
};

export default FormCreateApplication;
