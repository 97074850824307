import { useRef } from "react";

// Components
import BackgroundImage from "components/images/image";
import { IconDownload } from "components/icons";
import { useIsInViewport } from "components/utils/viewport";

const Card = ({ item }) => {
    const ref = useRef(null);
    const isInViewport = useIsInViewport(ref);

    return (
        <div ref={ref} className="card card-padding col__12 col_m__6 mb-m">
            <div className="row flex-space">
                <div className="col__6">
                    <div className="img-card flex flex-center bg-cloud relative radius">
                        {isInViewport && (
                            <BackgroundImage
                                width="100%"
                                url={`${process.env.PUBLIC_URL}/thumbnails/${
                                    item.thumbnail || "buisness_model_canvas"
                                }`}
                            />
                        )}
                    </div>
                </div>
                <div className="col__6 flex flex-col">
                    <a
                        href={`${process.env.PUBLIC_URL}/tools/${item.tool}`}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="flex fw-b pt"
                    >
                        {item.name}
                    </a>
                    <div className="grow"></div>
                    <a
                        className="button"
                        href={`${process.env.PUBLIC_URL}/tools/${item.tool}`}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <span className="fs-s">Downloaden</span>
                        <IconDownload className="icon icon-xs ml-s" />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Card;
