import Tag from "components/planning/utils/tag";
import Deadline from "components/planning/utils/deadline";
import { IconAttachment } from "components/icons";

const Card = ({ item, openForm = () => {} }) => {
    return (
        <div className="card mb p-box a-l" onClick={() => openForm(item.id)}>
            <Tag type={item.type} />
            <div className="fw-b pt-s pb-s">{item.name}</div>
            <div className="flex flex-align">
                {!!item.deadline && <Deadline deadline={item.deadline} />}

                {!!item.files.length && (
                    <div className="flex flex-align fs-xs ml fw-b">
                        <IconAttachment className="icon icon-xs mr-s" />
                        {item.files.length}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Card;
