const RoleSwitch = ({ value, role, text, setActive }) => {
    return (
        <div className="flex flex-align flex-space mb">
            <div className="fs-s fw-b">
                Rol: {text}
                {value === role && (
                    <span className="color-main"> - Geselecteerd</span>
                )}
            </div>
            <button className="switch" onClick={() => setActive(role)}>
                <div
                    className={`slider ${!!(role === value) && "slider-on"}`}
                />
            </button>
        </div>
    );
};

export default RoleSwitch;
