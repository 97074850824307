import { createPlanningStore } from "core/application/dashboard/form-create-planning";

// Components
import Modal from "../../modal";
import Container from "./container";

const PlanningCreate = () => {
    const { closeForm, active, type } = createPlanningStore();

    return (
        <Modal active={active} toggle={closeForm}>
            <Container closeForm={closeForm} type={type} />
        </Modal>
    );
};

export default PlanningCreate;
