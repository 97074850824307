//  Libraries
import { useTransition, animated } from "react-spring";

//  Variables
import { slideAnimation } from "../../variables/animations";

// - Component
import { IconLeft, IconRight } from "../icons";
import Button from "../button";

const Slide = ({
    required,
    value,
    children,
    position,
    index,
    indexUp,
    indexDown,
    submit,
    loading,
    onSubmit,
}) => {
    const active = index === position;

    const transitions = useTransition(active, slideAnimation);

    return transitions(
        (styles, item) =>
            item && (
                <animated.div
                    key={index}
                    className="modal-slide"
                    style={styles}
                >
                    <div className="modal-container">
                        {children}
                        <div className="flex mt-m">
                            {!!position && (
                                <Button
                                    className="button-border mr"
                                    onClick={indexDown}
                                >
                                    <IconLeft className="icon icon-s mr" />
                                    <span className="fs-s">Vorige</span>
                                </Button>
                            )}
                            {required && !submit && (
                                <Button
                                    disabled={!value}
                                    onClick={indexUp}
                                    text="Volgende"
                                    className={`full grid-flex-1 ${
                                        !value && "button-disabled"
                                    }`}
                                >
                                    <IconRight className="icon icon-s ml" />
                                </Button>
                            )}

                            {!required && !submit && (
                                <Button
                                    onClick={indexUp}
                                    className="full"
                                    text="Volgende"
                                >
                                    <IconRight className="icon icon-s ml" />
                                </Button>
                            )}
                            {submit && (
                                <Button
                                    onClick={onSubmit}
                                    className="full grid-flex-1"
                                    disabled={loading}
                                    loading={loading}
                                    text="Toevoegen"
                                />
                            )}
                        </div>
                    </div>
                </animated.div>
            )
    );
};

export default Slide;
