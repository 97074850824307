import { useState, useEffect, useMemo } from "react";

// Libraries
import { useLocation, matchPath, useNavigate, Outlet } from "react-router-dom";

// Core
import { db } from "core/firebase";
import {
	getDoc,
	doc,
	collection,
	query,
	where,
	onSnapshot,
} from "firebase/firestore";

import { authStore } from "core/authentication/store";
import { unwrapList } from "core/utils";

// Variables
import { routesPlatform } from "variables/routes.platform";
import { routesApplication } from "variables/routes.application";

// Components
import { sortByDate } from "components/utils/helpers";

const RootPlatform = () => {
	const [isAdmin, setIsAdmin] = useState(null);

	const { uid = null } = authStore().user;

	let location = useLocation();
	let navigate = useNavigate();

	const isOverview = useMemo(() => {
		// run only at initial load
		const isOverview = matchPath(
			{ path: routesPlatform.BASE },
			location.pathname
		);

		return isOverview;
	}, [location]);

	const isApplication = useMemo(() => {
		// run only at initial load
		const isApplication = matchPath(
			{ path: routesApplication.APPLICATION },
			location.pathname
		);

		return isApplication;
	}, [location]);

	useEffect(() => {
		const getRoles = async () => {
			const result = await getDoc(doc(db, "admins", uid));

			if (result.exists()) {
				setIsAdmin(true);
			}
		};

		if (!isOverview) {
			getRoles();
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const getApplication = async () => {
			const q = query(
				collection(db, "applications"),
				where(`users.${uid}`, "==", true),
				where("visibility", "==", true)
			);

			const unsubscribe = onSnapshot(q, (payload) => {
				const results = unwrapList(payload);

				if (results.length) {
					const last = sortByDate(results)[0];
					unsubscribe();
					navigate(`/app/${last.id}`, { replace: true });
				} else {
					navigate("/platform");
				}
			});
		};

		if (!isAdmin && !isOverview && !isApplication) {
			getApplication();
		}
		// eslint-disable-next-line
	}, []);

	return <Outlet />;
};

export default RootPlatform;
