import Modal from "components/forms/modal";

// Store
import { planningArchiveStore } from "core/application/dashboard/form-archive-planning";

// Components
import Container from "./container";

const ArchivePlanning = () => {
    const { active, closeForm } = planningArchiveStore();
    return (
        <Modal active={active} toggle={closeForm}>
            <Container />
        </Modal>
    );
};

export default ArchivePlanning;
