export const list = [
    { name: "Ontmoetingsfase", query: "", value: "ontmoetingsfase" },
    {
        name: "Ontdekkingsfase",
        query: "ontdekkingsfase",
        value: "ontdekkingsfase",
    },
    {
        name: "Ontwikkelfase",
        query: "ontwikkelingsfase",
        value: "ontwikkelingsfase",
    },
    {
        name: "Organisatiefase",
        query: "organisatiefase",
        value: "organisatiefase",
    },
];

export const stages = {
    ontmoetingsfase: "ontmoetingsfase",
    ontdekkingsfase: "ontdekkingsfase",
    ontwikkelingsfase: "ontwikkelingsfase",
    organisatiefase: "organisatiefase",
};

export const types = {
    DOCUMENT: "DOCUMENT",
    VIDEO: "VIDEO",
    LINK: "LINK",
};
