import { authStore } from "../../authentication/store";
import { db } from "../../firebase";
import { addDoc, doc, writeBatch, collection } from "firebase/firestore";

export const createOrganization = async (payload) => {
    const { user } = authStore.getState();

    const { error = null } = await addDoc(collection(db, "organizations"), {
        ...payload,
        created_at: new Date(),
        created_by: user,
        visibility: true,
    }).catch((error) => ({ error }));

    if (error) {
        return { error };
    }

    return {};
};

export const createApplication = async (payload) => {
    const { user } = authStore.getState();

    const batch = writeBatch(db);

    const applicationRef = doc(collection(db, "applications"));

    batch.set(applicationRef, {
        ...payload,
        created_by: user,
        created_at: new Date(),
        visibility: true,
        users: {
            [payload.supervised_by.uid]: true,
        },
    });

    const userRef = doc(
        db,
        "applications",
        applicationRef.id,
        "users",
        payload.supervised_by.uid
    );

    batch.set(userRef, {
        user: payload.supervised_by,
        created_at: new Date(),
        roles: {
            APPLICATION_ADMIN: true,
        },
    });

    const { error } = await batch.commit().catch((error) => ({ error }));

    return error;
};
