import { TaskListener } from "./utils";
import { db } from "core/firebase";
import { doc, addDoc, updateDoc, collection } from "firebase/firestore";
import { authStore } from "core/authentication/store";
import { editPlanningStore } from "./store";

const taskListener = new TaskListener();

export const setTaskListener = () => {
    const { applicationId, projectId, taskId } =
        editPlanningStore.getState().task;

    taskListener.on(
        doc(
            db,
            "applications",
            applicationId,
            "projects",
            projectId,
            "planning",
            taskId
        )
    );
};

export const removeTaskListener = () => {
    taskListener.off();
};

export const updatePlanning = async (payload) => {
    const { item } = editPlanningStore.getState();
    const ref = doc(
        db,
        "applications",
        item.applicationId,
        "projects",
        item.projectId,
        "planning",
        item.id
    );

    const error = await updateDoc(ref, {
        ...payload,
        updated_at: new Date(),
    }).catch((error) => error);

    return error;
};

export const createMessage = async (payload) => {
    const user = authStore.getState().user;
    const { applicationId, projectId, id } = editPlanningStore.getState().item;

    const ref = collection(
        db,
        "applications",
        applicationId,
        "projects",
        projectId,
        "planning",
        id,
        "messages"
    );

    const { error = null } = await addDoc(ref, {
        ...payload,
        visibility: true,
        is_message: true,
        created_by: user,
        created_at: new Date(),
    }).catch((error) => error);

    return error;
};

export const updateMessage = async (payload) => {
    const { applicationId, projectId, id } = editPlanningStore.getState().item;

    const ref = doc(
        db,
        "applications",
        applicationId,
        "projects",
        projectId,
        "planning",
        id,
        "messages",
        payload.messageId
    );

    const error = await updateDoc(ref, {
        message: payload.message,
    }).catch((error) => error);

    return error;
};

export const removeMessage = async (payload) => {
    const { applicationId, projectId, id } = editPlanningStore.getState().item;

    const ref = doc(
        db,
        "applications",
        applicationId,
        "projects",
        projectId,
        "planning",
        id,
        "messages",
        payload.id
    );

    const error = await updateDoc(ref, {
        visibility: false,
    }).catch((error) => error);

    return error;
};
