// Core
import { editPlanningStore } from "core/application/project/form-edit-planning/store";

// Components
import Button from "components/button";

const TaskNotFound = () => {
    const { closeForm } = editPlanningStore();

    return (
        <section className="modal-slide">
            <div className="modal-container">
                <img
                    width="100%"
                    alt="Welkom bij YouLab - aanmelden"
                    src={`${process.env.PUBLIC_URL}/illustrations/empty_projects.svg`}
                />

                <h1 className="center mt">Taak is niet gevonden</h1>
                <Button
                    text="Taak sluiten"
                    className="mt-m full"
                    onClick={closeForm}
                />
            </div>
        </section>
    );
};

export default TaskNotFound;
