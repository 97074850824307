import { onSnapshot, getDocs, getDoc } from "firebase/firestore";

export class DocumentListener {
    on(query, store, key) {
        const unsubscribe = onSnapshot(query, (doc) => {
            store.setState({ [key]: doc.data() });
        });

        this._unsubscribe = () => unsubscribe();
    }
    off() {
        this._unsubscribe();
    }
}

export const unwrapSnapshot = (payload) => {
    if (!payload.exists()) {
        return null;
    }

    return {
        id: payload.id,
        ...payload.data(),
    };
};

export const unwrapList = (payload) => {
    const list = [];
    payload.forEach((doc) => {
        list.push(unwrapSnapshot(doc));
    });

    return list;
};

export const unwrapQuery = (query) => {
    return getDocs(query)
        .then((payload) => unwrapList(payload))
        .catch((error) => console.log(error));
};


export const unwrapQueryDoc = (query) => {
    return getDoc(query)
        .then((payload) => unwrapSnapshot(payload))
        .catch((error) => console.log(error));
};
