import { onSnapshot } from "firebase/firestore";
import { rolesStore, initialState } from "./store";
import { unwrapSnapshot } from "../utils";

export class PlatformListener {
    on(query) {
        const unsubscribe = onSnapshot(query, (doc) => {
            if (doc.exists()) {
                rolesStore.setState({
                    platformRoles: unwrapSnapshot(doc).roles,
                });
            } else {
                rolesStore.setState({
                    platformRolesNotFound: true,
                });
            }
        });

        this._unsubscribe = () => unsubscribe();
    }
    off() {
        rolesStore.setState({ ...initialState });
        this._unsubscribe();
    }
}

export class ApplicationListener {
    on(query) {
        const unsubscribe = onSnapshot(query, (doc) => {
            if (doc.exists()) {
                rolesStore.setState({
                    applicationRoles: doc.data().roles,
                });
            } else {
                rolesStore.setState({
                    applicationRolesNotFound: true,
                });
            }
        });

        this._unsubscribe = () => unsubscribe();
    }
    off() {
        rolesStore.setState({ ...initialState });
        this._unsubscribe();
    }
}
