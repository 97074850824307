import React from "react";

// - Libraries
import { useTransition, animated } from "react-spring";

// Config
import { alarmAnimation } from "../../variables/animations";

const Alarm = ({ active }) => {
    const transitions = useTransition(active, { ...alarmAnimation });

    return transitions(
        (styles, item) =>
            item && <animated.div style={styles} className="light-nav p-b-r" />
    );
};

export default Alarm;
