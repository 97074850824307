import { useState } from "react";

// Variables
import { roles } from "variables/roles";

// Components
import Button from "components/button";
import Input from "components/inputs/input";

const InvitationForm = ({ createInvitation }) => {
	const [email, setEmail] = useState("");

	const setInvitation = () => {
		let attrs = {};

		attrs.id = email.toLowerCase().trim();
		attrs.email = email.toLowerCase().trim();
		attrs.roles = { [roles.STUDENT_TEAM]: true };

		createInvitation(attrs);
		setEmail("");
	};

	return (
		<>
			<div className="mb-s fs-xs fw-b color-gray">
				Vul een emailadres in.
			</div>
			<div className="flex mb">
				<Input
					className="input input-flex"
					placeholder="Emailadres"
					onChange={({ target }) => setEmail(target.value)}
					value={email}
				/>
				<Button
					className={`ml ${!email && "button-disabled"}`}
					onClick={() => setInvitation()}
					text="Toevoegen"
					disabled={!email}
				/>
			</div>
		</>
	);
};

export default InvitationForm;
