import { useState, useEffect } from "react";

// Libraries
import { getDocs, query, collection, where } from "firebase/firestore";

// Core
import { db } from "../../../../core/firebase";
import { applicationStore } from "../../../../core/application/application/store";
import { unwrapList } from "../../../../core/utils";
import {
    editSupervisor,
    editVisibility,
} from "../../../../core/application/dashboard/form-edit-supervisor";
import { notificationStore } from "../../../../core/notifications/store";

// Variables
import { messages } from "variables/messages";

// Component
import Loader from "../../../utils/loader";
import Supervisor from "./supervisor";
import Switch from "../../../inputs/switch";

const Container = ({ closeForm, project }) => {
    const { id } = applicationStore().application;
    const { addNotification } = notificationStore();

    const [list, setList] = useState(null);

    useEffect(() => {
        const setSearch = async () => {
            const qAdmins = query(
                collection(db, "applications", id, "users"),
                where(`roles.APPLICATION_ADMIN`, "==", true)
            );

            const qSupervisor = query(
                collection(db, "applications", id, "users"),
                where(`roles.SUPERVISOR`, "==", true)
            );

            const q1 = getDocs(qAdmins).then((payload) => unwrapList(payload));
            const q2 = getDocs(qSupervisor).then((payload) =>
                unwrapList(payload)
            );

            const [r1, r2] = await Promise.all([q1, q2]);

            setList([...r1, ...r2]);
        };
        setSearch();
    }, [id]);

    const setSupervisor = async (payload) => {
        const error = await editSupervisor(payload);

        if (error) {
            addNotification({ message: messages.UPDATE.ERROR, error });
            return;
        }
        addNotification({ message: messages.UPDATE.SUCCESS });
        closeForm();
    };

    const setVisibility = async (payload) => {
        const error = await editVisibility(payload);

        if (error) {
            addNotification({ message: messages.UPDATE.ERROR, error });
            return;
        }
        addNotification({ message: messages.UPDATE.SUCCESS });
        closeForm();
    };

    if (!list) {
        return <Loader modal />;
    }

    return (
        <div className="modal-container">
            <h3 className="mb">Begeleider aanpassen</h3>
            {list.map((item) => (
                <Supervisor
                    key={item.id}
                    item={item}
                    supervisor={project.supervised_by}
                    setSupervisor={setSupervisor}
                />
            ))}
            <h3 className="mb">Zichtbaarheid aanpassen</h3>
            <Switch active={project.visibility} setActive={setVisibility} />
        </div>
    );
};

export default Container;

/*

import React, { Component } from "react";

// - Libraries
import { connect } from "react-redux";
import { createSelector } from "reselect";

// - Firebase
import {
    supervisorsOn,
    getSupervisors,
    getProject,
    toggleSupervisor
} from "../../../../core/application/dashboard-supervisors";

// - Components
import Loader from "../../utils/loader";
import Supervisor from "./supervisor";

class Container extends Component {
    componentDidMount() {
        this.props.supervisorsOn();
    }

    render() {
        const { list, project, toggleSupervisor } = this.props;

        if (!list || !project)
            return (
                <div className="modal-slide">
                    <Loader />
                </div>
            );

        return (
            <div className="modal-container">
                <h3 className="mb">Begeleider aanpassen</h3>
                {list.map(item => (
                    <Supervisor
                        key={item.id}
                        item={item}
                        currentSupervisor={project.supervised_by}
                        toggleSupervisor={toggleSupervisor}
                    />
                ))}
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    getSupervisors,
    getProject,
    (list, project) => ({ list, project })
);

const mapDispatchToProps = {
    supervisorsOn,
    toggleSupervisor
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Container);

*/
