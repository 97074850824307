import { types } from "variables/stages";
import { stageInfoStore } from "core/application/project/form-stage-info/store";
// Components
import Button from "components/button";
import { IconHelp } from "components/icons";

const Header = ({ title, listId, disabled = true }) => {
    const { openForm } = stageInfoStore();
    return (
        <div
            className={`row flex-align flex-space p-box mb
            ${!disabled && "card"} 
            ${listId === types.ONTMOETINGSFASE && "color-yellow"}
            ${listId === types.ONTDEKKINGSFASE && "color-red"}
            ${listId === types.ONTWIKKELFASE && "color-main"}
            ${listId === types.ORGANISATIEFASE && "color-purple"}
            ${disabled && "color-gray"}`}
        >
            <div className="fw-b fs-m pt pb">{title}</div>
            {!disabled && (
                <Button
                    onClick={() => openForm(listId)}
                    text="Info"
                    className={`
            ${listId === types.ONTMOETINGSFASE && "button-yellow"}
            ${listId === types.ONTDEKKINGSFASE && "button-red"}
            ${listId === types.ONTWIKKELFASE && ""}
            ${listId === types.ORGANISATIEFASE && "button-purple"}
            ${disabled && "color-gray"}`}
                >
                    <IconHelp className="icon icon-s ml-s" />
                </Button>
            )}
        </div>
    );
};

export default Header;
