import Modal from "../../../modal";
import Container from "./container";

const FormOrganizations = ({ active, setActive, admin, setAdmin }) => {
    return (
        <Modal active={active} toggle={() => setActive(!active)}>
            <Container admin={admin} setAdmin={setAdmin} />
        </Modal>
    );
};

export default FormOrganizations;
