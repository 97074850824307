import { useState, useEffect } from "react";

// Core
import { db } from "core/firebase";
import { collection, onSnapshot } from "firebase/firestore";
import { unwrapList } from "core/utils";

// Stores
import { editTeamMember } from "core/application/project/form-edit-team/actions";
import { createTeamStore } from "core/application/project/form-create-team/store";
import { projectStore } from "core/application/project/project/store";
import { applicationStore } from "core/application/application/store";

// Components
import { IconPlus } from "components/icons";
import Button from "components/button";
import TeamMember from "./team/member";
import Loader from "components/utils/loader";
import Invitation from "./team/invitation";

const Team = ({ isAuthor }) => {
    const { openForm } = createTeamStore();
    const { id } = projectStore().project;
    const { application } = applicationStore();

    const [team, setTeam] = useState(null);
    const [invitations, setInvitations] = useState(null);

    useEffect(() => {
        const q = collection(
            db,
            "applications",
            application.id,
            "projects",
            id,
            "team"
        );

        const unsubscribe = onSnapshot(q, (payload) =>
            setTeam(unwrapList(payload))
        );

        return () => unsubscribe();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const q = collection(
            db,
            "applications",
            application.id,
            "projects",
            id,
            "invitations"
        );

        const unsubscribe = onSnapshot(q, (payload) =>
            setInvitations(unwrapList(payload))
        );

        return () => unsubscribe();
        // eslint-disable-next-line
    }, []);

    if (!team || !invitations) {
        return <Loader />;
    }

    return (
        <div className="card col__12 col-6 flex flex-col">
            {team && team.length ? (
                team.map((item) => (
                    <TeamMember
                        key={item.id}
                        item={item}
                        isAuthor={isAuthor}
                        editTeamMember={editTeamMember}
                        removeTeamMember={() => {}}
                    />
                ))
            ) : (
                <div className="fs-m color-gray fw-b center pt pb">
                    Nog geen team
                </div>
            )}
            {invitations
                .filter((item) => !item.user)
                .map((item) => (
                    <Invitation
                        key={item.id}
                        item={item}
                        isAuthor={isAuthor}
                        removeTeamMember={() => {}}
                    />
                ))}
            <div className="grow"></div>
            {isAuthor && (
                <Button
                    className="full"
                    text="teamlid toevoegen"
                    onClick={openForm}
                >
                    <IconPlus className="icon icon-xs ml-s" />
                </Button>
            )}
        </div>
    );
};

export default Team;
